import isAdmin from "../../../utils/isAdmin";
import { Button } from "../../styles/Button.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { shareAsaleNew2 } from "../../../utils/shareAsaleNew";
import { useEffect } from "react";
import { getEnrollmentRecord } from "../../../api/enrollment";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { generateMiConsentPdf } from "../../../utils";
import {
  EnrollmentResultContent,
  PlanSummaryRow,
} from "../../styles/Enrollment.styled";

export const Successful = ({
  //   status,
  selectedPlans,
  enrollmentTokens,
  getScriptForAgent,
}: {
  status: string;
  selectedPlans: any;
  enrollmentTokens: any;
  getScriptForAgent?: any;
}) => {
  const { t } = useTranslation("enrollment");
  const isMi = sessionStorage.getItem("state") === "MI" || false;

  let allDocs: any = {};
  selectedPlans.forEach((plan: any) => {
    const { commodity_name, docs } = plan;
    if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
    const availableDocs = docs.filter(
      (val: any) => val.display_in_thank_you_page === true
    );
    allDocs[commodity_name] = availableDocs;
  });

  const generatePDF = async () => {
    //@ts-ignore
    const miUser = JSON.parse(sessionStorage.getItem("miUser"));
    const ip = await fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => data.ip)
      .catch((error) => console.error("Error:", error));

    generateMiConsentPdf({
      userId: miUser?.id,
      firstName: miUser?.first_name,
      lastName: miUser?.last_name,
      ldcAccount: miUser?.ldc_account,
      utility: miUser?.findUtility,
      signature: miUser?.signName,
      ip: ip,
    }).save(`Enrollment_Acknowledgement_${miUser?.id || ""}.pdf`);
  };

  useEffect(() => {
    (async () => {
      (await isAdmin()) && getScriptForAgent("7");
      let _res = await getEnrollmentRecord(
        enrollmentTokens?.enrollment_id,
        enrollmentTokens?.sign
      );
      shareAsaleNew2(
        enrollmentTokens?.enrollment_id,
        enrollmentTokens?.sign,
        "confirmation",
        _res?.response?.service_state
      );
    })();
  }, []);

  return (
    <EnrollmentResultContent>
      <div className="text-center">
        <h1 className="mb-3">{t("Thank you for choosing CleanSky Energy")}</h1>
        <p>
          {t(
            "Your enrollment request is now processing and may take up to 48 hours to completed. Your will receive an email when your status has changed."
          )}
        </p>
        <p>
          {t("If you any questions please call")}{" "}
          <a href="tel:(888)733-5557">(888)733-5557</a> {t("or email")}{" "}
          <a href="mailto:customercare@cleanskyenergy.com">
            customercare@cleanskyenergy.com
          </a>
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isMi && (
            <div className="mt-5" style={{ marginRight: "20px" }}>
              <Button
                onClick={() => {
                  generatePDF();
                }}
              >
                <VerticalAlignBottomOutlined
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    width: "25px",
                  }}
                  rev={undefined}
                />
                {t("Enrollment Acknowledgement")}
              </Button>
            </div>
          )}
          <div className="mt-5">
            <Button
              withArrow
              onClick={() => {
                window.location.href = `https://cleanskyenergy.com/`;
              }}
            >
              {t("Back to Homepage")}
            </Button>
          </div>
        </div>
        <div
          style={{
            border: "1px solid #ddd",
            borderRadius: 12,
            padding: "36px 32px 12px",
            backgroundColor: "#fafafa",
          }}
          className="mt-5"
        >
          <p style={{ fontSize: 13 }}>
            {t(
              "Refer below to view, print, and/or download your contract and other important documents. Adobe Acrobat Reader must be installed on your computer. You may download Adobe Acrobat Reader free of charge from the Adobe web site"
            )}
            :{" "}
            <a
              href="http://get.adobe.com/reader/"
              target="_blank"
              rel="noreferrer"
            >
              {t("Download")} Adobe Reader
            </a>
          </p>
          {Object.keys(allDocs).map((type: string, typeIdx: number) => {
            return (
              <div
                key={typeIdx}
                className="mb-4 mt-4 d-flex flex-column align-items-center"
              >
                <h6>{t(`Documents for ${type} Plan`)}</h6>
                <div className="plan-links mt-3">
                  {allDocs[type].map((doc: any, idx: number) => (
                    <PlanSummaryRow narrow key={idx} className="text-start">
                      <a
                        href={doc.url}
                        target="_blank"
                        key={idx}
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon="file-pdf" />
                        <span className="ms-2">{doc.display_name}</span>
                      </a>
                    </PlanSummaryRow>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </EnrollmentResultContent>
  );
};
