import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EnrollmentStepWrapper,
  EnrollmentStepFooter,
  EnrollmentFormRow,
  EnrollmentFormCol,
  CreditCheckSection,
  InputWithMask,
  InputMaskToggle,
  CheckboxDesc,
} from "../styles/Enrollment.styled";
import { Button } from "../styles/Button.styled";
import {
  Form,
  Dropdown,
  Spinner,
  InputGroup,
  DropdownButton,
} from "react-bootstrap";
import { CheckboxItem } from "../checkbox-item";
import { statesList } from "../../states-list";
import { FormError } from "./FormError";
import {
  confirmEnrollment,
  getEnrollmentRecord,
  saveEnrollment,
} from "../../api/enrollment";
import moment from "moment";
import NumberFormat from "react-number-format";
import { LoadingRow } from "../loading-row";
import { EnrollmentContext } from "../../enrollment";
import {
  getAgentScript,
  postToHubSpot,
  createDealHubspot,
  getSwitchVelocityDetails,
  getSwitchHoldDetails,
} from "../../api/api";
import { useLocation } from "react-router";
import qs from "qs";
import isAdmin from "../../utils/isAdmin";
import { ENROLLMENT_PORTAL_URL } from "../../config";
import ExistingCustomer from "./AgentWebPortal/ExistingCustomer";
import { dealStages } from "../../config";
import getStatusByCode from "../../utils/getStatusByCode";
import hubspotApiCalls from "../../utils/hubspotApiCalls";

interface Props {
  show: boolean;
  handleBack: Function;
  handleContinue: Function;
  dynamicFields: any;
  specialPro: {
    ssn: any;
    date_of_birth: any;
  };
  stateId?: string;
  handleSetCustomerName: Function;
  setScriptData: any;
  openReplayTracker: any;
}

interface CreditCheckTypeOption {
  label: string;
  value: string;
  desc: string;
}
interface StatesOption {
  name: string;
  abbr: string;
}

const creditCheckOptions = [
  {
    id: "credit_check_type_ssn",
    label: "SSN",
    value: "ssn",
    desc: "",
  },
  {
    id: "credit_check_type_dob",
    label: "Date of Birth",
    value: "dob",
    desc: "",
  },
];

export const EnrollmentStepInfo: React.FC<Props> = ({
  show,
  handleBack,
  handleContinue,
  dynamicFields,
  specialPro,
  stateId,
  handleSetCustomerName,
  openReplayTracker,
  setScriptData,
}) => {
  const { t, i18n } = useTranslation("enrollment");

  const phoneTypeOptions = [
    { label: "Mobile", value: "Mobile" },
    { label: "Land Line", value: "Land_Line" },
  ];

  const languageOptions = [
    { label: "English", value: "english" },
    { label: "Spanish", value: "spanish" },
  ];
  const location = useLocation();
  const { promocode } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [inputValue, setInputValue] = useState<any>({
    first_name: { type: "text", value: "" },
    last_name: { type: "text", value: "" },
    email: { type: "text", value: "" },
    email2: { type: "text", value: "" },
    phone: { type: "number", value: "" },
    billing_address: { type: "text", value: "" },
    billing_address2: { type: "text", value: "" },
    billing_city: { type: "text", value: "" },
    billing_state: { type: "select", value: "" },
    billing_zipcode: { type: "text", value: "" },
    billing_county: { type: "text", value: "" },
    promo_code: { type: "text", value: "" },
    language: { type: "select", value: { label: "English", value: "english" } },
    method_delivery: { type: "text", value: "email" },
    authorized_first_name: { type: "text", value: "" },
    authorized_last_name: { type: "text", value: "" },
  });
  const [isSameBillingAddress, setIsSameBillingAddress] = useState(true);
  const [billingState, setBillingState] = useState<StatesOption | undefined>(
    undefined
  );
  const [isAgree, setIsAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorFields, setErrorFields] = useState<any>({});
  const [requiredFields, setRequiredFields] = useState<any>({
    last_name: { type: "text" },
    first_name: { type: "text" },
    email: { type: "email" },
    email2: { type: "email" },
    phone: { type: "number" },
  });
  const [showSSN, setShowSSN] = useState(false);
  const [phoneType, setPhoneType] = useState(phoneTypeOptions[0]);
  const [creditCheckType, setCreditCheckType] = useState<CreditCheckTypeOption>(
    creditCheckOptions[0]
  );
  const [enableAuthorizedRepresentative, setEnableAuthorizedRepresentative] =
    useState(false);
  const [readOnlyPromoCode, setReadOnlyPromoCode] = useState(false);
  const {
    serviceAddress,
    storePromoCode,
    handleSetErrorModalContent,
    isTx,
    enrollmentTokens,
    selectedPlans,
  } = useContext<any>(EnrollmentContext);
  const promoCodeRef = useRef<null | HTMLDivElement>(null);
  const emailInputRef = useRef<null | HTMLDivElement>(null);
  const [isAgent, setAgent] = useState(false);
  const { utm_content } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const submit = (
    enrollmentId: string,
    sign: string,
    productId: string,
    customerId: string
  ) => {
    return new Promise((resolve, rejects) => {
      confirmEnrollment(
        enrollmentId,
        specialPro,
        sign,
        utm_content ? utm_content : "",
        "",
        "",
        customerId,
        null
      )
        .then(async (data) => {
          if (data) {
            if (data.error_code && data.error_code === "500") {
              handleSetErrorModalContent({
                title:
                  t(data.message) ||
                  t("The enrollment request has been submitted"),
              });
              // setSubmitting(false);
              return;
              // rejects(data.message || t('The enrollment request has been submitted'));
            }
            const { status } = data;
            const abandoned_status = getStatusByCode(status);
            await hubspotApiCalls(
              enrollmentId,
              sign,
              abandoned_status,
              "",
              "Information"
            );

            if (status) {
              const errorCode = status.split(".")[0];

              switch (errorCode) {
                case "0":
                case "-1":
                  if (
                    data.message &&
                    (data.message.toLowerCase().indexOf("email_repeated") >=
                      0 ||
                      data.message.toLowerCase().indexOf("email_enrolled") >= 0)
                  ) {
                    rejects("DuplicateAccount");
                  } else {
                    rejects(t(data.message));
                  }
                  if (data.message && data.message.indexOf("promoCode") >= 0) {
                    rejects("InvalidPromoCode");
                  }
                  break;
                case "30":
                  if (
                    data &&
                    data.additional &&
                    data.additional[0] &&
                    data.additional[0].account_number === null
                  ) {
                    const depositAmount = data.additional[0].deposit_amount
                      ? data.additional[0].deposit_amount
                      : 0;
                    rejects(`PayDeposit:${depositAmount}`);
                  } else {
                    resolve(Object.assign({}, data, { productId }));
                  }
                  break;
                case "10":
                case "20":
                case "40":
                case "50":
                  resolve(data);
                  break;
                default:
              }
            } else {
              rejects(t("An error occurred"));
            }
          } else {
            rejects(t("An error occurred"));
          }
        })
        .catch((error) => rejects(error));
    });
  };

  const getScriptForAgent = async () => {
    try {
      const res = await getAgentScript({
        script_id: "2",
        language: i18n.language === "es" ? "spanish" : "english",
        state: stateId || "TX",
        enrollment_id: enrollmentTokens[0]?.enrollment_id,
        commodity:
          selectedPlans.length > 1 && stateId == "OH"
            ? "gas"
            : selectedPlans[0]?.commodity_name,
        utility_code: selectedPlans[0]?.id_utility,
      });
      console.log(res);
      setScriptData({
        //@ts-ignore
        script: res.length > 0 ? res[0]?.script_content : "",
        scriptHead: res.length > 0 ? res[0]?.screen_name : "",
        dynamicData: {},
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (show) {
      window.scrollTo(0, 0);
      (async () => {
        let _isAdmin = await isAdmin();
        if (_isAdmin) {
          getScriptForAgent();
        }
      })();
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      (async () => {
        try {
          await getSwitchHoldDetails(serviceAddress?.esiid);
          await getSwitchVelocityDetails(serviceAddress?.esiid);
        } catch (err) { }
      })();
    }
  }, [show]);

  useEffect(() => {
    if (promocode) {
      const newInputValue = Object.assign({}, inputValue);
      newInputValue.promo_code.value = promocode;
      setInputValue(newInputValue);
      setReadOnlyPromoCode(true);
    }
  }, [promocode]);

  useEffect(() => {
    setTimeout(() => {
      const promoCodeError = localStorage.getItem("promoCodeError");
      if (promoCodeError) {
        setErrorFields({
          promoCode: "Invalid Promo Code",
        });
        localStorage.removeItem("promoCodeError");
      }
    }, 500);
  }, [show]);

  useEffect(() => {
    if (dynamicFields) {
      let newInputValue = Object.assign({}, inputValue),
        newRequiredFields: any = Object.assign({}, requiredFields);

      for (let cate in dynamicFields) {
        for (let key in dynamicFields[cate]) {
          if (key === "primary_phone_type") continue;
          const item = dynamicFields[cate][key];
          const { type } = item;
          if (key === "ssn") {
            newInputValue[key] = { type: "number", value: "" };
          } else {
            if (type === "checkbox") {
              let checkboxVal = false;
              if (key === "ext_sms_marketing_opt_in") {
                checkboxVal = true;
              }
              newInputValue[key] = { type: type, value: checkboxVal };
            } else if (type === "date" || type === "select") {
              if (key === "prefered_contact" || key === "ext_delivery_method") {
                // default select one option
                newInputValue[key] = { type: type, value: item.value[0] };
              } else {
                newInputValue[key] = { type: type, value: undefined };
              }
            } else {
              newInputValue[key] = { type: type, value: "" };
            }
          }
          if (key !== "premise_type" && !item.optional) {
            newRequiredFields[key] = { type: type };
          }
        }
      }

      if (i18n.language === "es") {
        newInputValue.language.value = { label: "Spanish", value: "spanish" };
        setInputValue(newInputValue);
      }

      setInputValue(newInputValue);
      setRequiredFields(newRequiredFields);
    } else {
      if (show) {
        handleBack();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, dynamicFields]);

  const validate = () => {
    let isValid = true;
    let newErrorFields: any = {};

    for (let i in requiredFields) {
      const { type } = requiredFields[i];
      if (type === "checkbox") {
        continue;
      } else if (type === "date") {
        if (
          // (i === 'date_of_birth' && creditCheckType.value !== 'dob') ||
          isTx === false
        )
          continue;
        if (
          !inputValue[i].value ||
          !moment(inputValue[i].value, "MM/DD/YYYY", true).isValid()
        ) {
          newErrorFields[i] = t("Invalid Date");
          isValid = false;
        }
        if (i === "date_of_birth") {
          const age = moment().diff(
            moment(inputValue[i].value, "MM/DD/YYYY"),
            "years"
          );

          if (age < 18) {
            newErrorFields[i] = t("Date of Birth is under 18 years");
            isValid = false;
          }
          if (age > 105) {
            newErrorFields[i] = t("Please enter valid Date of Birth");
            isValid = false;
          }
        }
      } else if (type === "email") {
        // eslint-disable-next-line
        const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!pattern.test(inputValue[i].value)) {
          newErrorFields[i] = t("Invalid emaill address format");
          isValid = false;
        }
      } else {
        if (!inputValue[i].value) {
          if (
            // (i === 'ssn' && creditCheckType.value !== 'ssn') ||
            i === "ssn" &&
            isTx === false
          )
            continue;
          newErrorFields[i] = t("This field is required");
          isValid = false;
        }
      }
    }

    if (inputValue.phone.value.indexOf("_") >= 0) {
      newErrorFields["phone"] = t("Invalid Phone Number");
      isValid = false;
    }

    if (
      creditCheckType.value === "ssn" &&
      inputValue.ssn.value.indexOf("_") >= 0 &&
      isTx
    ) {
      newErrorFields["ssn"] = t("Invalid SSN format");
      isValid = false;
    }

    if (inputValue.email.value && inputValue.email2.value) {
      if (inputValue.email.value !== inputValue.email2.value) {
        newErrorFields["email"] = t("Email address does not match");
        newErrorFields["email2"] = t("Email address does not match");
        isValid = false;
      }
    }

    if (!isSameBillingAddress && !billingState) {
      newErrorFields["billingState"] = t("This field is required");
      isValid = false;
    }

    if (!isAgree) {
      newErrorFields["isAgree"] = t("This field is required");
      isValid = false;
    }

    setErrorFields(newErrorFields);
    return isValid;
  };

  const handleSave = async () => {
    if (loading || !validate()) return;

    setLoading(true);

    let saveFields: any = {};
    let trueFields: any = {};

    const serviceAddr: any = {
      address: serviceAddress.street,
      state: serviceAddress.state,
      city: serviceAddress.city,
      zipcode: serviceAddress.zipcode,
      county: serviceAddress.county || "",
      address2: serviceAddress.unitNo || "",
    };

    if (openReplayTracker && inputValue?.email?.value) {
      await openReplayTracker.setUserID(inputValue.email.value);
    }

    for (let i in inputValue) {
      if (i === "email2") continue;

      const item = inputValue[i];
      if (i.indexOf("billing_") >= 0) {
        const _key = i.substr(8);
        if (i === "billing_address") {
          const billingAddress = `${item.value}`;
          saveFields[i] = isSameBillingAddress
            ? serviceAddr[_key]
            : billingAddress.trim();
        } else {
          saveFields[i] = isSameBillingAddress
            ? serviceAddr[_key]
            : inputValue[`billing_${_key}`].value;
        }
      } else {
        if (item.type === "number") {
          saveFields[i] = item.value ? item.value.replace(/ /g, "") : "";
        } else if (item.type === "date") {
          saveFields[i] = moment(item.value).format("MM/DD/YYYY");
        } else if (item.type === "select") {
          saveFields[i] = item.value ? item.value.value : "";
        } else {
          saveFields[i] = item.value;
        }
      }
    }

    saveFields.primary_phone_type = phoneType.value;
    saveFields.autopay_required = Boolean(selectedPlans[0]?.autopay_required);

    for (const key in saveFields) {
      if (key === "ssn") {
        // specialPro['ssn'] = creditCheckType.value === 'ssn' ? saveFields[key] : '';
        specialPro["ssn"] = saveFields[key];
      } else if (key === "date_of_birth") {
        // specialPro['date_of_birth'] = creditCheckType.value === 'dob' ? saveFields[key] : '';
        specialPro["date_of_birth"] = saveFields[key];
      } else if (key === "first_name" || key === "last_name") {
        trueFields[key] = saveFields[key] ? saveFields[key]?.trim() : "";
      } else {
        trueFields[key] = saveFields[key];
      }
    }
    // console.log(saveFields)
    // console.log(specialPro)
    // trueFields['premise_type'] = 'House'; // hard code
    storePromoCode(inputValue.promo_code.value);

    let promises: Promise<any>[] = [];
    enrollmentTokens.forEach((item: any) => {
      const findPlan = selectedPlans.find(
        (plan: any) => plan.plan_id === item.productId
      );
      if (findPlan) {
        const { enrollment_id, sign } = item;
        trueFields["product"] = findPlan.product;
        window.dataLayer.push({
          productName: selectedPlans[0].plan_name,
          term: selectedPlans[0].contract_term,
          rate: isTx ? selectedPlans[0].price2000 : selectedPlans[0].rate,
          customerName: `${trueFields.first_name} ${trueFields.last_name}`,
          email: trueFields.email,
          date: moment().format("MM/DD/YYYY"),
        });

        promises.push(saveData(trueFields, enrollment_id, sign));
      }
    });

    Promise.all(promises)
      .then(async (res: any) => {
        if (res) {
          if (res[0]) {
            const { email, phone, ext_data } = res[0];
            if (ext_data) {
              const ext_data_obj = JSON.parse(ext_data);
              const {
                ext_paperless,
                ext_sms_marketing_opt_in,
                ext_sms_transactional_opt_in,
              } = ext_data_obj;
              await postToHubSpot(
                ext_sms_marketing_opt_in,
                ext_sms_transactional_opt_in,
                ext_paperless,
                email,
                phone,
                trueFields.first_name,
                trueFields.last_name,
                trueFields.promo_code,
                serviceAddress.state,
                "",
                "",
                "Information"
              );
              //API call to create a Deal in Hubspot - initial phase - first retrieve data from api
              const dateToCreateDeals = await getEnrollmentRecord(
                enrollmentTokens[0].enrollment_id,
                enrollmentTokens[0].sign
              );
              await createDealHubspot(
                enrollmentTokens[0].enrollment_id,
                trueFields.promo_code || null,
                dateToCreateDeals.response,
                selectedPlans[0],
                dealStages.ENROLLMENT_INITIATED
              );

              handleSetCustomerName(
                `${trueFields.first_name} ${trueFields.last_name}`
              );
              let _isAdmin = await isAdmin();

              if (_isAdmin) {
                let customerId = "";
                let promises: Promise<any>[] = [];

                for (let item of enrollmentTokens) {
                  const { enrollment_id, sign, productId } = item;
                  const res: any = await submit(
                    enrollment_id,
                    sign,
                    productId,
                    customerId
                  );
                  if (
                    res &&
                    res.new_additional &&
                    res.new_additional.customer_id
                  ) {
                    customerId = res.new_additional.customer_id;
                    console.log(customerId);
                  }
                  promises.push(res);
                }

                Promise.all(promises)
                  .then((res: any) => {
                    setLoading(false);

                    if (res) {
                      handleContinue(res);
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    if (error === "DuplicateAccount") {
                      handleSetErrorModalContent({
                        title: t("Are you a current customer?"),
                        desc: t(
                          "It looks like you already have an account with us, please login to continue."
                        ),
                        button: "login",
                      });
                    }
                    if (error === "InvalidPromoCode") {
                      handleBack();
                      localStorage.setItem("promoCodeError", "1");
                    }
                    if (error.indexOf("PayDeposit") >= 0) {
                      const depositAmount = error.split("PayDeposit:")[1];
                      handleSetErrorModalContent({
                        title: t(
                          "A deposit is required to continue with this enrollment. Deposit amount: ##AMOUNT##. Please give us a call at ##PHONENUMBER##. Our representatives are available to assist you Monday – Friday from 8:00am – 7:00pm and Saturday 9am – 1pm CST."
                        )
                          .replace(/##AMOUNT##/, depositAmount)
                          .replace(
                            /##PHONENUMBER##/,
                            isTx ? "(888) 733-5557" : "(888) 355-6205"
                          ),
                        desc: " ",
                        buttonText: t("Cancel"),
                      });
                    }
                    handleSetErrorModalContent({
                      title: t(error),
                    });
                  });
              }
            }
          }
          setLoading(false);
          handleContinue(specialPro);
        } else {
          setLoading(false);
          handleSetErrorModalContent({
            title: t("Please try again"),
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleSetErrorModalContent({
          title: t(error),
        });
      });
  };

  const saveData = (requestData: any, enrollmentId: string, sign: string) => {
    return new Promise((resolve, rejects) => {
      saveEnrollment(enrollmentId, requestData, sign)
        .then((data) => {
          if (data && parseInt(data.status) === 1 && data.response) {
            resolve(data.response);
          } else {
            rejects(data.message);
          }
        })
        .catch((error) => rejects(error));
    });
  };

  const handleChangeInput = (evt: any) => {
    const { value, name } = evt.target;

    let newInputValue = Object.assign({}, inputValue);
    newInputValue[name as any].value = value;
    setInputValue(newInputValue);
  };

  const getDynamicFields = (type: string) => {
    const group = dynamicFields[type];

    return (
      <>
        <EnrollmentFormRow>
          {Object.keys(group).map((val, idx) => {
            const item = group[val];
            const { label, type, description } = item;
            if (type === "checkbox") {
              if (val === "ext_paperless" && stateId !== "NH") return null;
              return (
                <EnrollmentFormCol
                  full
                  key={idx}
                  style={{
                    marginTop: "2rem", // Margin addded to accomodate space for ext_delivery_method consent text
                  }}
                >
                  <CheckboxItem
                    label={t(label)}
                    value={`${inputValue[val] ? inputValue[val].value : ""}`}
                    checked={inputValue[val] && inputValue[val].value}
                    handleClick={() => {
                      let newInputValue = Object.assign({}, inputValue);
                      newInputValue[val].value = !newInputValue[val].value;
                      setInputValue(newInputValue);
                    }}
                    onlyread={false}
                    disabled={loading}
                    id={label}
                  />
                  {description && <CheckboxDesc>{t(description)}</CheckboxDesc>}
                </EnrollmentFormCol>
              );
            } else if (type === "select") {
              if (val === "primary_phone_type") return null;
              if (val === "ext_delivery_method" && !isTx) return null;
              return (
                <EnrollmentFormCol key={idx}>
                  <Form.Group className={errorFields[val] ? "error" : ""}>
                    <Form.Label>{t(label)} *</Form.Label>
                    <Dropdown className="plans-filter-dd">
                      <Dropdown.Toggle
                        disabled={loading}
                        variant="outline-secondary"
                      >
                        {inputValue[val] && inputValue[val].value
                          ? t(inputValue[val].value.label)
                          : `${t("Please Select")}...`}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {(item.value as Object[]).map(
                          (option: any, optionIdx) => {
                            return (
                              <Dropdown.Item
                                eventKey={option.label}
                                key={optionIdx}
                                onClick={() => {
                                  let newInputValue = Object.assign(
                                    {},
                                    inputValue
                                  );
                                  newInputValue[val].value = option;
                                  setInputValue(newInputValue);
                                }}
                                active={
                                  inputValue[val] &&
                                  inputValue[val].value &&
                                  inputValue[val].value.value === option.value
                                }
                              >
                                {t(option.label)}
                                {console.log(
                                  inputValue.ext_delivery_method.value.value
                                )}
                              </Dropdown.Item>
                            );
                          }
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    {val === "ext_delivery_method" &&
                      inputValue?.ext_delivery_method?.value?.value ===
                      "Email" && (
                        <span
                          style={{
                            // To place the under the default layout of select
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            width: "100%",
                            padding: "5px 0",
                            fontSize: ".75rem",
                            color: "#6c757d",
                          }}
                        >
                          {t("consentForEbill")}
                        </span>
                      )}
                  </Form.Group>
                  <FormError message={errorFields[val]} />
                </EnrollmentFormCol>
              );
            } else if (type === "date") {
              // if (val === 'date_of_birth' && creditCheckType.value !== 'dob') return null;
              return (
                <EnrollmentFormCol key={idx}>
                  <Form.Group className={errorFields[val] ? "error" : ""}>
                    <Form.Label>{t(label)} *</Form.Label>
                    <NumberFormat
                      format="##/##/####"
                      placeholder="MM/DD/YYYY"
                      mask="_"
                      name={val}
                      value={inputValue[val].value}
                      onChange={handleChangeInput}
                      className="form-control"
                      disabled={loading}
                    />
                  </Form.Group>
                  <FormError message={errorFields[val]} />
                </EnrollmentFormCol>
              );
            } else {
              if (val === "ssn") {
                // if (creditCheckType.value !== 'ssn') return null;
                return (
                  <EnrollmentFormCol key={idx}>
                    <InputWithMask>
                      <Form.Group className={errorFields[val] ? "error" : ""}>
                        <Form.Label>{t("SSN")} *</Form.Label>
                        <NumberFormat
                          format="### ## ####"
                          mask="_"
                          name={val}
                          value={inputValue[val].value}
                          onChange={handleChangeInput}
                          className="form-control"
                          disabled={loading}
                        />
                        {!showSSN && (
                          <NumberFormat
                            type="password"
                            maxLength={9}
                            name={val}
                            value={inputValue[val].value}
                            onChange={handleChangeInput}
                            className="form-control mask-input"
                            disabled={loading}
                          />
                        )}
                      </Form.Group>
                      <InputMaskToggle
                        type="button"
                        active={showSSN}
                        onClick={() => setShowSSN(!showSSN)}
                      />
                    </InputWithMask>
                    <FormError message={errorFields[val]} />
                  </EnrollmentFormCol>
                );
              }
              let labelText: any = `${t(label)}${Object.keys(requiredFields).indexOf(val) >= 0 ? " *" : ""
                }`;
              if (val === "ext_raf_code") return null;
              return (
                <EnrollmentFormCol key={idx}>
                  <Form.Group className={errorFields[val] ? "error" : ""}>
                    <Form.Label>{labelText}</Form.Label>
                    <Form.Control
                      type="text"
                      name={val}
                      placeholder=""
                      maxLength={50}
                      value={inputValue[val] ? inputValue[val].value : ""}
                      onChange={handleChangeInput}
                      disabled={loading}
                    />
                  </Form.Group>
                  <FormError message={errorFields[val]} />
                </EnrollmentFormCol>
              );
            }
          })}
        </EnrollmentFormRow>
      </>
    );
  };

  if (!show) return null;
  if (!dynamicFields) {
    return (
      <div className="d-flex justify-content-center pt-5 pb-5">
        <LoadingRow />
      </div>
    );
  }
  return (
    <EnrollmentStepWrapper>
      <Form>
        <EnrollmentFormRow>
          <EnrollmentFormCol>
            <Form.Group className={errorFields["first_name"] ? "error" : ""}>
              <Form.Label>{t("First Name")} *</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                maxLength={50}
                value={inputValue.first_name.value}
                onChange={handleChangeInput}
                disabled={loading}
              />
            </Form.Group>
            <FormError message={errorFields["first_name"]} />
          </EnrollmentFormCol>
          <EnrollmentFormCol>
            <Form.Group className={errorFields["last_name"] ? "error" : ""}>
              <Form.Label>{t("Last Name")} *</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                maxLength={50}
                value={inputValue.last_name.value}
                onChange={handleChangeInput}
                disabled={loading}
              />
            </Form.Group>
            <FormError message={errorFields["last_name"]} />
          </EnrollmentFormCol>
          <EnrollmentFormCol ref={emailInputRef}>
            <Form.Group className={errorFields["email"] ? "error" : ""}>
              <Form.Label>{t("Email")} *</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder=""
                maxLength={150}
                value={inputValue.email.value}
                onChange={handleChangeInput}
                disabled={loading}
              />
            </Form.Group>
            <FormError message={errorFields["email"]} />
          </EnrollmentFormCol>
          <EnrollmentFormCol>
            <Form.Group className={errorFields["email2"] ? "error" : ""}>
              <Form.Label>{t("Confirm Email")} *</Form.Label>
              <Form.Control
                type="email"
                name="email2"
                placeholder=""
                maxLength={150}
                value={inputValue.email2.value}
                onChange={handleChangeInput}
                disabled={loading}
              />
            </Form.Group>
            <FormError message={errorFields["email2"]} />
          </EnrollmentFormCol>
          <EnrollmentFormCol>
            <Form.Group className={errorFields["phone"] ? "error" : ""}>
              <Form.Label>{t("Phone Number")} *</Form.Label>
              <InputGroup>
                <DropdownButton
                  variant="outline-secondary"
                  title={t(phoneType.label)}
                  id="input-group-phone"
                >
                  {phoneTypeOptions.map((val, idx) => (
                    <Dropdown.Item key={idx} onClick={() => setPhoneType(val)}>
                      {t(val.label)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <NumberFormat
                  format="### ### ####"
                  mask="_"
                  name="phone"
                  value={inputValue.phone.value}
                  onChange={handleChangeInput}
                  className="form-control"
                  disabled={loading}
                />
              </InputGroup>
            </Form.Group>
            <FormError message={errorFields["phone"]} />
          </EnrollmentFormCol>
          {readOnlyPromoCode && (
            <EnrollmentFormCol ref={promoCodeRef}>
              <Form.Group className={errorFields["promoCode"] ? "error" : ""}>
                <Form.Label>
                  {t("Promo Code")}
                  <span style={{ fontSize: 14, marginLeft: 4, opacity: 0.8 }}>
                    ({t("Don't have one? That's ok!")})
                  </span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="promo_code"
                  placeholder=""
                  value={inputValue.promo_code.value}
                  onChange={handleChangeInput}
                  disabled={loading}
                  readOnly={readOnlyPromoCode}
                />
              </Form.Group>
              <FormError message={errorFields["promoCode"]} />
            </EnrollmentFormCol>
          )}
          {isTx && (
            <EnrollmentFormCol>
              <Form.Group>
                <Form.Label>{t("Language")}</Form.Label>
                <Dropdown className="plans-filter-dd">
                  <Dropdown.Toggle
                    disabled={loading}
                    variant="outline-secondary"
                  >
                    {inputValue.language.value
                      ? t(inputValue.language.value.label)
                      : ""}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {languageOptions.map((val, idx) => (
                      <Dropdown.Item
                        eventKey={val.label}
                        key={idx}
                        onClick={() => {
                          let newInputValue = Object.assign({}, inputValue);
                          newInputValue.language.value = val;
                          setInputValue(newInputValue);
                        }}
                        active={inputValue.language.value.value === val.value}
                      >
                        {t(val.label)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </EnrollmentFormCol>
          )}
        </EnrollmentFormRow>
        <EnrollmentFormRow>
          <EnrollmentFormCol full>
            <CheckboxItem
              label={t(
                "My billing address is the same as this service address"
              )}
              value={`${isSameBillingAddress}`}
              checked={isSameBillingAddress}
              handleClick={() => {
                let newRequiredFields = Object.assign({}, requiredFields);
                if (isSameBillingAddress) {
                  newRequiredFields["billing_address"] = { type: "text" };
                  newRequiredFields["billing_city"] = { type: "text" };
                  newRequiredFields["billing_state"] = { type: "select" };
                  newRequiredFields["billing_zipcode"] = { type: "text" };
                  newRequiredFields["billing_county"] = { type: "text" };
                } else {
                  if (newRequiredFields["billing_address"])
                    delete newRequiredFields["billing_address"];
                  if (newRequiredFields["billing_city"])
                    delete newRequiredFields["billing_city"];
                  if (newRequiredFields["billing_state"])
                    delete newRequiredFields["billing_state"];
                  if (newRequiredFields["billing_zipcode"])
                    delete newRequiredFields["billing_zipcode"];
                  if (newRequiredFields["billing_county"])
                    delete newRequiredFields["billing_county"];
                }
                setRequiredFields(newRequiredFields);
                setIsSameBillingAddress(!isSameBillingAddress);
              }}
              onlyread={false}
              disabled={loading}
              id="is-same-address"
            />
          </EnrollmentFormCol>
        </EnrollmentFormRow>
        {!isSameBillingAddress && (
          <div>
            <EnrollmentFormRow>
              <EnrollmentFormCol>
                <Form.Group
                  className={errorFields["billing_address"] ? "error" : ""}
                >
                  <Form.Label>{t("Billing Address Line 1")} *</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_address"
                    placeholder=""
                    maxLength={200}
                    value={inputValue.billing_address.value}
                    onChange={handleChangeInput}
                    disabled={loading}
                  />
                </Form.Group>
                <FormError message={errorFields["billing_address"]} />
              </EnrollmentFormCol>
              <EnrollmentFormCol>
                <Form.Group
                  className={errorFields["billing_address2"] ? "error" : ""}
                >
                  <Form.Label>{t("Unit/Suite/Apt Number")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_address2"
                    placeholder=""
                    maxLength={100}
                    value={inputValue.billing_address2.value}
                    onChange={handleChangeInput}
                    disabled={loading}
                  />
                </Form.Group>
                <FormError message={errorFields["billing_address2"]} />
              </EnrollmentFormCol>
              <EnrollmentFormCol>
                <Form.Group
                  className={errorFields["billing_city"] ? "error" : ""}
                >
                  <Form.Label>{t("City")} *</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_city"
                    placeholder=""
                    maxLength={50}
                    value={inputValue.billing_city.value}
                    onChange={handleChangeInput}
                    disabled={loading}
                  />
                </Form.Group>
                <FormError message={errorFields["billing_city"]} />
              </EnrollmentFormCol>
              <EnrollmentFormCol>
                <Form.Group
                  className={errorFields["billing_county"] ? "error" : ""}
                >
                  <Form.Label>{t("County")} *</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_county"
                    placeholder=""
                    maxLength={100}
                    value={inputValue.billing_county.value}
                    onChange={handleChangeInput}
                    disabled={loading}
                  />
                </Form.Group>
                <FormError message={errorFields["billing_county"]} />
              </EnrollmentFormCol>
              <EnrollmentFormCol>
                <Form.Group
                  className={errorFields["billingState"] ? "error" : ""}
                >
                  <Form.Label>{t("State")} *</Form.Label>
                  <Dropdown className="plans-filter-dd">
                    <Dropdown.Toggle
                      disabled={loading}
                      variant="outline-secondary"
                    >
                      {billingState ? billingState.name : "State..."}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {statesList.map((val, idx) => (
                        <Dropdown.Item
                          eventKey={val.abbr}
                          key={idx}
                          onClick={() => {
                            setBillingState(val);
                            let newInputValue = Object.assign({}, inputValue);
                            newInputValue.billing_state.value = val.abbr;
                            setInputValue(newInputValue);
                          }}
                          active={inputValue.billing_state.value === val.abbr}
                        >
                          {val.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
                <FormError message={errorFields["billingState"]} />
              </EnrollmentFormCol>
              <EnrollmentFormCol>
                <Form.Group
                  className={errorFields["billing_zipcode"] ? "error" : ""}
                >
                  <Form.Label>{t("Zip Code")} *</Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_zipcode"
                    placeholder=""
                    maxLength={5}
                    value={inputValue.billing_zipcode.value}
                    onChange={handleChangeInput}
                    disabled={loading}
                  />
                </Form.Group>
                <FormError message={errorFields["billing_zipcode"]} />
              </EnrollmentFormCol>
            </EnrollmentFormRow>
          </div>
        )}
        {getDynamicFields("ext_input")}
        {isAgent && (
          <ExistingCustomer
            show={true}
            esiid={"1008901012126050605100"}
            badDebt={"$10"}
            status={"Not Paid"}
            magicLink={"https://magiclink"}
          />
        )}
        {isTx && (
          <EnrollmentFormRow>
            <EnrollmentFormCol full>
              <CheckboxItem
                label={t(
                  "I want to specify an authorized representative that is allowed to manage and change my account on my behalf"
                )}
                value={`${enableAuthorizedRepresentative}`}
                checked={enableAuthorizedRepresentative}
                handleClick={() => {
                  let newRequiredFields = Object.assign({}, requiredFields);
                  if (!enableAuthorizedRepresentative) {
                    newRequiredFields["authorized_first_name"] = {
                      type: "text",
                    };
                    newRequiredFields["authorized_last_name"] = {
                      type: "text",
                    };
                  } else {
                    if (newRequiredFields["authorized_first_name"])
                      delete newRequiredFields["authorized_first_name"];
                    if (newRequiredFields["authorized_last_name"])
                      delete newRequiredFields["authorized_last_name"];
                  }
                  setRequiredFields(newRequiredFields);
                  setEnableAuthorizedRepresentative(
                    !enableAuthorizedRepresentative
                  );
                }}
                onlyread={false}
                disabled={loading}
                id="enable-authorized-representative"
              />
            </EnrollmentFormCol>
          </EnrollmentFormRow>
        )}
        {enableAuthorizedRepresentative && (
          <div style={{ marginTop: -24 }}>
            <EnrollmentFormRow>
              <EnrollmentFormCol>
                <Form.Group
                  className={
                    errorFields["authorized_first_name"] ? "error" : ""
                  }
                >
                  <Form.Label>{t("First Name")} *</Form.Label>
                  <Form.Control
                    type="text"
                    name="authorized_first_name"
                    placeholder=""
                    maxLength={200}
                    value={inputValue.authorized_first_name.value}
                    onChange={handleChangeInput}
                    disabled={loading}
                  />
                </Form.Group>
                <FormError message={errorFields["authorized_first_name"]} />
              </EnrollmentFormCol>
              <EnrollmentFormCol>
                <Form.Group
                  className={errorFields["authorized_last_name"] ? "error" : ""}
                >
                  <Form.Label>{t("Last Name")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="authorized_last_name"
                    placeholder=""
                    maxLength={100}
                    value={inputValue.authorized_last_name.value}
                    onChange={handleChangeInput}
                    disabled={loading}
                  />
                </Form.Group>
                <FormError message={errorFields["authorized_last_name"]} />
              </EnrollmentFormCol>
            </EnrollmentFormRow>
          </div>
        )}
        {isTx && (
          <CreditCheckSection>
            <h5>{t("Utility Credit Check")}</h5>
            <p>
              {t(
                "A utility soft credit check is necessary to assess whether a refundable deposit will be required. It does not affect your credit score. Your information is securely transmitted over an encrypted connection to CleanSky, a Texas Retail Energy Provider, which requires personal identification information to review and process your enrollment. CleanSky does not store, use, or sell any personal information collected."
              )}
            </p>
            <div className="d-flex align-items-center justify-content-between">
              <div className="flex-fill">
                {isAgent ? (
                  <Button
                    type="button"
                    style={{ marginTop: "30px" }}
                    secondary
                    onClick={() =>
                      window.open(
                        `${ENROLLMENT_PORTAL_URL}/credit_check`,
                        "_blank"
                      )
                    }
                  >
                    Send Magiclink to Customer
                  </Button>
                ) : (
                  getDynamicFields("credit_check")
                )}
              </div>
            </div>
          </CreditCheckSection>
        )}
        <EnrollmentFormRow
          style={{ borderTop: "1px solid #eee", marginTop: 20, paddingTop: 20 }}
        >
          <EnrollmentFormCol full>
            <CheckboxItem
              label={
                !isTx || (creditCheckType && creditCheckType.value === "none")
                  ? t(
                    "I hereby authorize CleanSky Energy to perform all the necessary task to establish electricity service. I accept the terms and conditions of CleanSky Energy."
                  )
                  : t(
                    "I hereby authorize CleanSky Energy to run a credit check and perform all necessary tasks to establish electricity service. I accept terms and conditions of CleanSky Energy."
                  )
              }
              value={`${isAgree}`}
              checked={isAgree}
              handleClick={() => {
                setIsAgree(!isAgree);
              }}
              onlyread={false}
              disabled={loading}
              id="is-agree-tos"
              error={errorFields["isAgree"]}
            />
          </EnrollmentFormCol>
        </EnrollmentFormRow>
        <EnrollmentStepFooter>
          <Button
            type="button"
            secondary
            disabled={loading}
            onClick={() => handleBack()}
          >
            {t("Back")}
          </Button>
          <Button type="button" onClick={() => handleSave()} data-test-id="cta">
            <span>{t("Review")}</span>
            {loading && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="ms-2"
              />
            )}
          </Button>
        </EnrollmentStepFooter>
        {Object.keys(errorFields).length > 0 && (
          <div className="mt-4 text-end">
            <FormError
              message={t(
                `Please correct error${Object.keys(errorFields).length > 1 ? "s" : ""
                } above`
              )}
            />
          </div>
        )}
      </Form>
    </EnrollmentStepWrapper>
  );
};
