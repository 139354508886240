import { Trans, useTranslation } from "react-i18next";
import { EnrollmentResultContent, PlanSummaryRow } from "../../styles/Enrollment.styled";
import { Button } from "../../styles/Button.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormLoading } from "../../form-loading";
import { useContext, useEffect, useState } from "react";
import { dealStages, iCheckTokenOnlyConfig } from "../../../config";
import { EnrollmentContext } from "../../../enrollment";
import { getEnrollmentRecord, saveEnrollmentPaymentInfo } from "../../../api/enrollment";
import moment from 'moment';
import { signEncoder } from "../../../utils/signEncoder";
import { fetchIsWhitelisted, paidCustomer, uiLogging, updateDealHubspot, updateStatus } from "../../../api/api";
import hubspotApiCalls from "../../../utils/hubspotApiCalls";
import ShareAsale from "../../../utils/ShareAsale";
import { shareAsaleNew } from "../../../utils/shareAsaleNew";
import urlHasParam from "../../../utils/urlHasParam";
import selectedPlanMagicLink from "../../../utils/selectedPlanMagicLink";
import { useNavigate } from "react-router-dom";

export const SuccessfulTx = ({ status, selectedPlans, name, enrollmentTokens, showFinalPage }: {
    status: string;
    selectedPlans: any;
    name: string;
    enrollmentTokens: any;
    showFinalPage: any;
}) => {
    const { t, i18n } = useTranslation('enrollment');
    const [ paymentMethod, setPaymentMethod ] = useState('1');
    const [ showSuccessfulPage, setShowSuccessfulPage ] = useState(showFinalPage);
    const [ paymentResponse, setPaymentResponse ] = useState();
    const [ saving, setSaving ] = useState(false);
    const { handleSetErrorModalContent } = useContext<any>(EnrollmentContext);
    const [ selectedPlansML, setSelectedPlansML ] = useState<any>()
    const navigate = useNavigate();
    const [ newNameData, setNewNameData ] = useState({
        firstName: "",
        lastName: ""
    })
    const [ iframeData, setIframeData ] = useState({
        ldc: "",
        email: ""
    });
    
    function removeParamsFromUrl(url: string) {
        const urlObj = new URL(url);
        const paramsToRemove = ["name", "status", "isTx"];
    
        for (const paramName of paramsToRemove) {
            urlObj.searchParams.delete(paramName);
        }
    
        return urlObj.toString();
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('message', handleMessage);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('message', handleMessage);
            }
        }
    }, [])

    useEffect(()=>{
        (async()=>{
            const urlSearchParams = new URLSearchParams(window.location.search)
            const is_magic_link = urlSearchParams.has("sign")

            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const signParam = url.searchParams.get('sign');

            if(idParam){
                let dataFetched = await selectedPlanMagicLink(idParam, signParam)
                setSelectedPlansML(dataFetched)
            }

            let res = await getEnrollmentRecord(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam)
            let customerData = res?.response;
            setNewNameData({
                firstName: customerData?.first_name,
                lastName: customerData?.last_name
            })
            setIframeData({ldc: customerData?.ldc_account, email: customerData?.email})

            if(!showFinalPage){
                let _resp = await fetchIsWhitelisted(enrollmentTokens?.enrollment_id || idParam)
                if(is_magic_link && _resp?.whitelist_flag == "1"){
                    await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "fraud_whitelisted_autopay_visited", window.location.href, "Autopay Setup Screen")
                }else{
                    await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "autopay_visited", `${window.location.href}${urlHasParam(window.location.href)?"&":"?"}id=${enrollmentTokens?.enrollment_id}&name=${name?.trim()?.replace(/ /g, '%20')}&status=10.E02&isTx=${true}&sign=${enrollmentTokens?.sign}`, "Autopay Setup Screen")
                }
                //UPDATE DEAL STAGE HERE
                updateDealHubspot(enrollmentTokens?.enrollment_id || idParam, dealStages.ABANDON_AUTOPAY)
            }
        })()
    },[])

    const saveRecords: any = async (enrollmentId: string, jsonData: string, sign: string, attempt = 1, maxAttempts = 3) => {
        const res = await saveEnrollmentPaymentInfo(enrollmentId, jsonData, sign);
        if (res && parseInt(res.status) === 1) {
            await uiLogging(enrollmentId, sign, `Transaction details successfully entered on our database`)
            //status Enrollment-Submitted Autopay-Complete
            const url = new URL(window.location.href);
            const agentParam = url.searchParams.get('isAgent');
            if(agentParam !== "true"){
                // await updateStatus(enrollmentId, sign, "40.E04", "Autopay-Complete", null)
            }else{
                // await updateStatus(enrollmentId, sign, null, "Autopay-Complete", null)
            }
            setSaving(false);
            return res;
        }
        else if (res && parseInt(res.status) === 2) {
            await uiLogging(enrollmentId, sign, `Transaction details entry FAILED on our database - status 2 from response`)
            handleSetErrorModalContent({
                title: t('Apologize text autopay neter')
            });
            //status Autopay Autopay-Failed
            // await updateStatus(enrollmentId, sign, "10.E02", "Autopay-Failed", null)
            setSaving(false);
            return res;
        }
        else if (attempt < maxAttempts) {
            await uiLogging(enrollmentId, sign, `Transaction details entry on our database - Attempt ${attempt}`)
            setSaving(false);
            return saveRecords(enrollmentId, jsonData, sign, attempt + 1, maxAttempts);
        }
        else {
            await uiLogging(enrollmentId, sign, `Transaction details entry FAILED on our database - Network Error`)
            handleSetErrorModalContent({
                title: t('Apologize text autopay invsg')
            });
            //status Autopay Autopay-Failed
            // await updateStatus(enrollmentId, sign, "10.E02", "Autopay-Failed", null)
            setSaving(false);
            throw new Error((res && res.message) ? t(res.message) : t('Network Error'));
        }
    }

    const handleMessage = async(e: any) => {
        const jsonData = e.data;
        const url = new URL(window.location.href);
        const idParam = url.searchParams.get('id');
        const signParam = url.searchParams.get('sign');
        jsonData?.custId && await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `Payment log from ICG - ${JSON.stringify(jsonData)}`)
        if (typeof jsonData !== 'undefined') {
            if (typeof jsonData === 'object' && jsonData.constructor.name === 'Object') {
                const { source } = jsonData;
                if (typeof source !== 'undefined' && source === 'iCheck') {
                    if (typeof jsonData.error !== 'undefined' && jsonData.error !== null) {
                        // toast.error(jsonData.error);
                        const url = new URL(window.location.href);
                        const idParam = url.searchParams.get('id');
                        const signParam = url.searchParams.get('sign');
                        await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `ICG response has error, modal shown to user for ${jsonData?.error}`)
                        handleSetErrorModalContent({
                            title: t(jsonData.error)
                        });
                    }
                    else {
                        setPaymentResponse(jsonData);
                        setSaving(true);
                        // const { transactionResponse } = jsonData;
                        // const confirmationNumber = transactionResponse.split('|')[7];
                        if (jsonData) {
                            const url = new URL(window.location.href);
                            const idParam = url.searchParams.get('id');
                            const signParam = url.searchParams.get('sign');

                            await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `credit card autopay transaction APPROVED from ICG, starting process to enter data in our database`)
                            saveRecords(
                                enrollmentTokens?.enrollment_id || idParam,
                                JSON.stringify(jsonData),
                                enrollmentTokens?.sign || signParam,
                            ).then(async () => {
                                // console.log(res)
                                setSaving(false);
                                setShowSuccessfulPage(true);
                                const urlSearchParams = new URLSearchParams(window.location.search)
                                const is_magic_link = urlSearchParams.has("sign")
                                let _resp = await fetchIsWhitelisted(enrollmentTokens?.enrollment_id || idParam)
                                if(is_magic_link && _resp?.whitelist_flag == "1"){
                                    await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "fraud_whitelisted_autopay_complete", removeParamsFromUrl(window.location.href), "Thank you Page")
                                    await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "Hubspot API call - fraud whitelisted autopay complete")
                                }else{
                                    await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "autopay_complete", "", "Thank you Page")
                                    await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "Hubspot API call - autopay complete")
                                }
                                //UPDATE DEAL STAGE HERE
                                updateDealHubspot(enrollmentTokens?.enrollment_id || idParam, dealStages.COMPLETE_AUTOPAY)
                                setShowSuccessfulPage(true);
                                if(idParam){
                                    // await paidCustomer(idParam, "autopay")
                                    await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `user status changed from FRAUD WARNING`)
                                }

                            })
                        }
                        else {
                            const url = new URL(window.location.href);
                            const idParam = url.searchParams.get('id');
                            const signParam = url.searchParams.get('sign');
                            await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `Error in processing credit card, did not get proper response from ICG`)
                            setSaving(false);
                            handleSetErrorModalContent({
                                title: t('UnknownError')
                            });
                        }
                    }
                }
            }
        }
    }

    let allDocs: any = {};
    selectedPlans.forEach((plan: any) => {
        const { commodity_name, docs } = plan;
        if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
        const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
        allDocs[commodity_name] = availableDocs;
    })

    const getAdditionalContent = () => {
        const {
            ccUrl, baUrl, ccSpUrl, baSpUrl,
            appId, appSecret, styles
        } = iCheckTokenOnlyConfig;
        const url = paymentMethod === '1' ?
                    (i18n.language === 'es' ? ccSpUrl : ccUrl) :
                    (i18n.language === 'es' ? baSpUrl : baUrl);

        const firstName = newNameData.firstName; 
        const lastName = newNameData.lastName;

        const currentUrl = new URL(window.location.href);
        const idParam = currentUrl.searchParams.get('id');

        return (
            <div style={{ marginTop: 12 }}>
                {/* <div style={{textAlign:'center',marginBottom:24}}>
                    <div className="d-flex justify-content-center">
                        <CheckboxItem label="Credit Card" value="1" checked={paymentMethod === '1'} isRadio disabled={false} handleClick={() => setPaymentMethod('1')} />
                        <CheckboxItem label="eCheck (ACH)" value="2" checked={paymentMethod === '2'} isRadio disabled={false} handleClick={() => setPaymentMethod('2')} />
                    </div>
                </div> */}
                <div className="d-flex justify-content-center">
                    <div className="position-relative">
                        <iframe
                            id="iFrameICG"
                            name="iFrameICG"
                            title="iFrameICG"
                            src={`${url}?appId=${appId}&appSecret=${appSecret}&custId=${`${encodeURIComponent(firstName)}${encodeURIComponent(lastName)}_${moment().format('YYYYMMDDHHmmss')}`}&amount=${0}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&email=${iframeData.email}&custom1=${iframeData.ldc}&custom2=${enrollmentTokens?.enrollment_id || idParam}&css=${styles}`}
                            frameBorder="0"
                            scrolling="no"
                            width="360"
                            height="560"
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (showSuccessfulPage) {
        const url = new URL(window.location.href);
        const idParam = url.searchParams.get('id');
        const idSign = url.searchParams.get('sign');
        uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "Reached Thank you page")
        // shareAsaleNew(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign)

        navigate(`/autopaysuccess${window.location.search}`, {state: {
            pagename: "AutopaySuccess",
            selectedPlans: selectedPlans,
            selectedPlansML: selectedPlansML,
            enrollmentTokens: enrollmentTokens
        }})

        return(
            <EnrollmentResultContent>
                <div className="text-center">
                    <h1 className="mb-3">{t('Thank you for choosing CleanSky Energy')}</h1>
                    <p>
                        <Trans i18nKey="enrollment:successfulPageDesc" components={{7: <a href="mailto:verify@CleanSkyEnergy.com" />, 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                            Your enrollment request is now processing and may take up to 48 hours to complete. You will receive an email when your status has changed.Congratulations! Your enrollment is processing and may take up to 48 hours or 2 business days.  If additional verification is required, your start date may be delayed. Once completed, you will receive an email regarding your enrollment status.If you have any questions, please contact us at 1-888 – 733 – 5557, Mon-Fri 7AM - 6PM, Sat 9AM – 1PM CST.
                        </Trans>
                    </p>
                    <p style={{fontSize:12}}>
                        <Trans i18nKey="enrollment:successfulPageDesc2" components={{10: <a href="mailto:verify@CleanSkyEnergy.com" />}}>
                            Please note that your enrollment details are subject to verification and that your service will not start until such time as your information can be validated.  Please send the following documents:  two forms of government issued ID (Driver's License (front & back), ID card (front & back), Passport, Military ID, Birth Certificate, Certificate of Citizenship N-560, N-561, N-645, Employment Authorization card (I-766), Social Security Card, Health Insurance Card, TennCare, Medicaid, Medicare, etc.) and the most recent copy of your lease or mortgage statement.  Please email these documents to <a href="mailto:verify@CleanSkyEnergy.com">verify@cleanskyenergy.com</a> using the same email used to enroll.
                        </Trans>
                    </p>
                    <div className="mt-5">
                        <Button
                            withArrow
                            onClick={() => {
                                window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                            }}>
                            {t('Back to Homepage')}
                        </Button>
                    </div>
                    <div style={{border:'1px solid #ddd',borderRadius:12,padding:'36px 32px 12px',backgroundColor:'#fafafa'}} className="mt-5 mb-5">
                        <p style={{fontSize:13}}>{t('Refer below to view, print, and/or download your contract and other important documents. Adobe Acrobat Reader must be installed on your computer. You may download Adobe Acrobat Reader free of charge from the Adobe web site')}: <a href="http://get.adobe.com/reader/" target="_blank" rel="noreferrer">{t('Download')} Adobe Reader</a></p>
                        {
                            Object.keys(allDocs).map((type: string, typeIdx: number) => {
                                return (
                                    <div key={typeIdx} className="mb-4 mt-4 d-flex flex-column align-items-center">
                                        <h6>{t(`Documents for ${type} Plan`)}</h6>
                                        <div className="plan-links mt-3">
                                            {
                                                allDocs[type].map((doc: any, idx: number) => (
                                                    <PlanSummaryRow narrow key={idx} className="text-start">
                                                        <a href={doc.url} target="_blank" key={idx} rel="noreferrer">
                                                            <FontAwesomeIcon icon="file-pdf" />
                                                            <span className="ms-2">{doc.display_name}</span>
                                                        </a>
                                                    </PlanSummaryRow>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <p style={{fontSize:12}}>{t("Benefits Beyond Energy Want to see about potential additional savings on recurring bills like cable, phone, internet, satellite (television and radio), pest control and home/alarm security? CleanSky Savings Assistant works to get you a better price for the exact same service and sometimes we are even able to get you an upgrade or add-on for the same or lower price! Here's the best part, it costs you nothing for the negotiations. We split the savings 50/50. Find out more at")} <a href="https://CleanSkyEnergy.com/savings-assistant/" target="_blank" rel="noreferrer">https://CleanSkyEnergy.com/savings-assistant/</a></p>
                </div>
            </EnrollmentResultContent>
        )
    }

    return (
        <div>
            <EnrollmentResultContent>
                <div className="text-center">
                    <h1 className="mb-3">{t("We're Almost There!")}</h1>
                    <p>{t('To complete the enrollment, please use a credit/debit card to establish autopay.')}</p>
                    <div className="mt-5">{getAdditionalContent()}</div>
                </div>
            </EnrollmentResultContent>
            <FormLoading show={saving} />
        </div>
    )
}
