export const IS_DEV = false;
export const IS_UAT = false;

export const PROVIDER_ID = "230";

export const AGENT_WEB_ENROLLMENT = IS_DEV ? true : false;

export const API_URL = "https://www.powerlego.com/ApiGateway/";
// IS_DEV
// ? 'https://dev.powerlego.com/ApiGateway/'
// : 'https://www.powerlego.com/ApiGateway/'

export const CS_API_URL = IS_DEV
    ? "https://eb2trzdwi1.execute-api.us-east-1.amazonaws.com/dev/"
    : IS_UAT
        ? "https://cn7hrthwqc.execute-api.us-east-1.amazonaws.com/uat/"
        : "https://iyky4nwsnh.execute-api.us-east-1.amazonaws.com/prod/";

export const AdminApiUrl = (IS_DEV) ? "https://d3vd61xun6ojuz.cloudfront.net/" : (IS_UAT ? 'https://d1ne46r7cjqug3.cloudfront.net/' : "https://d3h0d6xuehzus4.cloudfront.net/")
export const adminUrls = (IS_DEV) ? "https://d3vd61xun6ojuz.cloudfront.net/" : (IS_UAT ? 'https://d1ne46r7cjqug3.cloudfront.net/' : "https://d3h0d6xuehzus4.cloudfront.net/")

export const DUKE_CAMPAIGN = IS_DEV ?
    'Affordable Clean Gas 12' : (
        IS_UAT ? 'Affordable Clean Gas 12' :
            'Affordable Clean Gas 12'
    );

export const DUKE_CAMPAIGN_UTILITY = IS_DEV ? "duke" : IS_UAT ? "duke" : "duke";

export const ACC_KEY = "5edfb8859ed4cb5fb935adfa528daabd";
// IS_DEV
// ? '359c78e8a945a841047fc3bc16c0b446'
// : '5edfb8859ed4cb5fb935adfa528daabd'


export const adminPortalAgent = (IS_DEV) ? 'https://dev-portal.cleanskyenergy.com/#/agent-web-enrollment' : (IS_UAT ? 'https://uat-portal.cleanskyenergy.com/#/agent-web-enrollment' : 'https://portal.cleanskyenergy.com/#/agent-web-enrollment')

export const dunsUtilityList = [
    { value: '007923311', label: 'AEP North', id: '4', code: '115' },
    { value: '007924772', label: 'AEP Central', id: '6', code: '114' },
    { value: '007929441', label: 'Texas New Mexico Power (TNMP)', id: '5', code: '116' },
    { value: '1039940674000', label: 'Oncor Electric Delivery', id: '2', code: '113' },
    { value: '957877905', label: 'CenterPoint Energy', id: '1', code: '112' },
    // { value: '0088288574800', label: 'Nueces Coop' },
    // { value: '827438383', label: 'Entergy Gulf States Inc' },
]

export const iCheckTokenOnlyConfig = { // Token Only
    ccUrl: 'https://iframe.icheckgateway.com/iFrameCC.aspx',
    baUrl: 'https://iframe.icheckgateway.com/iFrameBA.aspx',
    ccSpUrl: 'https://iframe.icheckgateway.com/iFrameCCSp.aspx',
    baSpUrl: 'https://iframe.icheckgateway.com/iFrameBASp.aspx',
    appId: (IS_DEV || IS_UAT) ? 'AC5VLM14EBEykflxuy3no4zg5uzhw4l2' : 'ZaR0bSVXuIf6YS4Henma1TSyoWFeR77K',
    appSecret: (IS_DEV || IS_UAT) ? 'Y4JACEA1VKVdjyrxxdd5hxytt45okfjn' : 'yHVkLxbuEHVQdfsyuOnyVFgWjAURkWdM',
    appSiteId: (IS_DEV || IS_UAT) ? 'XAEJ' : 'NAY3',
    styles: 'payment{background:white;border-color:rgb(221,221,221);}input[type="submit"],input[type="reset"]{background:rgb(37,63,102);border-radius:30px;border:0 none;}'
}

export const iCheckConfig = { // Payment & Token
    ccUrl: 'https://iframe.icheckgateway.com/iFrameCC.aspx',
    baUrl: 'https://iframe.icheckgateway.com/iFrameBA.aspx',
    ccSpUrl: 'https://iframe.icheckgateway.com/iFrameCCSp.aspx',
    baSpUrl: 'https://iframe.icheckgateway.com/iFrameBASp.aspx',
    appId: (IS_DEV || IS_UAT) ? 'H343ZGSTRG0zpkwmso1oo5tl03rk0bya' : 'OHrJoQ0w0kEjlldVgGTmdNicZA9auE0q',
    appSecret: (IS_DEV || IS_UAT) ? 'DL2JTWSNVE0v5aehtdkuqr2tmruxo5hu' : 'm5YBvAOqG090CnrTXNVpBNUp5UjTEuJI',
    appSiteId: (IS_DEV || IS_UAT) ? 'XAEL' : 'NAY3',
    styles: 'payment{background:white;border-color:rgb(221,221,221);}input[type="submit"],input[type="reset"]{background:rgb(37,63,102);border-radius:30px;border:0 none;}'
}

export const checkWhitelistUrl = `${AdminApiUrl}user/customerCheck`;
export const paidCustomerUrl = `${AdminApiUrl}user/paidCustomer`;
export const ENROLLMENT_PORTAL_URL = (IS_DEV)
    ? "https://dev-signup.cleanskyenergy.com"
    : (IS_UAT ? "https://uat-signup.cleanskyenergy.com" : "https://signup.cleanskyenergy.com")

export const bearerToken = (IS_DEV || IS_UAT) ? "DEVELOPMENT_BEARER_TOKEN" : "PRODUCTION_BEARER_TOKEN";

export const openReplayCSEKey = (IS_DEV || IS_UAT) ? "0dWjRZFNXMwsExE9OVIn" : "marsXDW6PJjNE5ieAy3l";
export const openReplaySecureMode = (IS_DEV || IS_UAT) ? true : false;

//DOCUMENT PREFIX
export const documentPrefix = (IS_DEV || IS_UAT) ? "https://dev-enrollment-document.s3.amazonaws.com" : "https://enrollment-document.s3.amazonaws.com";

//HUBSPOT CONTACTS RELATED
export const contactSubmissionURL = (IS_DEV || IS_UAT) ? "https://api.hsforms.com/submissions/v3/integration/submit/39643115/a84abc70-4529-469e-bf52-42b8ee638398" : "https://api.hsforms.com/submissions/v3/integration/submit/23595384/c2667169-8754-4339-a4f9-c4cee9897d43"

//HUBSPOT DEALS RELATED VARIABLES
export const accessTokenForDeals = (IS_DEV || IS_UAT) ? "pat-na1-897c70ec-9fa2-448d-9220-7b45adcfb1d0" : "pat-na1-f3fb80e5-8bf7-4aa8-a528-ae2b6307575b";
export const clientSecretForDeals = (IS_DEV || IS_UAT) ? "e4453f71-581e-4e69-9acc-c0fe5d841400" : "f8b518bc-e372-4e2c-96c7-43d392a4a419";
export const dealPipeline = (IS_DEV || IS_UAT) ? 64579112 : 59098477;
export const dealStages = (IS_DEV || IS_UAT) ? {
    "ENROLLMENT_INITIATED": 126142213,
    "ABANDON_DEPOSIT": 126128232,
    "ABANDON_AUTOPAY": 126128231,
    "COMPLETE_DEPOSIT": 126142215,
    "COMPLETE_AUTOPAY": 126128230,
    "COMPLETED_ENROLLMENT_NO_AUTOPAY_DEPOSIT_NEEDED": 172743968
} : {
    "ENROLLMENT_INITIATED": 116586362,
    "ABANDON_DEPOSIT": 116586363,
    "ABANDON_AUTOPAY": 116586364,
    "COMPLETE_DEPOSIT": 116586365,
    "COMPLETE_AUTOPAY": 116586366,
    "COMPLETED_ENROLLMENT_NO_AUTOPAY_DEPOSIT_NEEDED": 116586367
}

export const CAMPAIGN_PROMO = ['3DMDPL', '3DMPECO', 'DPLDMGC1', 'PECODMGC1']
