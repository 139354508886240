export const dukeZipcodes = [
  43004, 43223, 43332, 44022, 44026, 44062, 44118, 44484, 45001, 45002,
  45005, 45011, 45013, 45014, 45015, 45030, 45033, 45034, 45036, 45039, 45040,
  45041, 45042, 45044, 45050, 45052, 45056, 45061, 45062, 45065, 45066, 45067,
  45068, 45069, 45101, 45102, 45103, 45105, 45106, 45107, 45111, 45121, 45122,
  45130, 45140, 45144, 45147, 45148, 45150, 45152, 45154, 45157, 45162, 45167,
  45168, 45174, 45176, 45201, 45202, 45203, 45204, 45205, 45206, 45207, 45208,
  45209, 45211, 45212, 45213, 45214, 45215, 45216, 45217, 45218, 45219, 45220,
  45221, 45222, 45223, 45224, 45225, 45226, 45227, 45228, 45229, 45230, 45231,
  45232, 45233, 45234, 45235, 45236, 45237, 45238, 45239, 45240, 45241, 45242,
  45243, 45244, 45245, 45246, 45247, 45248, 45249, 45250, 45251, 45252, 45255,
  45270, 45332, 45402, 45406, 45458, 45693
];


// removed zip codes 44618, 45365  as per client requirement => conversation below
// https://eiqdigital.slack.com/archives/C044R6EAZB5/p1741887816405179?thread_ts=1741699352.028759&cid=C044R6EAZB5