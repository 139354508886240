import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  EnrollmentStepWrapper,
  EnrollmentStepFooter,
  ReviewRow,
  ReviewBlock,
  ReviewWrapper,
  ReviewDisclaimer,
  EnrollmentFormRow,
  EnrollmentFormCol,
  // CreateAccountSection,
  // InputWithMask,
  // InputMaskToggle,
  ReviewContent,
  PlanSummaryRow,
  InputZipcode,
} from "../styles/Enrollment.styled";
import { getEnrollmentRecord, confirmEnrollment } from "../../api/enrollment";
import { Button } from "../styles/Button.styled";
import { /*Form, */ Spinner } from "react-bootstrap";
import { LoadingRow } from "../loading-row";
import moment from "moment";
import { CheckboxItem } from "../checkbox-item";
import { EnrollmentContext } from "../../enrollment";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { FormLoading } from "../form-loading";
import Form from "react-bootstrap/Form";
import { FormError } from "./FormError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAgentScript,
  postToHubSpot,
  createDealHubspot,
  updateStatus,
  fetchIsWhitelisted,
  saveMiConsent,
} from "../../api/api";
import hubspotApiCalls from "../../utils/hubspotApiCalls";
import getStatusByCode from "../../utils/getStatusByCode";
import { adminPortalAgent, dealStages } from "../../config";
import isAdmin from "../../utils/isAdmin";
import { generateMiConsentPdf } from "../../utils";

interface Props {
  show: boolean;
  handleContinue: Function;
  handleBack: Function;
  specialPro: {
    ssn: string;
    date_of_birth: string;
  };
  handleReset: Function;
  selectedUtlity: any;
  utilityList: any;
  setScriptData: Function;
}

interface EnrollmentFields {
  billing_address: string;
  billing_address2: string;
  billing_city: string;
  billing_state: string;
  billing_zipcode: string;
  billing_county: string;
  date_of_birth: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  requested_date: string;
  service_address: string;
  service_address2: string;
  service_city: string;
  service_county: string;
  service_state: string;
  service_type: string;
  ssn?: string;
  ext_data?: string;
  authorized_first_name?: string;
  authorized_last_name?: string;
  ldc_account?: string;
}

export const EnrollmentStepReview: React.FC<Props> = ({
  show,
  handleContinue,
  handleBack,
  specialPro,
  handleReset,
  selectedUtlity,
  utilityList,
  setScriptData,
}) => {
  const { t, i18n } = useTranslation("enrollment");
  const location = useLocation();
  const [reviewData, setReviewData] = useState<EnrollmentFields[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [errorFields, setErrorFields] = useState<any>({});
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const {
    serviceAddress,
    promoCode,
    handleSetErrorModalContent,
    isTx,
    enrollmentTokens,
    selectedPlans,
  } = useContext<any>(EnrollmentContext);
  const { utm_content } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [agreementErrors, setAgreementErrors] = useState<any>({});
  const [agreements, setAgreements] = useState<any>(undefined);
  const [agreementValues, setAgreementValues] = useState<any>(undefined);
  const [adminCompletes, setAdminCompletes] = useState(false);
  const [signName, setSignName] = useState("");
  const [fileData, setFileData] = useState<any>();
  const [isMi, setIsMi] = useState(reviewData[0]?.service_state == "MI");

  const getScriptForAgent = async () => {
    try {
      const res = await getAgentScript({
        script_id: "3",
        language: i18n.language === "es" ? "spanish" : "english",
        state: reviewData[0]?.service_state || "TX",
        enrollment_id: enrollmentTokens[0]?.enrollment_id,
        commodity:
          selectedPlans.length > 1 && reviewData[0]?.service_state == "OH"
            ? "gas"
            : selectedPlans[0]?.commodity_name,
        utility_code: selectedPlans[0]?.id_utility,
      });
      setScriptData({
        //@ts-ignore
        script: res.length > 0 ? res[0]?.script_content : "",
        scriptHead: res.length > 0 ? res[0]?.screen_name : "",
        dynamicData: {},
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (show) {
      window.scrollTo(0, 0);
      getScriptForAgent();
      setIsMi(reviewData[0]?.service_state == "MI");
      sessionStorage.setItem("state", reviewData[0]?.service_state);
    }
  }, [show, reviewData[0]?.service_state]);

  useEffect(() => {
    let newAgreements: any = {},
      newAgreementValues: any = {};
    selectedPlans.forEach((plan: any) => {
      const { commodity_name, agreed_acceptance_text } = plan;
      newAgreements[commodity_name] = agreed_acceptance_text;
      let values: boolean[] = [];
      agreed_acceptance_text.forEach(() => values.push(false));
      newAgreementValues[commodity_name] = values;
    });

    setAgreements(newAgreements);
    setAgreementValues(newAgreementValues);
  }, [selectedPlans]);

  useEffect(() => {
    if (show) {
      if (enrollmentTokens.length > 0) {
        let promises: Promise<any>[] = [];
        enrollmentTokens.forEach((item: any) => {
          const { enrollment_id, sign } = item;
          promises.push(getRecord(enrollment_id, sign));
        });

        Promise.all(promises)
          .then((res: any) => {
            setLoading(false);
            setReviewData(res);
          })
          .catch((error) => {
            setLoading(false);
            handleSetErrorModalContent({
              title: t(error),
            });
          });
      } else {
        handleReset();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, enrollmentTokens]);

  const getRecord = (enrollmentId: string, sign: string) => {
    return new Promise((resolve, rejects) => {
      getEnrollmentRecord(enrollmentId, sign)
        .then((data) => {
          if (data && parseInt(data.status) === 1) {
            resolve(data.response);
          }
          rejects("No data found");
        })
        .catch((error) => rejects(error));
    });
  };

  function formatStringForServiceType(inputString: string) {
    try {
      return inputString.replace(/([A-Z])/g, " $1").trim();
    } catch (err) {
      return "";
    }
  }

  const syncSubmit = async () => {
    let customerId = "";

    try {
      if (isMi) {
        const { enrollment_id, sign } = enrollmentTokens[0];
        const fileD = await generatePDF();
        await saveMiConsent(enrollment_id, sign, fileD);
      }
    } catch (err) {
      console.log(err);
    }
    let promises: Promise<any>[] = [];
    for (let item of enrollmentTokens) {
      const { enrollment_id, sign, productId } = item;
      const res: any = await submit(enrollment_id, sign, productId, customerId);
      if (res && res.new_additional && res.new_additional.customer_id) {
        customerId = res.new_additional.customer_id;
        console.log(customerId);
      }
      promises.push(res);
    }

    Promise.all(promises)
      .then((res: any) => {
        setSubmitting(false);

        if (res) {
          handleContinue(res);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        if (error === "DuplicateAccount") {
          handleSetErrorModalContent({
            title: t("Are you a current customer?"),
            desc: t(
              "It looks like you already have an account with us, please login to continue."
            ),
            button: "login",
          });
        }
        if (error === "InvalidPromoCode") {
          handleBack();
          localStorage.setItem("promoCodeError", "1");
        }
        if (error.indexOf("PayDeposit") >= 0) {
          const depositAmount = error.split("PayDeposit:")[1];
          handleSetErrorModalContent({
            title: t(
              "A deposit is required to continue with this enrollment. Deposit amount: ##AMOUNT##. Please give us a call at ##PHONENUMBER##. Our representatives are available to assist you Monday – Friday from 8:00am – 7:00pm and Saturday 9am – 1pm CST."
            )
              .replace(/##AMOUNT##/, depositAmount)
              .replace(
                /##PHONENUMBER##/,
                isTx ? "(888) 733-5557" : "(888) 355-6205"
              ),
            desc: " ",
            buttonText: t("Cancel"),
          });
        }
        handleSetErrorModalContent({
          title: t(error),
        });
      });
  };

  const handleSubmit = async () => {
    const isAd = await isAdmin();
    if (isAd) {
      // rest of the agent changes
      const url = new URL(window.location.href);

      let _resp = await fetchIsWhitelisted(enrollmentTokens[0]?.enrollment_id);
      const resp = [_resp];
      let payNow = Boolean(resp[0]?.paynow);

      if (!payNow) {
        //status Enrollment-Submitted Autopay-Complete
        updateStatus(
          enrollmentTokens[0]?.enrollment_id,
          enrollmentTokens[0]?.sign,
          "40.E04",
          "Autopay-Complete",
          null
        );
      } else {
        //status Enrollment-Submitted Deposit-Paid
        updateStatus(
          enrollmentTokens[0]?.enrollment_id,
          enrollmentTokens[0]?.sign,
          "40.E04",
          "Deposit-Paid",
          null
        );
      }
      setAdminCompletes(true);
    }
    if (isAd) return;
    let isValid = true;

    let newAgreesError: any = {};

    Object.keys(agreementValues).forEach((type: string, typeIdx: number) => {
      agreementValues[type].forEach((val: boolean, idx: number) => {
        if (!val) {
          if (!newAgreesError[type]) newAgreesError[type] = [];
          newAgreesError[type].push(idx);
          isValid = false;
        }
      });
    });

    setAgreementErrors(newAgreesError);

    if (showCreateAccount) {
      let newErrorFields: any = {};

      if (!username) {
        newErrorFields["username"] = t("This field is required");
        isValid = false;
      }
      if (!password) {
        newErrorFields["password"] = t("This field is required");
        isValid = false;
      }

      setErrorFields(newErrorFields);
    }
    if (isMi) {
      let newErrorFields: any = {};

      if (!signName) {
        newErrorFields["signName"] = t("This field is required");
        isValid = false;
      }

      setErrorFields(newErrorFields);
    }

    if (submitting || !isValid) return;
    setSubmitting(true);

    syncSubmit();
  };

  const submit = (
    enrollmentId: string,
    sign: string,
    productId: string,
    customerId: string
  ) => {
    const currentUrl = new URL(window.location.href);
    const idTest = currentUrl.searchParams.get("test");

    return new Promise((resolve, rejects) => {
      confirmEnrollment(
        enrollmentId,
        specialPro,
        sign,
        utm_content ? utm_content : "",
        username,
        password,
        customerId,
        idTest
      )
        .then(async (data) => {
          if (data) {
            if (data.error_code && data.error_code === "500") {
              handleSetErrorModalContent({
                title:
                  t(data.message) ||
                  t("The enrollment request has been submitted"),
              });
              setSubmitting(false);
              return;
              // rejects(data.message || t('The enrollment request has been submitted'));
            }
            const { status } = data;
            const abandoned_status = getStatusByCode(status);
            await hubspotApiCalls(
              enrollmentId,
              sign,
              abandoned_status,
              "",
              "Review"
            );

            if (status) {
              const errorCode = status.split(".")[0];

              switch (errorCode) {
                case "0":
                case "-1":
                  if (
                    data.message &&
                    (data.message.toLowerCase().indexOf("email_repeated") >=
                      0 ||
                      data.message.toLowerCase().indexOf("email_enrolled") >= 0)
                  ) {
                    rejects("DuplicateAccount");
                  } else {
                    rejects(t(data.message));
                  }
                  if (data.message && data.message.indexOf("promoCode") >= 0) {
                    rejects("InvalidPromoCode");
                  }
                  break;
                case "30":
                  if (
                    data &&
                    data.additional &&
                    data.additional[0] &&
                    data.additional[0].account_number === null
                  ) {
                    const depositAmount = data.additional[0].deposit_amount
                      ? data.additional[0].deposit_amount
                      : 0;
                    rejects(`PayDeposit:${depositAmount}`);
                  } else {
                    resolve(Object.assign({}, data, { productId }));
                  }
                  break;
                case "10":
                case "20":
                case "40":
                case "50":
                  resolve(data);
                  break;
                default:
              }
            } else {
              handleSetErrorModalContent({
                title: t(
                  "Unable to process enrollment currently. Please try again later."
                ),
              });
              setSubmitting(false);
              rejects(t("An error occurred"));
            }
          } else {
            handleSetErrorModalContent({
              title: t(
                "Unable to process enrollment currently. Please try again later."
              ),
            });
            setSubmitting(false);
            rejects(t("An error occurred"));
          }
        })
        .catch((error) => {
          rejects(error);

          handleSetErrorModalContent({
            title: t(
              "Unable to process enrollment currently. Please try again later."
            ),
          });
          setSubmitting(false);
        });
    });
  };

  const findAccountNumbers = (data: any) => {
    let accountNumbers: any = {};
    data.forEach((val: any) => {
      const { enrollment_id, ldc_account } = val || {};
      if (!ldc_account) return true;
      const findToken = enrollmentTokens.find(
        (item: any) => item.enrollment_id === enrollment_id
      );
      if (findToken) {
        const findProduct = selectedPlans.find(
          (plan: any) => plan.plan_id === findToken.productId
        );
        if (findProduct) {
          const { commodity_name } = findProduct;
          try {
            if (utilityList[commodity_name]) {
              const findUtility = utilityList[commodity_name]?.find(
                (utility: any) =>
                  utility.utility_code === selectedUtlity[commodity_name]
              );
              if (findUtility) {
                accountNumbers[
                  `${commodity_name} ${findUtility.wizard_ldc_label}`
                ] = ldc_account;
              }
            }
          } catch (err) {}
        }
      }
    });
    return accountNumbers;
  };

  const generatePDF = async () => {
    const { commodity_name } = selectedPlans[0];
    let findUtility = "";
    if (utilityList[commodity_name]) {
      findUtility = utilityList[commodity_name][0]?.name;
    }
    const { billing_state, first_name, last_name, ldc_account } = reviewData[0];
    const id = enrollmentTokens[0]?.enrollment_id;
    const ip = await fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => data.ip)
      .catch((error) => console.error("Error:", error));

    const doc = generateMiConsentPdf({
      userId: id,
      firstName: first_name,
      lastName: last_name,
      ldcAccount: ldc_account as string,
      utility: findUtility,
      signature: signName,
      ip: ip,
    });

    const pdfBase64 = btoa(doc.output());

    const miUser = {
      first_name: first_name,
      last_name: last_name,
      ldc_account: ldc_account,
      signName: signName,
      ip: ip,
      findUtility: findUtility,
      id: id,
    };
    sessionStorage.setItem("miUser", JSON.stringify(miUser));

    setFileData(pdfBase64);
    return pdfBase64;
  };

  const reviewContent = () => {
    // use the first record as review content

    // return reviewData.map((data: EnrollmentFields) => {
    const {
      billing_address,
      billing_address2,
      billing_city,
      billing_county,
      billing_state,
      billing_zipcode,
      date_of_birth,
      email,
      first_name,
      last_name,
      phone,
      requested_date,
      service_address,
      service_address2,
      service_city,
      service_county,
      service_state,
      service_type,
      ssn,
      ext_data,
      authorized_first_name,
      authorized_last_name,
    } = reviewData[0];

    const accountNumbers = findAccountNumbers(reviewData);

    let isPaperless = "No",
      rafCode = "";
    if (ext_data) {
      const { ext_raf_code, ext_paperless } = JSON.parse(ext_data) || "";
      if (ext_paperless && ext_paperless === "true") isPaperless = "Yes";
      if (ext_raf_code) rafCode = ext_raf_code;
    }
    return (
      <ReviewContent>
        <ReviewBlock>
          <h6>{t("Service Information")}</h6>
          <ReviewRow>
            <label>{t("Service Address")}:</label>
            <span>{`${service_address}${
              service_address2 ? ` ${service_address2}` : ""
            } ${service_city}${
              service_county ? `, ${service_county}, ` : ""
            } ${service_state}, ${
              serviceAddress ? serviceAddress.zipcode : ""
            }`}</span>
          </ReviewRow>
          <ReviewRow>
            <label>{t("Billing Address")}:</label>
            <span>{`${billing_address}${
              billing_address2 ? ` ${billing_address2}` : ""
            } ${billing_city}${
              billing_county ? `, ${billing_county}, ` : ""
            } ${billing_state}, ${billing_zipcode}`}</span>
          </ReviewRow>
          <ReviewRow>
            <label>{t("Service Type")}:</label>
            <span>
              {service_type.toLowerCase() === "switching" &&
              (requested_date === "1970-01-01" ||
                requested_date === "0000-00-00")
                ? t("Standard Switch")
                : t(formatStringForServiceType(service_type))}
            </span>
          </ReviewRow>
          {service_type.toLowerCase() === "switching" &&
          (requested_date === "1970-01-01" ||
            requested_date === "0000-00-00") ? null : (
            <ReviewRow>
              <label>{t("Requested Date")}:</label>
              <span>{moment(requested_date).format("MM/DD/YYYY")}</span>
            </ReviewRow>
          )}
          {Object.keys(accountNumbers).map((label: string, idx: number) => (
            <ReviewRow key={idx}>
              <label>{isTx ? "ESIID" : label}:</label>
              <span>{accountNumbers[label]}</span>
            </ReviewRow>
          ))}
          {
            // isPaperless &&
            // <ReviewRow>
            //     <label>{t('Paperless Billing')}:</label>
            //     <span>{isPaperless}</span>
            // </ReviewRow>
          }
          {rafCode && (
            <ReviewRow>
              <label>{t("Referral Code")}:</label>
              <span>{rafCode}</span>
            </ReviewRow>
          )}
          {promoCode && (
            <ReviewRow>
              <label>{t("Promo Code")}:</label>
              <span>{promoCode}</span>
            </ReviewRow>
          )}
        </ReviewBlock>
        <ReviewBlock>
          <h6>{t("Customer Information")}</h6>
          <ReviewRow>
            <label>{t("Customer Name")}:</label>
            <span>{`${first_name} ${last_name}`}</span>
          </ReviewRow>
          {isTx && authorized_first_name && authorized_last_name && (
            <ReviewRow>
              <label>{t("Authorized Representative Name")}:</label>
              <span>{`${authorized_first_name} ${authorized_last_name}`}</span>
            </ReviewRow>
          )}
          {phone && (
            <ReviewRow>
              <label>{t("Phone")}:</label>
              <span>{phone}</span>
            </ReviewRow>
          )}
          <ReviewRow>
            <label>{t("Email")}:</label>
            <span>{email}</span>
          </ReviewRow>
          {moment(date_of_birth).isValid() && (
            <ReviewRow>
              <label>{t("Date of Birth")}:</label>
              <span>{moment(date_of_birth).format("MM/DD/YYYY")}</span>
            </ReviewRow>
          )}
          {isTx && ssn && (
            <ReviewRow>
              <label>{t("SSN")}:</label>
              <span>*****{ssn.substr(5)}</span>
            </ReviewRow>
          )}
        </ReviewBlock>
      </ReviewContent>
    );
    // })
  };

  const reviewDisclaimer = () => {
    if (isTx) {
      if (reviewData && reviewData[0]) {
        const { service_type } = reviewData[0];
        if (service_type.toLowerCase() === "switching") {
          return (
            <ReviewDisclaimer>
              {t(
                "By clicking 'Complete Enrollment', you agree that your information will be sent to CleanSky Energy for their review and approval, and a soft credit check will be run to assist with that review. You will receive a copy of the terms of service document via email. You have the right to review and rescind the terms of service within three federal business days after receiving the terms of service without penalty."
              )}
            </ReviewDisclaimer>
          );
        }
        return (
          <ReviewDisclaimer>
            {t(
              "By clicking 'Complete Enrollment', you agree that your information will be sent to CleanSky Energy for their review and approval, and a soft credit check will be run to assist with that review.  You will receive a copy of the terms of service document via email or regular US mail based on your Deliver Bill By selection."
            )}
          </ReviewDisclaimer>
        );
      }
      return null;
    }
    return (
      <ReviewDisclaimer>
        {t(
          "By clicking 'Complete Enrollment', you agree that your information will be sent to CleanSky Energy for their review and approval."
        )}
      </ReviewDisclaimer>
    );
  };

  const getContent = () => {
    if (loading || reviewData.length === 0) {
      return (
        <div className="pt-5 pb-5">
          <LoadingRow />
        </div>
      );
    }

    if (adminCompletes) {
      return (
        <ReviewWrapper className="position-relative">
          <h5 className="mb-5">{`Thank you Mr./Ms. ${reviewData[0]?.first_name} ${reviewData[0]?.last_name}, your enrollment verification is now complete. Your confirmation number for your records is [E as in Echo | N as in November | L as in Lima | 6 digit date | 3 digit extension].`}</h5>
          <EnrollmentFormRow>
            {agreements &&
              agreementValues &&
              Object.keys(agreements).map((type, typeIdx) => {
                const data = agreements[type];
                let availableDocs = undefined;
                const findPlan = selectedPlans.find(
                  (plan: any) => plan.commodity_name === type
                );
                if (findPlan && findPlan.docs) {
                  availableDocs = findPlan.docs.filter(
                    (val: any) => val.display_in_summary === true
                  );
                }
                return (
                  <div className="ps-3 pe-3" key={typeIdx}>
                    <h5 className="mb-3">
                      {t(`Authorize ${type} Enrollment`)}
                    </h5>
                    {availableDocs && (
                      <div className="plan-links mb-4 ms-4">
                        {availableDocs.map((doc: any, idx: number) => (
                          <PlanSummaryRow narrow key={idx}>
                            <a href={doc.url} target="_blank" rel="noreferrer">
                              <FontAwesomeIcon icon="file-pdf" />
                              <span className="ms-2">
                                {doc.display_name || doc.description}
                              </span>
                            </a>
                          </PlanSummaryRow>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
          </EnrollmentFormRow>
          <h3 className="mb-3 px-3 font-weight-bold">
            {t("Document Delivery Disclosure Statement")}
          </h3>
          <h5 className="mb-5">
            To ensure that your enrollment documents are successfully delivered
            to your email address, please add{" "}
            <a href="mailto:enrollments@cleanskyenergy.com">
              enrollments@cleanskyenergy.com
            </a>{" "}
            to your approved contact list, otherwise they may end up in your
            junk folder.
          </h5>
          <EnrollmentStepFooter
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              id="complete-btn"
              type="button"
              onClick={() => {
                window.location.href = `${adminPortalAgent}`;
              }}
              data-test-id="cta"
            >
              <span>{t("Back To Enrollment Portal")}</span>
              {submitting && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ms-2"
                />
              )}
            </Button>
          </EnrollmentStepFooter>

          <FormLoading
            show={submitting}
            content={t("This process may take up to 1 minute", {
              ns: "common",
            })}
          />
        </ReviewWrapper>
      );
    }
    return (
      <ReviewWrapper className="position-relative">
        <h5 className="mb-5">
          {t("Please double check your enrollment details")}
        </h5>
        {reviewContent()}
        <EnrollmentFormRow>
          {agreements &&
            agreementValues &&
            Object.keys(agreements).map((type, typeIdx) => {
              const data = agreements[type];
              let availableDocs = undefined;
              const findPlan = selectedPlans.find(
                (plan: any) => plan.commodity_name === type
              );
              if (findPlan && findPlan.docs) {
                availableDocs = findPlan.docs.filter(
                  (val: any) => val.display_in_summary === true
                );
              }
              return (
                <div className="ps-3 pe-3" key={typeIdx}>
                  <h5 className="mb-3">{t(`Authorize ${type} Enrollment`)}</h5>
                  {availableDocs && (
                    <div className="plan-links mb-4 ms-4">
                      {availableDocs.map((doc: any, idx: number) => (
                        <PlanSummaryRow narrow key={idx}>
                          <a href={doc.url} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon="file-pdf" />
                            <span className="ms-2">{doc.display_name}</span>
                          </a>
                        </PlanSummaryRow>
                      ))}
                    </div>
                  )}
                  {data.map((val: string, idx: number) => (
                    <EnrollmentFormCol full key={idx}>
                      <div className="checkbox-row">
                        <CheckboxItem
                          label={t(val)}
                          value={`${agreementValues[type][idx]}`}
                          checked={agreementValues[type][idx] === true}
                          handleClick={() => {
                            let newAgreementValues = Object.assign(
                              {},
                              agreementValues
                            );
                            let newAgreesError = Object.assign(
                              {},
                              agreementErrors
                            );
                            newAgreementValues[type][idx] =
                              !newAgreementValues[type][idx];

                            const findErrorIdx =
                              newAgreesError[type] &&
                              newAgreesError[type].indexOf(idx);
                            if (findErrorIdx >= 0) {
                              newAgreesError[type].splice(findErrorIdx, 1);
                              setAgreementErrors(newAgreesError);
                            }
                            setAgreementValues(newAgreementValues);
                          }}
                          onlyread={false}
                          disabled={loading}
                          error={
                            agreementErrors[type] &&
                            agreementErrors[type].indexOf(idx) >= 0
                          }
                        />
                      </div>
                    </EnrollmentFormCol>
                  ))}
                </div>
              );
            })}
        </EnrollmentFormRow>
        {/* <EnrollmentFormRow>
                    <EnrollmentFormCol full>
                        <div className="checkbox-row">
                            <CheckboxItem
                                label="Create Account Login (Optional)"
                                value=''
                                checked={showCreateAccount}
                                handleClick={() => {
                                    setShowCreateAccount(!showCreateAccount);
                                }}
                                onlyread={false}
                                disabled={loading}
                                error={false}
                            />
                        </div>
                    </EnrollmentFormCol>
                </EnrollmentFormRow> */}
        {/*
                    showCreateAccount &&
                    <CreateAccountSection>
                        <EnrollmentFormRow>
                            <EnrollmentFormCol>
                                <Form.Group className={`mt-0 ${errorFields['username'] ? 'error' : ''}`}>
                                    <Form.Label>{t('Username')} *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        placeholder=""
                                        maxLength={20}
                                        value={username}
                                        onChange={(evt: any) => setUsername(evt.target.value)}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <FormError message={errorFields['username']} />
                            </EnrollmentFormCol>
                            <EnrollmentFormCol>
                                <InputWithMask>
                                    <Form.Group className={`mt-0 ${errorFields['password'] ? 'error' : ''}`}>
                                        <Form.Label>{t('Password')} *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="password"
                                            maxLength={20}
                                            value={password}
                                            onChange={(evt: any) => setPassword(evt.target.value)}
                                            disabled={loading}
                                        />
                                        {
                                            !showPassword &&
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                maxLength={20}
                                                value={password}
                                                className="mask-input"
                                                onChange={(evt: any) => setPassword(evt.target.value)}
                                                disabled={loading}
                                            />
                                        }
                                    </Form.Group>
                                    <InputMaskToggle type="button" active={showPassword} onClick={() => setShowPassword(!showPassword)} />
                                </InputWithMask>
                                <FormError message={errorFields['password']} />
                            </EnrollmentFormCol>
                        </EnrollmentFormRow>
                    </CreateAccountSection>
                    */}
        {!isTx && isMi && (
          <EnrollmentFormCol>
            <Form.Group
              className={`mt-0 ${errorFields["username"] ? "error" : ""}`}
            >
              <Form.Label>{t("Your Signature here")} *</Form.Label>
              <Form.Control
                type="text"
                name="signName"
                placeholder=""
                value={signName}
                onChange={(evt: any) => setSignName(evt.target.value)}
                disabled={loading}
                style={{
                  fontFamily: "'Satisfy', cursive",
                  fontSize: "18px",
                  width: "100%",
                }}
              />
            </Form.Group>
            <FormError message={errorFields["signName"]} />
          </EnrollmentFormCol>
        )}
        <EnrollmentStepFooter>
          <Button
            type="button"
            secondary
            disabled={submitting}
            onClick={() => handleBack()}
          >
            {t("Back")}
          </Button>
          <Button
            id="complete-btn"
            type="button"
            onClick={() => handleSubmit()}
            data-test-id="cta"
          >
            <span>{t("Complete Enrollment")}</span>
            {submitting && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="ms-2"
              />
            )}
          </Button>
        </EnrollmentStepFooter>
        {reviewDisclaimer()}
        <FormLoading
          show={submitting}
          content={t("This process may take up to 1 minute", { ns: "common" })}
        />
      </ReviewWrapper>
    );
  };

  if (!show) return null;
  return <EnrollmentStepWrapper>{getContent()}</EnrollmentStepWrapper>;
};
