type PUCT_number_type = {
  [key: string]: string;
};

export const PUCT_number: PUCT_number_type = {
  "210": "10259", // Pulse
  "194": "10105", // APGE
  "183": "10098", // Champion
  "239": "10279", // Rhythm
  "258": "10293", // Value Power
  "196": "10014", // Constellation
  "269": "10308", // GoodCharlie
};

export const consent_mi = (utility: string) =>
  `I acknowledge that I am the account holder or legally authorized person to execute a contract on behalf of the account holder. I understand that by signing this agreement, I am switching the gas Supplier for this account to CleanSky Energy. I understand that the gas purchased for this account by CleanSky Energy will be delivered through ${utility}'s delivery system. The account holder, or the person who signed this contract on behalf of the account holder, has 30 days after today to cancel this contract for any reason written or verbal verification to CleanSky Energy.`;
