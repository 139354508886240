import { useTranslation } from "react-i18next";
import { StyledProcessBar, ProcessBarItem } from "../styles/ProcessBar.styled";
interface Props {
  step: number;
}

export const ProcessBar: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation("enrollment");

  const process = [
    t("Zip Code"),
    t("plans"),
    t("Service Start Date"),
    t("Information"),
    t("Review"),
  ];

  //currentUrl.searchParams.get('sign') is for checking whether it is magic link or not
  const currentUrl = new URL(window.location.href);
  if (
    ((step < 1 || step > 3) && ![11, 12, 13].includes(step)) ||
    currentUrl.searchParams.get("sign")
  )
    return null;
  //  if ((step < 1 || step > 3)) return null;

  return (
    <StyledProcessBar>
      {process.map((val, idx) => (
        <ProcessBarItem
          active={
            idx - 2 + 1 === (step === 13 ? 0 : step) ||
            idx + 11 === (step === 13 ? 0 : step)
          }
          key={idx}
        >
          {`${idx + 1}. ${val}`}
        </ProcessBarItem>
      ))}
    </StyledProcessBar>
  );
};
