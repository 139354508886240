import { useEffect, useState, createContext } from 'react'
import { Container } from 'react-bootstrap'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import qs from 'qs'
import {
    EnrollmentContainer,
    EnrollmentMain,
    EnrollmentAside,
    AddressToggleRow
} from './components/styles/Enrollment.styled'
import { ProcessBar } from './components/enrollment/ProcessBar'
import { EnrollmentStepServiceTx } from './components/enrollment/StepServiceTx'
import { EnrollmentStepInfo } from './components/enrollment/StepInfo'
import { EnrollmentStepConfirmation } from './components/enrollment/StepConfirmation'
import { EnrollmentStepReview } from './components/enrollment/StepReview'
import type { PlanProps, Address } from './types'
import { getEnrollmentInputs } from './api/enrollment'
import { fetchIsWhitelisted, fetchProducts, getCustomerDataForRedirection, isDepositWaived, setORSession, updateStatus } from './api/api'
import { PlanModal } from './components/PlanModal'
import { FormLoading } from './components/form-loading'
import { PROVIDER_ID } from './config'
import { RateComparisonTx } from './components/RateComparisonTx'
import { RateComparisonUsn } from './components/RateComparisonUsn'
import { ErrorModal } from './components/ErrorModal'
import { LoadingRow } from './components/loading-row'
import { PlanSummary } from './components/PlanSummary'
import { EnrollmentStepServiceUsn } from './components/enrollment/StepServiceUsn'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { UtilityModal } from './components/UtilityModal'
import { UtilityWarningModal } from './components/UtilityWarningModal'
import ExtraPage from './extrapage'
import ExistingCustomer from './components/enrollment/AgentWebPortal/ExistingCustomer'
import CreditCheckScreen from './components/enrollment/AgentWebPortal/CreditCheckScreen'
import { Zipcode } from './components/enrollment/zipcode'
import { RateComparisonUsnGas } from './components/RateComparisonUsnGas'
import { PlanModalDuke } from './components/PlanModalDuke'
import { CircularProgress } from '@mui/material'
import { dukeZipcodes } from './common/dukeZipcodes'

// import useIpLocation from './hooks/useIpLocation'

export const EnrollmentContext = createContext<any>(null);

const defaultGasPromocode = ['PAGASZERO', 'MDGAS'];

function sortGasArray(data: any) {
    if (!data || !data.Gas) {
      return data;
    }
    let gasArray = data.Gas;
  
    gasArray.sort((a: any, b: any) => {
      if (a.name.includes("Duke")) return -1;
      if (b.name.includes("Duke")) return 1;
      return 0;
    });
  
    data.Gas = gasArray;
    return data;
  }
  

export const Enrollment = ({ handleSetIsTx, handleSetEnableSwitchLang, openReplayTracker, openreplayData, setScriptData, isAdmin }: {
    handleSetIsTx: Function;
    handleSetEnableSwitchLang: Function;
    openReplayTracker: any;
    openreplayData: any;
    setScriptData: Function;   
    isAdmin: boolean; 
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation('enrollment');
    const { promocode, zipcode, planId , commodity } = qs.parse(
        location.search, {
            ignoreQueryPrefix: true,
    })
    let { utilityId } = qs.parse(
        location.search, {
            ignoreQueryPrefix: true,
        }
    )
    const [ step, setStep ] = useState<number>(11)
    const [ enrollmentResult, setEnrollmentResult ] = useState<any>(undefined)
    const [ dynamicFields, setDynamicFields ] = useState(undefined)
    const [ specialPro, setSpecialPro ] = useState({ ssn: '', date_of_birth: '' })
    const [ serviceAddress, setServiceAddress ] = useState<Address|undefined>(undefined);
    const [ selectedUtility, setSelectedUtility ] = useState<any>(undefined);
    const [ allPlans, setAllPlans ] = useState<any>(undefined);
    const [ selectedPlans, setSelectedPlans ] = useState<PlanProps[]>([]);
    const [ utilityList, setUtilityList ] = useState<any>(undefined);
    const [ showPlanModal, setShowPlanModal ] = useState(false);
    const [ showUtilityModal, setShowUtilityModal ] = useState(false);
    const [multiUtility, setMultiUtility] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ promoCode, setPromoCode ] = useState('');
    const [ errorModalContent, setErrorModalContent ] = useState<any>(undefined);
    const [ isTx, setIsTx ] = useState(false);
    const [ isFindingLocation, setIsFindingLocation ] = useState(false); // stick with USN
    const [ usnZipcode, setUsnZipcode ] = useState(''); // for USN
    const [ showSwitchToTxLabel, setShowSwitchToTxLabel ] = useState(false);
    const [ enrollmentTokens, setEnrollmentTokens ] = useState<any>([]);
    const [ stateId, setStateId ] = useState('');
    const [ customerName, setCustomerName ] = useState('');
    const [ showFinalPage, setShowFinalPage ] = useState(false);
    const [optionSelected,setOptionSelected] = useState("Both");
    const[showWarning,setShowWarning] = useState(false);
    const [activeKey, setActiveKey] = useState(optionSelected == "Both"?"Electric":optionSelected); // Set the default active tab key
    // tesitng
    const [hasUtility, setHasUtitlity] = useState(false);
    const [loader,setLoader] = useState(false)
    

      //45154

    // const { ipLocation } = useIpLocation();
    
    const changeServiceAddress = (val: Address) => setServiceAddress(val);


    const currentUrl = new URL(window.location.href);
    const redirectionForDukeCampaign =  currentUrl.searchParams.get('utm_campaign') === 'Duke Gas';
    utilityId = redirectionForDukeCampaign ? '84' : utilityId;
    const [dukeRdrZipcode, setDukeRdrZipCode] = useState('')
    
    const { urlStep } = useParams();
    
    useEffect(() => {
        setLoader(true)
        if(redirectionForDukeCampaign && step>10){
            setStep(13)
            setLoader(false)
            let params = window.location.href.split("?")[1]
            params ? navigate(`/plans?${params}`) : navigate('/plans');
            return;
        }
        if(step === 11){
            let params = window.location.href.split("?")[1]
            params ? navigate(`/zipcode?${params}`) : navigate('/zipcode');
        }
        (async()=>{
            if(step === 100) return
            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const custSign = url.searchParams.get('sign');
            const isAgent = url.searchParams.get('isAgent');

            const customerDataForRedirection = await getCustomerDataForRedirection(idParam, custSign);

            const idName = `${customerDataForRedirection?.name}`.replace(" ","%20");
            const idStatus = customerDataForRedirection?.status;
            const idIsTx = customerDataForRedirection?.isTx;
            const depositWaived = Boolean(customerDataForRedirection?.deposit_waiver_flag)
            const finalPage = Boolean(customerDataForRedirection?.final_page);
            const dateCheck = customerDataForRedirection?.whitelist_date;
            const payNow = Boolean(customerDataForRedirection?.paynow);
            const whitelistFlag = customerDataForRedirection?.whitelist_flag;

            const autopayOverrideFlag = customerDataForRedirection?.autopay_override;
            const autopayRequiredFlag = customerDataForRedirection?.autopay_required;
            const autopaySetupCompleted = customerDataForRedirection?.sub_status === 'Autopay-Complete' ? true : false;

            if(idStatus == "10.E02" || idStatus == "20.E01" || idStatus == "40.E04"){
                // let _resp = await fetchIsWhitelisted(idParam);
                // const resp = [_resp]
                // let finalPage = Boolean(resp[0]?.final_page);

                if(finalPage){
                    navigate(`/confirmation?id=${idParam}&name=${idName}&status=${idStatus}&isTx=${idIsTx}&sign=${custSign}&isAgent=${isAgent}`);
                    setEnrollmentResult([
                        {
                            "status": payNow ? "20.E01" : "10.E02",
                            "message": "",
                            "additional": [
                                {
                                    "label": "Pay deposit",
                                    "value": "195",
                                    "link": "",
                                    "type": "amount",
                                    "message": "",
                                    "final": "true",
                                    "split_payment": String(customerDataForRedirection?.split_payment)
                                }
                            ]
                        }
                    ])
                    setIsTx(Boolean(idIsTx))
                    handleSetIsTx(Boolean(idIsTx))
                    setCustomerName(idName || "")
                    setShowFinalPage(true)
                    setStep(4)
                }else{
                    if(autopayOverrideFlag){
                        navigate(`/confirmation?id=${idParam}&name=${idName}&status=${idStatus}&isTx=${idIsTx}&sign=${custSign}&isAgent=${isAgent}`);
                        setEnrollmentResult([
                            {
                                "status": '10.E02',
                                "message": "",
                                "additional": [
                                    {
                                        "label": "Pay deposit",
                                        "value": "195",
                                        "link": "",
                                        "type": "amount",
                                        "message": "",
                                        "autopay_override": true,
                                        "autopay_completed": autopaySetupCompleted,
                                    }
                                ]
                            }
                        ])
                        setIsTx(Boolean(idIsTx))
                        handleSetIsTx(Boolean(idIsTx))
                        setCustomerName(idName || "")
                        setStep(4)
                    }
                    else if(idStatus == "10.E02" || depositWaived){ //auto pay - Export to excel
                        navigate(`/confirmation?id=${idParam}&name=${idName}&status=${idStatus}&isTx=${idIsTx}&sign=${custSign}&isAgent=${isAgent}`);
                        setEnrollmentResult([
                            {
                                "status": '10.E02',
                                "message": "",
                                "additional": [
                                    {
                                        "label": "Pay deposit",
                                        "value": "195",
                                        "link": "",
                                        "type": "amount",
                                        "message": ""
                                    }
                                ]
                            }
                        ])
                        setIsTx(Boolean(idIsTx))
                        handleSetIsTx(Boolean(idIsTx))
                        setCustomerName(idName || "")
                        setStep(4)
                    }else{ //pay now - Deposit required
                        navigate(`/confirmation?id=${idParam}&name=${idName}&status=${idStatus}&isTx=${idIsTx}&sign=${custSign}&isAgent=${isAgent}`);
                        setEnrollmentResult([
                            {
                                "status": '20.E01',
                                "message": "",
                                "autopay_required": autopayRequiredFlag,
                                "additional": [
                                    {
                                        "label": "Pay deposit",
                                        "value": "195",
                                        "link": "",
                                        "type": "amount",
                                        "message": ""
                                    }
                                ]
                            }
                        ])
                        setIsTx(Boolean(idIsTx))
                        handleSetIsTx(Boolean(idIsTx))
                        setCustomerName(idName || "")
                        setStep(4)
                    }
                }
            }else{
                if(idParam && idName){                    
                    if(finalPage){
                        navigate(`/confirmation?id=${idParam}&name=${idName}&status=${idStatus}&isTx=${idIsTx}&sign=${custSign}&isAgent=${isAgent}`);
                        setEnrollmentResult([
                            {
                                "status": '20.E01',
                                "message": "",
                                "additional": [
                                    {
                                        "label": "Pay deposit",
                                        "value": "195",
                                        "link": "",
                                        "type": "amount",
                                        "message": ""
                                    }
                                ]
                            }
                        ])
                        setIsTx(Boolean(idIsTx))
                        handleSetIsTx(Boolean(idIsTx))
                        setCustomerName(idName || "")
                        setShowFinalPage(true)
                        setStep(4)
                    }
                    else if(idParam && whitelistFlag == 1 && (moment(moment(dateCheck, 'YYYY-MM-DD').toDate()) >= moment(moment(new Date()).format("YYYY-MM-DD")))){
                        if(finalPage){
                            navigate(`/confirmation?id=${idParam}&name=${idName}&status=${idStatus}&isTx=${idIsTx}&sign=${custSign}&isAgent=${isAgent}`);
                            setEnrollmentResult([
                                {
                                    "status": '20.E01',
                                    "message": "",
                                    "additional": [
                                        {
                                            "label": "Pay deposit",
                                            "value": "195",
                                            "link": "",
                                            "type": "amount",
                                            "message": ""
                                        }
                                    ]
                                }
                            ])
                            setIsTx(Boolean(idIsTx))
                            handleSetIsTx(Boolean(idIsTx))
                            setCustomerName(idName || "")
                            setShowFinalPage(true)
                            setStep(4)
                        }
                        else if(payNow && !depositWaived){ //to pay now page
                            navigate(`/confirmation?id=${idParam}&name=${idName}&status=${idStatus}&isTx=${idIsTx}&sign=${custSign}&isAgent=${isAgent}`);
                            setEnrollmentResult([
                                {
                                    "status": '20.E01',
                                    "message": "",
                                    "additional": [
                                        {
                                            "label": "Pay deposit",
                                            "value": "195",
                                            "link": "",
                                            "type": "amount",
                                            "message": ""
                                        }
                                    ]
                                }
                            ])
                            setIsTx(Boolean(idIsTx))
                            handleSetIsTx(Boolean(idIsTx))
                            setCustomerName(idName || "")
                            setStep(4)
                        }else{ //auto pay page
                            navigate(`/confirmation?id=${idParam}&name=${idName}&status=${idStatus}&isTx=${idIsTx}&sign=${custSign}&isAgent=${isAgent}`);
                            setEnrollmentResult([
                                {
                                    "status": '10.E02',
                                    "message": "",
                                    "additional": [
                                        {
                                            "label": "Pay deposit",
                                            "value": "195",
                                            "link": "",
                                            "type": "amount",
                                            "message": ""
                                        }
                                    ]
                                }
                            ])
                            setIsTx(Boolean(idIsTx))
                            handleSetIsTx(Boolean(idIsTx))
                            setCustomerName(idName || "")
                            setStep(4)
                        }
                    }
                }
                else{
                    const url = new URL(window.location.href);
                    switch (urlStep) {
                        // case 'service_date': setStep(1); break;
                        case 'information': setStep(2); break;
                        case 'review': setStep(3); break;
                        case 'confirmation': setStep(4); break;
                        // case 'existing_customer': setStep(5); break; 
                        // case 'credit_check' : setStep(6); break;
                         // to be directed for magic link
                        // default: setStep(11);
                    }
                }
            }

        })()
        setLoader(false)
    }, [urlStep])

    const changeUtilityList = (val: any) => {
        setUtilityList(undefined);
        setSelectedUtility(undefined);
        setSelectedPlans([]);
        if (val) {
            const _result = val.reduce((obj: any, item: any) => {
                const key = item.commodity_name;
                if (!obj[key]) obj[key] = [];
                obj[key].push(item);
                return obj;
            }, {});

            const result = sortGasArray(_result)
           
            const defaultValue = Object.keys(result).reduce((obj: any, key: any) => {
                obj[key] = ((result[key][0]['id_utility'] ? String(result[key][0]['id_utility']) : null) || result[key][0]['utility_code']) || '';
                return obj;
            }, {});

            if (val[0]) {
                const { state } = val[0];
                if (state) {
                    setStateId(state.state_id);
                }
            }
            const sortedResult: any = {};
            Object.keys(result)
                .sort()
                .forEach(key => {
                    sortedResult[key] = result[key];
                })
            setUtilityList(sortedResult);
            if(utilityId && commodity){
                //@ts-ignore
                setSelectedUtility({[commodity]:utilityId})
                redirectionForDukeCampaign ? setStep(13) : setStep(12)
            }
            else{
                setSelectedUtility(defaultValue);
            }
            utilityId && setShowPlanModal(false)
        }
    };

    const storePromoCode = (val: string) => setPromoCode(val);
    const handleSetErrorModalContent = (val: any) => setErrorModalContent(val);
    const changeZipcode = (val: string) => {setUsnZipcode(val)};
    
    useEffect(() => {
        if (zipcode) {
            setUsnZipcode(`${zipcode}`);
        }
    }, [zipcode])

    // useEffect(() => {
        // if (ipLocation) {
        //     setIsTx(ipLocation.state === 'TX');
        //     setIsFindingLocation(false);
        // }
    // }, [ipLocation])

    useEffect(()=>{
        switch(window.location.pathname){
            case "/zipcode" : setStep(11); break;
            case "/plans" : redirectionForDukeCampaign ? setStep(13) : setStep(12); break;
            case "/serviceAddress" : setStep(1); break;
            case "/information" : setStep(2); break;
            case "/review" : setStep(3); break;
            default : break;
        }
    },[window.location.pathname])

    useEffect(() => {
        if ((!selectedUtility || isFindingLocation) && !redirectionForDukeCampaign) return;
        //  showPlanModal && !multiUtility && getAllPlans();
        if(step==12 || step==13){
            getAllPlans()
        }
        getDynamicInputs();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUtility, usnZipcode, isFindingLocation,showPlanModal, step, dukeRdrZipcode])

    const getDynamicInputs = () => {
        if (!selectedUtility && !redirectionForDukeCampaign) return;

        const utilityCode = redirectionForDukeCampaign ? utilityId : Object.values(selectedUtility)[0];
        
        let res = undefined;
        if (isTx) {
            res = getEnrollmentInputs(
                PROVIDER_ID,
                serviceAddress?.zipcode || '',
                utilityCode
            )
        }
        else {
            res = getEnrollmentInputs(
                PROVIDER_ID,
                redirectionForDukeCampaign ? dukeRdrZipcode : usnZipcode,
                utilityCode
            )
        }
        res.then((res) => {
            if (res && parseInt(res.status) === 1) {
                setDynamicFields(res.response)
            } else {
                console.error('Failed to fetch input fields')
            }
        })
    }

    const handlePlanPromises = (planPromises: any) => {
        Promise.all(planPromises).then(res => {
            setLoading(false);
            if (res) {
                const transformedData = res.reduce((result: any, item: any) => {
                    const key = Object.keys(item)[0];
                    if (key !== 'na') {
                        result[key] = item[key];
                    }
                    return result;
                }, {});

                setAllPlans(transformedData);
                
                if (transformedData?.Electric || transformedData?.Gas) {
                    const electricPlan = transformedData?.Electric || transformedData?.Gas;
                    const findPlan = electricPlan.find((plan: PlanProps) => plan.plan_id === planId);

                    if (findPlan) {
                        if (selectedUtility) {
                            const utilityCode = Object.values(selectedUtility)[0];
                            const productWithCode = Object.assign({}, findPlan, { utilityCode });
                            setSelectedPlans([productWithCode]);
                        }
                        else if(findPlan?.id_utility && findPlan?.commodity_name){
                            const utilityCode = findPlan?.id_utility;
                            const productWithCode = Object.assign({}, findPlan, { utilityCode });
                            setSelectedPlans([productWithCode]);
                        }
                        let params = window.location.href.split("?")[1]
                        params ? navigate(`/serviceAddress?${params}`) : navigate(`/serviceAddress`)
                        setStep(1)
                    }
                    else {
                    //    multiUtility && 
                    // !planId && setShowPlanModal(true);
                    !planId && (redirectionForDukeCampaign ? setStep(13) : setStep(12));
                    }
                }
            }
            else {
                setErrorModalContent({
                    title: 'No data found'
                });
            }
        }).catch(error => {
            setLoading(false);
            if(error){
                setErrorModalContent({
                    title: error,
                    buttonText: (error.length && error?.indexOf('promo code') >= 0) ? 'Continue with Enrollment' : ''
                });
            }
        })
    }

    const getAllPlans = () => {
        if (loading) return;

        let _zipcode = '';
        
        if (isTx) _zipcode = serviceAddress ? serviceAddress?.zipcode : usnZipcode;
        if (!isTx && usnZipcode) _zipcode = usnZipcode;
        
        if (!_zipcode && !redirectionForDukeCampaign) return null;

        setLoading(true);
        setSelectedPlans([]);
        setAllPlans(undefined);
        
        const utilityCodes = Object.values(redirectionForDukeCampaign ? {Gas: utilityId}  : selectedUtility);
        const promises = (utilityCodes as string[]).map(code => getPlan(code, _zipcode));

        handlePlanPromises(promises);
    }

    const getPlan = (utilityCode: string, zipcode: string) => {
        return new Promise((resolve, rejects) => {
            fetchProducts(utilityCode, zipcode, promocode, redirectionForDukeCampaign)
                .then(data => {
                    if (data.error_code && data.error_code === '500') {
                        // rejects(data.message);
                        resolve({ 'na': [] });
                    }
                    if (data.response && data.response.plans && data.response.plans.length > 0) {
                        resolve({ [data.response.plans[0].commodity_name]: data.response.plans });
                    }
                    rejects('No data found');
                })
                .catch(error => rejects(error))
        })
    }

    const preFetchTxPlans = (txZipUtility: any) => {
        const { zipcode, utility } = txZipUtility;
        const utilityCode =utilityId? utilityId : (utility[0] ? utility[0].utility_code : '');
        const promises = [getPlan(utilityCode, zipcode)];

        handlePlanPromises(promises);
    }

    const handleSwitchArea = () => {
        setServiceAddress(undefined);
        setUtilityList(undefined);
        setSelectedUtility(undefined);
        setAllPlans(undefined);
        setSelectedPlans([]);
        setUsnZipcode('');
        setLoading(false);
    }

    useEffect(()=>{
        const res = checkUtilityLength()
        let finalres = res?.some((value:any) => value === true)
        if(!finalres && utilityList){
            setMultiUtility(true);
            // setShowPlanModal(true);
            redirectionForDukeCampaign ? setStep(13) : setStep(12);
        }
        //@ts-ignore
        if(usnZipcode || zipcode){
            if(dukeZipcodes.includes(Number(zipcode)) || dukeZipcodes.includes(Number(usnZipcode))){
                setActiveKey("Gas")
                const url = new URL(window.location.href);
                const params = new URLSearchParams(url.search);
                if(step!==12 && !params.has("cookiesAccepted")){
                    redirectionForDukeCampaign ? setStep(13) : setStep(12)
                }
                // adding by praven for tesitn use cases
                else 
                {
                    if(!hasUtility)
                    {
                        setStep(11)
                        setHasUtitlity(true)
                    }else if(utilityList){
                        redirectionForDukeCampaign ? setStep(13) : setStep(12)
                    }
                }
            }else{
                !utilityId && setShowUtilityModal(finalres)
                finalres && setOptionSelected("Both")
            }
        }
    },[utilityList, usnZipcode])

    useEffect(()=>{
        if(showPlanModal){
            setMultiUtility(false)
        }
        if(showUtilityModal){
            setShowPlanModal(false)
        }
        if(selectedPlans?.length>0){
            setShowUtilityModal(false)
        }
    },[showPlanModal,selectedPlans.length])

    const checkUtilityLength = ()=>{
        return utilityList && Object.keys(utilityList).map((key, idx) =>{
            if( utilityList[key].length > 1){
                return true
            }else{
                return false
            }
        })
    }

    return (
        loader?
        <div style={{display:'flex',justifyContent:'center', marginTop:"30px"}}>
                    <CircularProgress size={"30px"} style={{marginLeft: "10px"}}/>
                </div>
        :<div>
            <Container>
                <ProcessBar step={step} />
                <PlanModal
                    zipcode={usnZipcode}
                    loading={loading}
                    show={step === 12}
                    setStep = {setStep}
                    handleClose={() => setShowPlanModal(false)}
                    data={allPlans}
                    isTx={isTx}
                    utilityList={utilityList}
                    utility={selectedUtility}
                    optionSelected = {optionSelected}
                    setOptionSelected = {setOptionSelected}
                    activeKey = {activeKey}
                    setActiveKey = {setActiveKey}
                    selectedPlans={selectedPlans}
                    showWarning = {showWarning}
                    setScriptData = {setScriptData}
                    isDefaultGas={(defaultGasPromocode.indexOf(`${promocode}`.toUpperCase()) >= 0)}
                    selectProduct={(product: any, utilityCode: string) => {
                        // add utility code to the plan object
                        const productWithCode = Object.assign({}, product, { utilityCode });
                        if (Object.keys(utilityList).length > 1) {
                            let [ ...newSelectedPlans ] = selectedPlans;
                            const sameIdidx = newSelectedPlans.findIndex((val: any) => val.plan_id === product.plan_id);
                            const sameCommodityIdx = newSelectedPlans.findIndex((val: any) => val.commodity_name === product.commodity_name);
                            
                            if (sameIdidx >= 0) {
                                newSelectedPlans.splice(sameIdidx, 1);
                            }
                            else if (sameCommodityIdx >= 0) {
                                newSelectedPlans.splice(sameCommodityIdx, 1);
                                newSelectedPlans.push(productWithCode);
                            }
                            else {
                                newSelectedPlans.push(productWithCode)
                            }
                            setSelectedPlans(newSelectedPlans);
                        }
                        else {
                            setSelectedPlans([productWithCode]);
                            setShowPlanModal(false);
                        }
                    }}
                    handleChangeUtility={(commodityName: string, utilityCode: string) => {
                        let newSelectedUtility = selectedUtility ? Object.assign({}, selectedUtility) : {};
                        newSelectedUtility[commodityName] = utilityCode;
                        setSelectedUtility(newSelectedUtility);
                    }}
                    handleConfirm={() => {
                        if(!selectedPlans?.length){
                            return;
                        } 
                        else if(optionSelected == "Both" && selectedPlans.length != 2){
                            setShowWarning(true)
                        }
                        else{
                            setShowPlanModal(false);
                            let params = window.location.href.split("?")[1]
                            params ? navigate(`/serviceAddress?${params}`) : navigate(`/serviceAddress`)
                            setStep(1)
                        }
                        // setShowPlanModal(false)
                    }}
                />
                <PlanModalDuke
                    loading={loading}
                    show={step === 13}
                    setStep = {setStep}
                    data={allPlans}
                    isTx={isTx}
                    utilityList={{Gas: [{
                        "commodity_name": "Gas",
                        "commodity_name_from": "plan",
                        "name": "Duke Energy Ohio (Gas)",
                        "logo": "14eb7a10-19e7-4e21-af3e-a2e38254d2e6.png",
                        "id_utility": utilityId,
                        "formal_name": "Duke Energy Ohio Gas",
                        "ldc_length": "22",
                        "ldc_mask": "############Z#########",
                        "utility_code": "DUKEOHG",
                        "ldc_only_numbers": false,
                        "wizard_sample_bill_label": "You can find the Account number on your existing bill.\nPlease be aware that entering the wrong utility account number will delay your enrollment. ",
                        "wizard_ldc_label": "Account Number",
                        "is_zone_required": false,
                        "pool_id": "",
                        "markets": {
                            "id_market": 32,
                            "name": "Ohio Market",
                            "active": true,
                            "state_code": "OH",
                            "state_name": "Ohio",
                            "handle_payments": false
                        },
                        "sample_bill": "8662cc1c-d6d2-445a-bb7d-fdace0b07937.jpg",
                        "friendly_name": "Duke Energy Ohio Gas",
                        "customer_name_key_required": false,
                        "customer_name_key_length": null,
                        "state": {
                            "state_id": "OH",
                            "state_name": "Ohio",
                            "city": "Columbus",
                            "county_names": [
                                "Franklin"
                            ]
                        }
                    }]}}
                    utility={{Gas: utilityId}}
                    optionSelected = {optionSelected}
                    activeKey = {activeKey}
                    setActiveKey = {setActiveKey}
                    selectedPlans={selectedPlans}
                    showWarning = {showWarning}
                    setScriptData = {setScriptData}
                    isDefaultGas={(defaultGasPromocode.indexOf(`${promocode}`.toUpperCase()) >= 0)}
                    selectProduct={(product: any, utilityCode: string) => {
                        // add utility code to the plan object
                        const productWithCode = Object.assign({}, product, { utilityCode });
                        if (Object.keys({Gas: utilityId}).length > 1) {
                            let [ ...newSelectedPlans ] = selectedPlans;
                            const sameIdidx = newSelectedPlans.findIndex((val: any) => val.plan_id === product.plan_id);
                            const sameCommodityIdx = newSelectedPlans.findIndex((val: any) => val.commodity_name === product.commodity_name);
                            
                            if (sameIdidx >= 0) {
                                newSelectedPlans.splice(sameIdidx, 1);
                            }
                            else if (sameCommodityIdx >= 0) {
                                newSelectedPlans.splice(sameCommodityIdx, 1);
                                newSelectedPlans.push(productWithCode);
                            }
                            else {
                                newSelectedPlans.push(productWithCode)
                            }
                            setSelectedPlans(newSelectedPlans);
                        }
                        else {
                            setSelectedPlans([productWithCode]);
                            setShowPlanModal(false);
                        }
                    }}
                    handleConfirm={() => {
                        if(!selectedPlans?.length){
                            return;
                        } 
                        else if(optionSelected == "Both" && selectedPlans.length != 2){
                            setShowWarning(true)
                        }
                        else{
                            setShowPlanModal(false);
                            let params = window.location.href.split("?")[1]
                            params ? navigate(`/serviceAddress?${params}`) : navigate(`/serviceAddress`)
                            setStep(1)
                        }
                        // setShowPlanModal(false)
                    }}
                />
                <EnrollmentContainer style={{minHeight:window.location.pathname === "/plans"?"0px":"500px"}}>
                <EnrollmentMain>
                        {
                            isFindingLocation ?
                            <div className="mt-5"><LoadingRow /></div>
                            :
                            <EnrollmentContext.Provider value={{
                                serviceAddress, selectedPlans, loading, promoCode, isTx, usnZipcode, enrollmentTokens,
                                changeServiceAddress, storePromoCode, handleSetErrorModalContent, changeZipcode, changeUtilityList
                            }}>
                                {step===0 && <LoadingRow />}
                                <Zipcode
                                    show={step === 11}
                                    handleContinue={(saveResponse: any) => {
                                        setEnrollmentTokens(saveResponse);
                                        setORSession(saveResponse[0]?.enrollment_id, openreplayData.sessionId);
                                        // updateStatus(saveResponse[0]?.enrollment_id, saveResponse[0]?.sign, "0", "New-Enrollment-Information", null)
                                        window.scrollTo(0, 0);
                                        navigate(`/information${location.search}`);
                                    }}
                                    isShowPlanModal={showPlanModal}
                                    handleOpenPlanModal={() => setStep(12)}
                                    handleSwitchToTx={(txZipUtility: any) => {
                                        setIsTx(true);
                                        handleSetIsTx(true);
                                        setShowSwitchToTxLabel(true);
                                        handleSwitchArea();
                                        preFetchTxPlans(txZipUtility);
                                        handleSetEnableSwitchLang(true);
                                    }}
                                    handleConfirmUsn={(val: boolean) => {
                                        handleSetEnableSwitchLang(!val);
                                    }}
                                    utilityList={utilityList}
                                    planIsLoaded={!!allPlans}
                                    handleOpenUtilityModal = {() => setShowUtilityModal(true)}
                                    setScriptData={setScriptData}
                                />
                                {/* STEP 1: SERVICE START DATE */}
                                {
                                    (showSwitchToTxLabel && step === 1) &&
                                    <AddressToggleRow>{t('The service area you are trying to enroll in is Texas. Please enter your service address below.')} <span onClick={() => {
                                        let url = new URL(window.location.href);
                                        url.searchParams.delete('zipcode');
                                        url.searchParams.delete('planId');
                                        url.searchParams.delete('utilityId');
                                        url.searchParams.delete('commodity');
                                        let newUrl = '/zipcode' + url.search;
                                        window.location.href = newUrl;
                                        setStep(11)
                                        setIsTx(false);
                                        handleSetIsTx(false);
                                        setShowSwitchToTxLabel(false);
                                        handleSwitchArea();
                                        handleSetEnableSwitchLang(true);
                                    }}>{t('If this is incorrect, enter a different ZIPCODE.')}</span></AddressToggleRow>
                                }
                                <EnrollmentStepServiceTx
                                    show={step === 1 && isTx === true}
                                    handleContinue={(saveResponse: any) => {
                                        setEnrollmentTokens(saveResponse);
                                        setORSession(saveResponse[0]?.enrollment_id, openreplayData.sessionId);
                                        window.scrollTo(0, 0);
                                        //status Prospect New-Enrollment-Information
                                        // updateStatus(saveResponse[0]?.enrollment_id, saveResponse[0]?.sign, "0", "New-Enrollment-Information", null)
                                        navigate(`/information${location.search}`);
                                    }}
                                    isShowPlanModal={showPlanModal}
                                    selectedUtlity={selectedUtility}
                                    planIsLoaded={!!allPlans}
                                    handleOpenPlanModal={() => setStep(12)}
                                    setScriptData={setScriptData}
                                    selectedPlans={selectedPlans}
                                />
                                <EnrollmentStepServiceUsn
                                    show={step === 1 && isTx === false}
                                    handleContinue={(saveResponse: any) => {
                                        setEnrollmentTokens(saveResponse);
                                        setORSession(saveResponse[0]?.enrollment_id, openreplayData.sessionId);
                                        // updateStatus(saveResponse[0]?.enrollment_id, saveResponse[0]?.sign, "0", "New-Enrollment-Information", null)
                                        window.scrollTo(0, 0);
                                        navigate(`/information${location.search}`);
                                    }}
                                    isShowPlanModal={showPlanModal}
                                    handleOpenPlanModal={() => redirectionForDukeCampaign ? setStep(13) : setStep(12)}
                                    handleSwitchToTx={(txZipUtility: any) => {
                                        setIsTx(true);
                                        handleSetIsTx(true);
                                        setShowSwitchToTxLabel(true);
                                        handleSwitchArea();
                                        preFetchTxPlans(txZipUtility);
                                        handleSetEnableSwitchLang(true);
                                    }}
                                    handleConfirmUsn={(val: boolean) => {
                                        handleSetEnableSwitchLang(!val);
                                    }}
                                    utilityList={utilityList}
                                    setDukeRdrZipCode={setDukeRdrZipCode}
                                    planIsLoaded={!!allPlans}
                                    handleOpenUtilityModal = {() => setShowUtilityModal(true)}
                                    setScriptData={setScriptData}
                                    redirectionForDukeCampaign={redirectionForDukeCampaign}
                                />

                                {/* STEP 2: INFORMATION */}
                                <EnrollmentStepInfo
                                    show={step === 2}
                                    handleBack={() => {
                                        window.scrollTo(0, 0);
                                        navigate(`/serviceAddress${location.search}`);
                                        setStep(1)
                                    }}
                                    handleContinue={(val: any) => {
                                        //status Prospect New-Enrollment-Review
                                        // updateStatus(enrollmentTokens[0]?.enrollment_id, enrollmentTokens[0]?.sign, "0", "New-Enrollment-Review", null)
                                        if(isAdmin){
                                            setEnrollmentResult(val)
                                            window.scrollTo(0, 0);
                                            navigate(`/confirmation${location.search}`);  
                                        }
                                        else{
                                            setSpecialPro(val)
                                            window.scrollTo(0, 0);
                                            navigate(`/review${location.search}`);
                                        }
                                    }}
                                    stateId={stateId}
                                    dynamicFields={dynamicFields}
                                    specialPro={specialPro}
                                    handleSetCustomerName={(val: string) => setCustomerName(val)}
                                    openReplayTracker = {openReplayTracker}
                                    setScriptData={setScriptData}
                                />

                                {/* STEP 3: REVIEW */}
                                <EnrollmentStepReview
                                    show={step === 3}
                                    handleContinue={(val: any) => {
                                        setEnrollmentResult(val)
                                        window.scrollTo(0, 0);
                                        navigate(`/confirmation${location.search}`);
                                    }}
                                    handleBack={() => {
                                        window.scrollTo(0, 0);
                                        navigate(`/information${location.search}`);
                                    }}
                                    handleReset={() => {
                                        navigate(`/${location.search}`);
                                    }}
                                    specialPro={specialPro}
                                    utilityList={
                                        !redirectionForDukeCampaign ? utilityList :
                                        {Gas: [{
                                            "commodity_name": "Gas",
                                            "commodity_name_from": "plan",
                                            "name": "Duke Energy Ohio (Gas)",
                                            "logo": "14eb7a10-19e7-4e21-af3e-a2e38254d2e6.png",
                                            "id_utility": utilityId,
                                            "formal_name": "Duke Energy Ohio Gas",
                                            "ldc_length": "22",
                                            "ldc_mask": "############Z#########",
                                            "utility_code": "DUKEOHG",
                                            "ldc_only_numbers": false,
                                            "wizard_sample_bill_label": "You can find the Account number on your existing bill.\nPlease be aware that entering the wrong utility account number will delay your enrollment. ",
                                            "wizard_ldc_label": "Account Number",
                                            "is_zone_required": false,
                                            "pool_id": "",
                                            "markets": {
                                                "id_market": 32,
                                                "name": "Ohio Market",
                                                "active": true,
                                                "state_code": "OH",
                                                "state_name": "Ohio",
                                                "handle_payments": false
                                            },
                                            "sample_bill": "8662cc1c-d6d2-445a-bb7d-fdace0b07937.jpg",
                                            "friendly_name": "Duke Energy Ohio Gas",
                                            "customer_name_key_required": false,
                                            "customer_name_key_length": null,
                                            "state": {
                                                "state_id": "OH",
                                                "state_name": "Ohio",
                                                "city": "Columbus",
                                                "county_names": [
                                                    "Franklin"
                                                ]
                                            }
                                        }]}}
                                    selectedUtlity={selectedUtility}
                                    setScriptData={setScriptData}
                                />

                                {/* STEP 4: CONFIRMATION */}
                                <EnrollmentStepConfirmation
                                    result={enrollmentResult}
                                    show={step === 4}
                                    setStep={setStep}
                                    isTx={isTx}
                                    handleReset={() => {
                                        navigate(`/${location.search}`);
                                    }}
                                    customerName={customerName}
                                    showFinalPage={showFinalPage}
                                    setScriptData={setScriptData}
                                />
                                {/* STEP 5: EXISTING CUSTOMER SCREEN */}
                                <ExistingCustomer
                                    show={step === 5}
                                    esiid={"1008901012126050605100"}
                                    badDebt={"$10"}
                                    status={"Not Paid"}
                                    magicLink={"https://magiclink"}
                                />
                                {/* STEP 5: CREDIT CHECK SCREEN */}
                                <CreditCheckScreen
                                    show= {step === 6}
                                    utility={selectedUtility}
                                    utilityList={utilityList}
                                />
                                {/* STEP 2: PLAN SCREEN*/}
                              

                                {/* STEP 100: PATH SPLITTED TO THIS PAGE AFTER CONFIRMATION */}
                                <ExtraPage setScriptData={setScriptData} show={step===100} setSpecialPro={setSpecialPro} setStep={setStep}/>
                            </EnrollmentContext.Provider>
                        }
                    </EnrollmentMain>
                    {(window.location.pathname !== "/plans" && window.location.pathname !== "/zipcode") && <EnrollmentAside>
                        <PlanSummary
                            data={selectedPlans}
                            isTx={isTx}
                            enrollmentStep={step}
                            handleShowPlanModal={() => {
                                let url = new URL(window.location.href);
                                if(url.searchParams.get('planId')){
                                    redirectionForDukeCampaign ? setStep(13) : setStep(12)
                                    url.searchParams.delete('planId');
                                    url.searchParams.delete('utilityId');
                                    let newUrl = '/' + url.search;
                                    window.location.href = newUrl;
                                }else{
                                    redirectionForDukeCampaign ? setStep(13) : setStep(12)
                                }
                            }}
                            utility={selectedUtility}
                            utilityList={utilityList}
                            redirectionForDukeCampaign={redirectionForDukeCampaign}
                        />
                        {
                            isTx ?
                            <RateComparisonTx
                                product={selectedPlans}
                                utilityList={utilityList}
                                utility={selectedUtility}
                            /> :
                            (dukeZipcodes.includes(Number(zipcode)) || dukeZipcodes.includes(Number(usnZipcode))?
                            <>
                            {/* @ts-ignore */}
                            {selectedPlans[0]?.commodity_name == "Gas"?
                                <>
                                    <RateComparisonUsnGas
                                        product={selectedPlans}
                                        utility={selectedUtility}
                                        zipcode={usnZipcode}
                                        redirectionForDukeCampaign={redirectionForDukeCampaign}
                                    />
                                    <RateComparisonUsn
                                        product={selectedPlans}
                                        utility={selectedUtility}
                                        zipcode={usnZipcode}
                                    />
                                </>:
                                <>
                                    <RateComparisonUsn
                                        product={selectedPlans}
                                        utility={selectedUtility}
                                        zipcode={usnZipcode}
                                    />
                                    <RateComparisonUsnGas
                                        product={selectedPlans}
                                        utility={selectedUtility}
                                        zipcode={usnZipcode}
                                        redirectionForDukeCampaign={redirectionForDukeCampaign}
                                    />
                                </>

                            }
                            </>

                            :( redirectionForDukeCampaign ? 
                                <RateComparisonUsnGas
                                    product={selectedPlans}
                                    utility={redirectionForDukeCampaign ? {Gas: utilityId} : selectedUtility}
                                    zipcode={usnZipcode}
                                    redirectionForDukeCampaign={redirectionForDukeCampaign}
                                />:
                                <RateComparisonUsn
                                    product={selectedPlans}
                                    utility={selectedUtility}
                                    zipcode={usnZipcode}
                                />
                            ))
                        }
                    </EnrollmentAside>}
                </EnrollmentContainer>
                
                <UtilityWarningModal show={showWarning} content={selectedPlans}  setActiveKey = {setActiveKey} handleSkip={()=> {
                    let params = window.location.href.split("?")[1]
                    params ? navigate(`/serviceAddress?${params}`) : navigate(`/serviceAddress`)
                    setShowPlanModal(false);
                    setShowWarning(false);
                    setStep(1)
                }} setShow={setShowWarning}                
                />
                <UtilityModal
                    loading={loading}
                    show={utilityList && showUtilityModal}
                    handleClose={() => setShowUtilityModal(false)}
                    data={allPlans}
                    isTx={isTx}
                    utilityList={utilityList}
                    utility={selectedUtility}
                    selectedPlans={selectedPlans}
                    setOptionSelected={setOptionSelected}
                    setMultiUtility= {setMultiUtility}
                    isDefaultGas={defaultGasPromocode.indexOf(`${promocode}`.toUpperCase()) >= 0}
                    // disableContinueButton={!(!!allPlans && selectedPlans.length === 0 && !showPlanModal)}
                    disableContinueButton={false}
                    setActiveKey={setActiveKey}
                    selectProduct={(product: any, utilityCode: string) => {
                        // add utility code to the plan object
                        const productWithCode = Object.assign({}, product, { utilityCode });
                        if (Object.keys(utilityList).length > 1) {
                            let [ ...newSelectedPlans ] = selectedPlans;
                            const sameIdidx = newSelectedPlans.findIndex((val: any) => val.plan_id === product.plan_id);
                            const sameCommodityIdx = newSelectedPlans.findIndex((val: any) => val.commodity_name === product.commodity_name);
                            
                            if (sameIdidx >= 0) {
                                newSelectedPlans.splice(sameIdidx, 1);
                            }
                            else if (sameCommodityIdx >= 0) {
                                newSelectedPlans.splice(sameCommodityIdx, 1);
                                newSelectedPlans.push(productWithCode);
                            }
                            else {
                                newSelectedPlans.push(productWithCode)
                            }
                            setSelectedPlans(newSelectedPlans);
                        }
                        else {
                            setSelectedPlans([productWithCode]);
                        }
                    }}
                    handleChangeUtility={(commodityName: string, utilityCode: string) => {
                        let newSelectedUtility = selectedUtility ? Object.assign({}, selectedUtility) : {};
                        newSelectedUtility[commodityName] = utilityCode;
                        setSelectedUtility(newSelectedUtility);
                    }}
                    handleConfirm={() => {
                        // let newSelectedUtility = selectedUtility ? Object.assign({}, selectedUtility) : {};
                        // newSelectedUtility[Object.keys(utilityList)[0]] = utilityList[Object.keys(utilityList)[0]]?.utility_code;
                        // setSelectedUtility(newSelectedUtility);
                        setStep(12)
                        setShowUtilityModal(false)
                    }}
                />
                <ErrorModal content={errorModalContent} handleClose={() => setErrorModalContent(undefined)} isTx={isTx} />
                {/* <FormLoading show={loading} /> */}
            </Container>
        </div>
    )
}
