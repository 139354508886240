import { useEffect, useState } from "react";
import { InputZipcode } from "./components/styles/Enrollment.styled";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useMediaQuery } from "react-responsive";

export const ValidateZipcode = ({
  handleSearchUtility,
  loadingUtility,
  defaultZipcode,
}: {
  handleSearchUtility: Function;
  loadingUtility: boolean;
  defaultZipcode: string;
}) => {
  const { t } = useTranslation("enrollment");
  const [zipcode, setZipcode] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    if (defaultZipcode) {
      setZipcode(defaultZipcode);
    }

    const url = window.location.href;
    const urlObj = new URL(url);
    const zipcode = urlObj.searchParams.get("zipcode");
    setZipcode(zipcode || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const urlObj = new URL(url);
    const _zipcode = urlObj.searchParams.get("zipcode");
    if (_zipcode && _zipcode.length === 5) {
      handleSearchUtility(_zipcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    if (zipcode && zipcode.length === 5) {
      handleSearchUtility(zipcode);
    }
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleSearch(); // Trigger the click handler
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          fontSize: isMobile ? "36px" : "48px",
          fontWeight: 700,
          color: "#253F66",
          margin: isMobile ? "48px 0px 24px 0px" : "80px 0px 24px 0px",
          maxWidth: isMobile ? "312px" : "864px",
          textAlign: "center",
        }}
      >
        {t("heading")}
      </div>
      <div
        style={{
          fontSize: "20px",
          fontWeight: 400,
          color: "#2D3748",
          margin: isMobile ? "0px 20px 48px 20px" : "0px 0px 48px 0px",
          maxWidth: "864px",
          textAlign: "center",
        }}
      >
        {t("subHeading")}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <InputZipcode>
          <input
            maxLength={5}
            placeholder={t("enterZipCode")}
            value={zipcode}
            onKeyDown={handleKeyDown}
            onChange={(evt) => {
              const onlyNums = evt.target.value.replace(/[^0-9]/g, "");
              setZipcode(onlyNums);
            }}
            disabled={loadingUtility}
          />
          {loadingUtility && (
            <Spinner animation="border" variant="secondary" size="sm" />
          )}
        </InputZipcode>
        <Button
          tabIndex={0}
          onClick={() => handleSearch()}
          style={{
            backgroundColor: "#253F66",
            padding: "10px 24px",
            color: "white",
            height: "48px",
            fontSize: "18px",
            width: isMobile ? "100%" : "",
          }}
        >
          {t("seePlans")}
        </Button>
      </div>
    </div>
  );
};
