import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    EnrollmentStepWrapper,
    EnrollmentStepFooter,
    EnrollmentFormRow,
    EnrollmentFormCol,
} from '../styles/Enrollment.styled'
import { Dropdown, Form, Modal, Spinner } from 'react-bootstrap'
import { Button } from '../styles/Button.styled'
import { RadioBox } from './RadioBox'
import { FormError } from './FormError'
import { fetchServiceSwitchOptions, saveEnrollment } from '../../api/enrollment'
import moment, { Moment } from 'moment'
import Datetime from 'react-datetime'
import { EnrollmentContext } from '../../enrollment'
import { ValidateZipcode } from '../../validate_zipcode'
import { statesList } from '../../states-list'
import NumberFormat from 'react-number-format'
import { fetchUtility, getAgentScript, saveCookieData } from '../../api/api'
import { useLocation } from 'react-router'
import qs from 'qs'
import InputMask from 'react-input-mask';
import 'moment/locale/es'
import jsonToArrConverter from '../../utils/jsonToArrConverter'
import isAdmin from '../../utils/isAdmin'
import getCookieData from '../../utils/getCookieData'

const nonTxStates = statesList.filter(val => val.abbr !== 'TX');

interface Props {
    show: boolean;
    handleContinue: Function;
    handleOpenPlanModal: Function;
    isShowPlanModal: boolean;
    handleSwitchToTx: Function;
    utilityList: any;
    planIsLoaded: boolean;
    handleConfirmUsn: Function;
    handleOpenUtilityModal: Function;
    setScriptData: any;
    redirectionForDukeCampaign: boolean;
    setDukeRdrZipCode: any;
}

interface ServiceTypeOption {
    label: string
    value: string
    desc: string
}

interface StatesOption {
    name: string
    abbr: string
}

const DUKE_ZIPCODE = [
    43230, 43560, 43613, 44022, 44026, 44118, 44484, 44646, 44705, 45001, 45002, 45005, 
    45011, 45012, 45013, 45014, 45015, 45030, 45033, 45034, 45036, 45039, 45040, 45041, 
    45042, 45044, 45050, 45052, 45054, 45056, 45061, 45062, 45065, 45066, 45067, 45068, 
    45069, 45101, 45102, 45103, 45105, 45106, 45107, 45111, 45121, 45122, 45130, 45140, 
    45144, 45147, 45148, 45150, 45152, 45154, 45157, 45162, 45167, 45168, 45174, 45176, 
    45201, 45202, 45203, 45204, 45205, 45206, 45207, 45208, 45209, 45211, 45212, 45213, 
    45214, 45215, 45216, 45217, 45218, 45219, 45220, 45221, 45222, 45223, 45224, 45225, 
    45226, 45227, 45228, 45229, 45230, 45231, 45232, 45233, 45234, 45235, 45236, 45237, 
    45238, 45239, 45240, 45241, 45242, 45243, 45244, 45245, 45246, 45247, 45248, 45249, 
    45250, 45251, 45252, 45255, 45277, 45321, 45327, 45342, 45414, 45431, 45693
];

export const EnrollmentStepServiceUsn: React.FC<Props> = ({
    show,
    handleContinue,
    utilityList,
    handleOpenPlanModal,
    isShowPlanModal,
    handleSwitchToTx,
    planIsLoaded,
    handleConfirmUsn,
    handleOpenUtilityModal,
    setScriptData,
    redirectionForDukeCampaign,
    setDukeRdrZipCode
}) => {
    const {
        handleSetErrorModalContent, usnZipcode, changeZipcode,
        changeServiceAddress, changeUtilityList, selectedPlans, enrollmentTokens
    } = useContext<any>(EnrollmentContext);

    const { t, i18n } = useTranslation('enrollment');
    const location = useLocation()
    const { promocode } = qs.parse(
        location.search, {
        ignoreQueryPrefix: true,
    })
    const [serviceTypeOptions, setServiceTypeOptions] = useState<any>([]);
    const [serviceType, setServiceType] = useState<ServiceTypeOption | undefined>(undefined);
    const [startDate, setStartDate] = useState<undefined | Moment>(undefined);
    const [loading, setLoading] = useState(false);
    const [errorFields, setErrorFields] = useState<any>({});
    const [address, setAddress] = useState('');
    const [unitNo, setUnitNo] = useState('');
    const [dukeZipCode, setDukeZipCode] = useState('');
    const [countyList, setCountyList] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState<any>(undefined);
    const [city, setCity] = useState('');
    const [selectedState, setSelectedState] = useState<StatesOption | undefined>(undefined);
    const [sampleBill, setSampleBill] = useState('');
    const [isAutopopulateState, setIsAutoPopulateState] = useState(false);
    const [loadingUtility, setLoadingUtility] = useState(false);
    const [utilityDetail, setUtilityDetail] = useState<any>([]);
    const [accountInfos, setAccountInfos] = useState<any>({});

    const holidays = { end_date: '', blackout_dates: [] }; // unnecessary in USN flow

    const getScriptForAgent = async () => {
        try {
            const res = await getAgentScript({
                script_id: selectedState?.abbr == "OH" ? "1" : "8",
                language: i18n.language === 'es' ? 'spanish' : 'english',
                state: selectedState?.abbr == "OH" ? "OH" : "TX",
                enrollment_id: enrollmentTokens[0]?.enrollment_id,
                commodity: selectedState?.abbr == "OH" ? (((selectedPlans.length > 1) ? "gas" : selectedPlans[0]?.commodity_name) || "gas") : "electric",
                utility_code: selectedPlans[0]?.id_utility,
            })
            setScriptData({
                //@ts-ignore
                script: res.length > 0 ? res[0]?.script_content : "",
                scriptHead: res.length > 0 ? res[0]?.screen_name : "",
                dynamicData: {}
            })
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (show) {
            window.scrollTo(0, 0);
            (async () => {
                let _isAdmin = await isAdmin();
                if (_isAdmin) {
                    getScriptForAgent();
                }
            })();
        }
    }, [show, selectedPlans.length, selectedState?.abbr])


    useEffect(() => {
        if ((!utilityList && !redirectionForDukeCampaign) || selectedPlans.length === 0) {
            setUtilityDetail([]);
        }
        else {

            if(redirectionForDukeCampaign){
                setUtilityDetail([
                    {
                        "commodity_name": "Gas",
                        "commodity_name_from": "plan",
                        "name": "Duke Energy Ohio (Gas)",
                        "logo": "14eb7a10-19e7-4e21-af3e-a2e38254d2e6.png",
                        "id_utility": 84,
                        "formal_name": "Duke Energy Ohio Gas",
                        "ldc_length": "22",
                        "ldc_mask": "############Z#########",
                        "utility_code": "DUKEOHG",
                        "ldc_only_numbers": false,
                        "wizard_sample_bill_label": "You can find the Account number on your existing bill.\nPlease be aware that entering the wrong utility account number will delay your enrollment. ",
                        "wizard_ldc_label": "Account Number",
                        "is_zone_required": false,
                        "pool_id": "",
                        "markets": {
                            "id_market": 32,
                            "name": "Ohio Market",
                            "active": true,
                            "state_code": "OH",
                            "state_name": "Ohio",
                            "handle_payments": false
                        },
                        "sample_bill": "8662cc1c-d6d2-445a-bb7d-fdace0b07937.jpg",
                        "friendly_name": "Duke Energy Ohio Gas",
                        "customer_name_key_required": false,
                        "customer_name_key_length": null,
                        "state": {
                            "state_id": "OH",
                            "state_name": "Ohio",
                            "city": "Columbus",
                            "county_names": [
                                "Franklin"
                            ]
                        }
                    }
                ])
                return;
            }

            let newUtilityDetail: any = [];

            const utilitiesInPlan = selectedPlans.reduce((arr: string[], item: any) => {
                return [...arr, item.utilityCode];
            }, [])

            Object.values(utilityList).forEach((utilities: any) => {
                utilities.forEach((item: any) => {
                    if (Object.values(utilitiesInPlan).includes(item.utility_code) || Object.values(utilitiesInPlan).includes(String(item.id_utility))) {
                        newUtilityDetail.push(item);
                    }
                })
            });
            setUtilityDetail(newUtilityDetail);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utilityList, selectedPlans]);

    useEffect(() => {
        if(redirectionForDukeCampaign) {
            getUsnServiceSwitchOptions('OH');
            setSelectedState({name: 'Ohio', abbr: 'OH'});
            //@ts-ignore
            return;
        }
        if (utilityDetail.length > 0) {
            const { state } = utilityDetail[0];

            if (state) {
                getUsnServiceSwitchOptions(state.state_id);

                const findState = nonTxStates.filter(val => val.abbr === state.state_id);

                if (findState[0]) {
                    setSelectedState(findState[0]);
                    setIsAutoPopulateState(true);
                };
                setCity(state.city);

                const { county_names } = state;
                if (county_names) {
                    setCountyList(county_names);
                    setSelectedCounty(county_names[0]);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [utilityDetail])

    function replaceEncodedSpaces(str:any) {
        if(str?.length>0){
            return str?.split('%20')?.join(' ');
        }
    }

    const getUsnServiceSwitchOptions = async (state: string) => {
        await fetchServiceSwitchOptions(state).then(res => {
            let options: any = [];
            if (res) {
                const { response } = res;            
                let normalizedResponse = [];
            
                // Check the structure of the response and normalize it
                if (Array.isArray(response)) {
                    normalizedResponse = response; // Handle the second type of response
                } else if (response.standardSwitch) {
                    normalizedResponse = response.standardSwitch; // Handle the first type of response
                }
            
                // Process the normalized response
                normalizedResponse.forEach((val: any) => {
                    options.push({
                        id: val.name,
                        label: val.name === 'standardSwitch' ? t('Standard Switch') : t('Let me choose the day'),
                        value: val.datepicker ? '2' : '-1',
                        desc: val.desc
                    });
                });
            
                setServiceTypeOptions(options);
            
                if (options.length === 1) {
                    setServiceType(options[0]);
                }
            }
            
        })
    }

    const validate = () => {
        let isValid = true
        let newErrorFields: any = {}
        if (!serviceType) {
            newErrorFields['serviceType'] = t('This field is required')
            isValid = false
        }

        if(redirectionForDukeCampaign){
            if(!DUKE_ZIPCODE.includes(Number(dukeZipCode))){
                newErrorFields['dukeZipCodeError'] = t('Enter valid zip code')
                isValid = false
            }
        }

        if (serviceType?.value !== '-1') {
            if (
                !startDate ||
                !(
                    moment(startDate, 'MM/DD/YYYY', true).isValid() ||
                    moment(startDate, 'M/DD/YYYY', true).isValid() ||
                    moment(startDate, 'MM/D/YYYY', true).isValid() ||
                    moment(startDate, 'M/D/YYYY', true).isValid()
                )
            ) {
                newErrorFields['startDate'] = t('Invalid Date')
                isValid = false
            }

            if (holidays && startDate) {
                if (startDate.isBefore(moment().format('YYYY-MM-DD')) || startDate.isAfter(moment(holidays.end_date, 'YYYY-MM-DD'))) {
                    newErrorFields['startDate'] = t('Invalid Date')
                    isValid = false
                }
                const blackoutDate = (jsonToArrConverter(holidays.blackout_dates))?.filter((val: string) => val.indexOf(moment(startDate).format('YYYY-MM-DD')) >= 0);
                if (blackoutDate.length > 0) {
                    newErrorFields['startDate'] = t('Invalid Date')
                    isValid = false
                }
            }
        }
        if (!address) {
            newErrorFields['service_address'] = t('This field is required')
            isValid = false
        }
        if (!city) {
            newErrorFields['service_city'] = t('This field is required')
            isValid = false
        }
        if (!selectedState) {
            newErrorFields['service_state'] = t('This field is required')
            isValid = false
        }

        utilityDetail.forEach((utility: any) => {
            const { commodity_name, ldc_length, wizard_ldc_label, customer_name_key_length } = utility;

            if (!accountInfos[`${commodity_name}_accountNumber`]) {
                newErrorFields[`${commodity_name}_accountNumber`] = t('This field is required')
                isValid = false
            }

            if (accountInfos[`${commodity_name}_accountNumber`] && accountInfos[`${commodity_name}_accountNumber`].length !== parseInt(ldc_length)) {
                newErrorFields[`${commodity_name}_accountNumber`] = `This is not a valid ${wizard_ldc_label}`
                isValid = false
            }
            if (accountInfos[`${commodity_name}_accountNumber`] && accountInfos[`${commodity_name}_accountNumber`].indexOf('_') >= 0) {
                newErrorFields[`${commodity_name}_accountNumber`] = 'This is not a valid customer name key'
                isValid = false
            }

            if (utility.customer_name_key_required) {
                if (!accountInfos[`${commodity_name}_customerNameKey`]) {
                    newErrorFields[`${commodity_name}_customerNameKey`] = t('This field is required')
                    isValid = false
                }
                if (accountInfos[`${commodity_name}_customerNameKey`] && accountInfos[`${commodity_name}_customerNameKey`].length !== parseInt(customer_name_key_length)) {
                    newErrorFields[`${commodity_name}_customerNameKey`] = 'This is not a valid customer name key'
                    isValid = false
                }
            }
        })

        setErrorFields(newErrorFields)
        return isValid
    }

    const handleSave = async () => {
        if (loading || !validate()) return;

        let serviceTypeVal = 'MoveIn',
            requestedDate = moment().add(1, 'days').format('MM/DD/YYYY')
        if (serviceType && parseInt(serviceType.value) > 1) {
            serviceTypeVal = 'Switching'
        }
        if (serviceType && parseInt(serviceType.value) < 3) {
            requestedDate = moment(startDate).format('MM/DD/YYYY')
        }
        if (serviceType && parseInt(serviceType.value) === -1) {
            serviceTypeVal = 'Switching'
            requestedDate = 'false'
        }

        let saveFields: any = {
            service_address: address,
            service_zipcode: redirectionForDukeCampaign ? dukeZipCode : usnZipcode,
            service_city: city,
            service_state: selectedState?.abbr,
            service_type: serviceTypeVal,
            requested_date: requestedDate,
            service_county: selectedCounty,
            service_address2: unitNo
        }

        setLoading(true);
        let promises: Promise<any>[] = [];
        selectedPlans.forEach((plan: any) => {
            const { product, commodity_name } = plan;
            saveFields['product'] = product;
            saveFields['ldc_account'] = accountInfos[`${commodity_name}_accountNumber`];
            if (accountInfos[`${commodity_name}_customerNameKey`]) {
                saveFields['customer_name_key'] = accountInfos[`${commodity_name}_customerNameKey`];
            }

            promises.push(saveData(saveFields));
        })

        Promise.all(promises).then((res: any) => {
            setLoading(false);

            if (res) {
                let saveResponse: any = [];

                res.forEach((val: any) => {
                    const { enrollment_id, sign, product } = val;
                    if (!enrollment_id) {
                        handleSetErrorModalContent({
                            title: t('Enrollment Id does not exist')
                        });
                        return false;
                    }
                    saveCookieData(enrollment_id,sign,getCookieData('cookiesAccepted')=="true"?"accepted":"rejected",getCookieData("cookiesAcceptedDate"))
                    saveResponse.push({
                        productId: product,
                        enrollment_id,
                        sign
                    });
                })
                if (saveResponse.length > 0) {
                    changeServiceAddress({
                        esiid: '',
                        street: address,
                        zipcode: redirectionForDukeCampaign ? dukeZipCode : usnZipcode,
                        city: city,
                        state: selectedState?.abbr,
                        county: selectedCounty,
                        unitNo: unitNo
                    });
                    handleContinue(saveResponse);
                }
            }
        }).catch(error => {
            setLoading(false);
            handleSetErrorModalContent({
                title: t(error)
            });
        })
    }

    const saveData = (requestData: any) => {
        const url = new URL(window.location.href);
	    const hearus = url.searchParams.get('hearus');
        return new Promise((resolve, rejects) => {
            saveEnrollment((enrollmentTokens[0]?.enrollment_id || null), {...requestData,hear_us:replaceEncodedSpaces(hearus)}, (enrollmentTokens[0]?.sign || null))
            .then(data => {
                    if (data && parseInt(data.status) === 1 && data.response) {
                        resolve(data.response);
                    }
                    else {
                        rejects(data.message);
                    }
                })
                .catch(error => rejects(error))
        })
    }

    const searchUsnUtility = (zipcode: string) => {
        if (loadingUtility) return;
        changeZipcode('');
        setLoadingUtility(true);
        fetchUtility(zipcode, promocode).then(res => {
            setLoadingUtility(false);
            if (res && res.status === 1) {
                const { utility, state } = res.response;
                if (state) {
                    const stateId = state.state_id;
                    getAgentScript();
                    const isTx = stateId && stateId.toLowerCase().indexOf('tx') >= 0;
                    if (isTx) {
                        console.log('Texas zipcode detected!');
                        // window.location.href = `https://rates.cleanskyenergy.com:8443/rates/index?customerTypeId=7&zipcode=${zipcode}`
                        // return;
                        // !!! Temporary redirect Texas flow to CSE legacy site !!!
                        changeUtilityList(undefined);
                        handleSwitchToTx({
                            zipcode: zipcode,
                            utility: utility
                        });
                    }
                    else {
                        handleConfirmUsn(true);
                    }

                    const [...utilityWithState] = utility;
                    utilityWithState.forEach((item: any) => {
                        if (!item.state) item.state = state;
                        if (isTx) item.commodity_name = "Electric";
                    })
                    changeUtilityList(utilityWithState);
                    changeZipcode(zipcode);
                }
            }
            else {
                handleSetErrorModalContent({
                    title: t("We're sorry, CleanSky Energy currently does not service this area."),
                    desc: ' '
                });
                changeUtilityList(undefined);
            }
        })
    }

    const showInput = () => {
        return (
            <>
                {/* <h6 className="mt-2 mb-2">{t('Zip Code')} *</h6>
                <ValidateZipcode
                    handleSearchUtility={(zipcode: string) => searchUsnUtility(zipcode)}
                    loadingUtility={loadingUtility}
                    defaultZipcode={usnZipcode}
                /> */}
                {
                    (planIsLoaded && selectedPlans.length === 0 && (usnZipcode || redirectionForDukeCampaign) && !isShowPlanModal) &&
                    <div className="mt-3">
                        <Button sm secondary onClick={() => handleOpenUtilityModal()}>Choose Plan</Button>
                    </div>
                }
            </>
        )
    }

    const handleChangeSpecialInput = (key: string, value: string) => {
        let newAccountInfos = Object.assign({}, accountInfos);
        newAccountInfos[key] = value;
        setAccountInfos(newAccountInfos);
    }

    const renderSpecialInput = () => {
        if (utilityDetail.length === 0) return null;

        return utilityDetail.map((item: any, idx: number) => {
            const {
                commodity_name, wizard_ldc_label, wizard_sample_bill_label, sample_bill, ldc_mask,
                ldc_only_numbers, ldc_length, customer_name_key_required, customer_name_key_length, name
            } = item;

            const mask = (name === "WMECO") ? ldc_mask : ((ldc_only_numbers) ?
                new Array(parseInt(ldc_length) + 1).join('#') :
                ldc_mask ? ldc_mask.replace(/#/g, '*') : new Array(parseInt(ldc_length) + 1).join('*'));

            return (
                <React.Fragment key={idx}>
                    <EnrollmentFormCol>
                        <Form.Group className={errorFields[`${commodity_name}_accountNumber`] ? 'error' : ''}>
                            <Form.Label>{t(`${commodity_name} ${wizard_ldc_label}`)} *</Form.Label>
                            <p style={{ fontSize: 13, marginTop: -8 }}>
                                {wizard_sample_bill_label}
                                {
                                    sample_bill &&
                                    <>
                                        <br />
                                        <a className="d-inline-block mt-2" style={{ textDecoration: 'underline' }} onClick={() => setSampleBill(sample_bill)}>{t('View Sample')}</a>
                                    </>
                                }
                            </p>
                            {
                                ldc_only_numbers ?
                                    <NumberFormat
                                        mask="_"
                                        format={mask}
                                        name="account_number"
                                        value={accountInfos[`${commodity_name}_accountNumber`] || ''}
                                        onChange={(evt: any) => handleChangeSpecialInput(`${commodity_name}_accountNumber`, evt.target.value)}
                                        className="form-control"
                                        disabled={loading}
                                    /> :
                                    <InputMask
                                        mask={mask}
                                        type="text"
                                        name="account_number"
                                        maskChar="_"
                                        className="form-control"
                                        // placeholder=""
                                        // maxLength={ldc_length}
                                        disabled={loading}
                                        onChange={(evt: any) => handleChangeSpecialInput(`${commodity_name}_accountNumber`, evt.target.value)}
                                        value={accountInfos[`${commodity_name}_accountNumber`] || ''}
                                        style={{ letterSpacing: '1px' }}
                                    />
                                // <Form.Control
                                //     type="text"
                                //     name="account_number"
                                //     placeholder=""
                                //     maxLength={ldc_length}
                                //     value={accountInfos[`${commodity_name}_accountNumber`] || ''}
                                //     onChange={evt => handleChangeSpecialInput(`${commodity_name}_accountNumber`, evt.target.value)}
                                //     disabled={loading}
                                // />
                            }
                        </Form.Group>
                        <FormError message={errorFields[`${commodity_name}_accountNumber`]} />
                    </EnrollmentFormCol>
                    {
                        customer_name_key_required &&
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['customer_name_key'] ? 'error' : ''}>
                                <Form.Label>{`${commodity_name} Customer Name Key`} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="customer_name_key"
                                    placeholder=""
                                    maxLength={customer_name_key_length}
                                    value={accountInfos[`${commodity_name}_customerNameKey`] || ''}
                                    onChange={evt => handleChangeSpecialInput(`${commodity_name}_customerNameKey`, evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields[`${commodity_name}_customerNameKey`]} />
                        </EnrollmentFormCol>
                    }
                </React.Fragment>
            )
        })
    }

    if (!show) return null
    return (
        <EnrollmentStepWrapper>
            {showInput()}
            {
                (usnZipcode && utilityDetail.length > 0 && selectedPlans) ?
                <div className="mt-5">
                    <h6 style={{ marginBottom: -20 }}>{t('Service Address')}</h6>
                    <EnrollmentFormRow>
                        <EnrollmentFormCol full>
                            <Form.Group className={errorFields['service_address'] ? 'error' : ''}>
                                <Form.Label>{t('Address')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_address"
                                    placeholder=""
                                    maxLength={200}
                                    value={address}
                                    onChange={evt => setAddress(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_address']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_unit'] ? 'error' : ''}>
                                <Form.Label>{t('Unit/Suite/Apt Number')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_unit"
                                    placeholder=""
                                    maxLength={50}
                                    value={unitNo}
                                    onChange={evt => setUnitNo(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_unit']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_city'] ? 'error' : ''}>
                                <Form.Label>{t('City')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_city"
                                    placeholder=""
                                    maxLength={50}
                                    value={city}
                                    onChange={evt => setCity(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_city']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_county'] ? 'error' : ''}>
                                <Form.Label>{t('County')} *</Form.Label>
                                <Dropdown className="plans-filter-dd">
                                    <Dropdown.Toggle
                                        variant="outline-secondary"
                                    >
                                        {selectedCounty ? selectedCounty : 'County...'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            countyList &&
                                            countyList.map((val, idx) => (
                                                <Dropdown.Item
                                                    eventKey={val}
                                                    key={idx}
                                                    onClick={() => setSelectedCounty(val)}
                                                    active={selectedCounty === val}>
                                                    {val}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                            <FormError message={errorFields['service_city']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_state'] ? 'error' : ''}>
                                <Form.Label>{t('State')} *</Form.Label>
                                <Dropdown className="plans-filter-dd">
                                    <Dropdown.Toggle
                                        disabled={isAutopopulateState}
                                        variant="outline-secondary"
                                    >
                                        {selectedState ? selectedState.name : 'State...'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            nonTxStates.map((val, idx) => (
                                                <Dropdown.Item
                                                    eventKey={val.abbr}
                                                    key={idx}
                                                    onClick={() => setSelectedState(val)}
                                                    active={selectedState?.abbr === val.abbr}>
                                                    {val.name}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                            <FormError message={errorFields['service_state']} />
                        </EnrollmentFormCol>
                        {renderSpecialInput()}
                    </EnrollmentFormRow>
                </div>:
                (redirectionForDukeCampaign && <div className="mt-5">
                    <h6 style={{ marginBottom: -20 }}>{t('Service Address')}</h6>
                    <EnrollmentFormRow>
                        <EnrollmentFormCol full>
                            <Form.Group className={errorFields['service_address'] ? 'error' : ''}>
                                <Form.Label>{t('Address')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_address"
                                    placeholder=""
                                    maxLength={200}
                                    value={address}
                                    onChange={evt => setAddress(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_address']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_unit'] ? 'error' : ''}>
                                <Form.Label>{t('Unit/Suite/Apt Number')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_unit"
                                    placeholder=""
                                    maxLength={50}
                                    value={unitNo}
                                    onChange={evt => setUnitNo(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_unit']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_city'] ? 'error' : ''}>
                                <Form.Label>{t('City')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_city"
                                    placeholder=""
                                    maxLength={50}
                                    value={city}
                                    onChange={evt => setCity(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_city']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_county'] ? 'error' : ''}>
                                <Form.Label>{t('County')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="service_county"
                                    placeholder=""
                                    maxLength={50}
                                    value={selectedCounty}
                                    onChange={evt => setSelectedCounty(evt.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['service_city']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_state'] ? 'error' : ''}>
                                <Form.Label>{t('State')} *</Form.Label>
                                <Dropdown className="plans-filter-dd">
                                    <Dropdown.Toggle
                                        disabled={redirectionForDukeCampaign ? true : isAutopopulateState}
                                        variant="outline-secondary"
                                    >
                                        {selectedState ? selectedState.name : 'State...'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            nonTxStates.map((val, idx) => (
                                                <Dropdown.Item
                                                    eventKey={val.abbr}
                                                    key={idx}
                                                    onClick={() => setSelectedState(val)}
                                                    active={selectedState?.abbr === val.abbr}>
                                                    {val.name}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                            <FormError message={errorFields['service_state']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                            <Form.Group className={errorFields['service_unit'] ? 'error' : ''}>
                                <Form.Label>{t('Zip code*')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="zipcode"
                                    placeholder=""
                                    maxLength={5}
                                    value={dukeZipCode}
                                    onChange={evt => {
                                        setDukeZipCode(evt.target.value)
                                        if(evt.target.value.length === 5){
                                            setDukeRdrZipCode(evt.target.value)
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <FormError message={errorFields['dukeZipCodeError']} />
                        </EnrollmentFormCol>
                        <EnrollmentFormCol>
                        </EnrollmentFormCol>
                        {renderSpecialInput()}
                    </EnrollmentFormRow>
                </div>)
            }
            {
                ((usnZipcode || redirectionForDukeCampaign) && utilityDetail.length > 0 && selectedPlans) &&
                <>
                    <EnrollmentFormRow>
                        <EnrollmentFormCol full>
                            <Form.Group className={errorFields['serviceType'] ? 'error' : ''}>
                                <RadioBox
                                    title={`${t('Why do you need this service?')} *`}
                                    options={serviceTypeOptions}
                                    select={serviceType}
                                    handleSelect={(val: any) => {
                                        setServiceType(val)
                                    }}
                                />
                                <FormError message={errorFields['serviceType']} />
                            </Form.Group>
                        </EnrollmentFormCol>
                    </EnrollmentFormRow>
                    {
                        (serviceType && serviceType.value !== '3' && serviceType.value !== '-1') &&
                        <>
                            <EnrollmentFormRow>
                                <EnrollmentFormCol>
                                    <Form.Group className={errorFields['startDate'] ? 'error' : ''}>
                                        <Form.Label>{t('Select Date')} *</Form.Label>
                                        <Datetime
                                            locale={i18n.language === 'es' ? 'es' : 'en'}
                                            value={startDate}
                                            onChange={(date: any) => setStartDate(date)}
                                            dateFormat="MM/DD/YYYY"
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            inputProps={{
                                                placeholder: t('MM/DD/YYYY'),
                                                id: 'select-start-date',
                                                readOnly: true
                                            }}
                                            isValidDate={(currentDate) => {
                                                if (holidays) {
                                                    if (currentDate.isBefore(moment().format('YYYY-MM-DD')) || currentDate.isAfter(moment(holidays.end_date, 'YYYY-MM-DD'))) return false;
                                                    const blackoutDate = (jsonToArrConverter(holidays.blackout_dates))?.filter((val: string) => val.indexOf(moment(currentDate).format('YYYY-MM-DD')) >= 0);
                                                    if (blackoutDate.length > 0) return false;
                                                    return true;
                                                }
                                                else if (currentDate.isBefore(moment().format('YYYY-MM-DD'))) {
                                                    return false;
                                                }
                                                return true;
                                            }}
                                        />
                                    </Form.Group>
                                    <FormError message={errorFields['startDate']} />
                                </EnrollmentFormCol>
                            </EnrollmentFormRow>
                            <p style={{ marginTop: -20, fontSize: 14, color: '#666' }}>
                                {t('It may take 24 - 48 hours from your service request date for services to be connected.')}
                            </p>
                            <p style={{ fontSize: 14, color: '#666' }}>
                                {t('Please note that CleanSky Energy will submit your enrollment request for the date that you select, but actual connectivity of service is determined by your local utility provider. Service connectivity may not be available on weekends or holidays in some areas.')}
                            </p>
                        </>
                    }
                    <EnrollmentStepFooter>
                        <div />
                        <Button type="button" disabled={loading} onClick={() => handleSave()} data-test-id="cta">
                            <span>{t('Continue')}</span>
                            {loading && (
                                <Spinner animation="border" variant="light" size="sm" className="ms-2" />
                            )}
                        </Button>
                    </EnrollmentStepFooter>
                </>
            }
            <Modal size="lg" centered animation={false} show={!!sampleBill} onHide={() => setSampleBill('')}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img src={`https://rates.cleanskyenergy.com:8443/Company/Images/Utilities/${sampleBill}`} width="100%" alt="" />
                </Modal.Body>
            </Modal>
        </EnrollmentStepWrapper>
    )
}
