import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Button } from './styles/Button.styled';
import { getConfigById } from '../api/api';

import isAdmin from '../utils/isAdmin';

const CookiePopup: React.FC<{ setCookiesAccepted: Function }> = ({ setCookiesAccepted }) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      const cookieStatus = getCookie('cookiesAccepted'); // stored as a string
      const planPageDecision = url.searchParams.get('cookiesAccepted');
      const isCookieEnabledResponse = await getConfigById("COOKIE_MANAGEMENT")
      const isCookieEnabledOnPortal = isCookieEnabledResponse?.config_details?.ConfigData === "1"
      const admin = await isAdmin();
      if (cookieStatus === "false") {
        handleReject()
      }
      else if (planPageDecision === "true" || admin || cookieStatus === "true") {
        handleAccept()
      }
      else if (isCookieEnabledOnPortal) { // if the cookie management is enabled on the portal
        setShowPopup(true)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAccept = () => {
    setCookie("cookiesAccepted", "true", 365);
    setCookie("cookiesAcceptedDate", moment().format('YYYY-MM-DD'), 365);
    setShowPopup(false);
    setCookiesAccepted(true)
    document.body.style.overflow = 'auto';
  };

  const handleReject = () => {
    setCookie("cookiesAccepted", "false", 30); // 30 days in case cookie is rejected, we will show the popup again after 30 days
    setCookie("cookiesAcceptedDate", moment().format('YYYY-MM-DD'), 30);
    setShowPopup(false);
    setCookiesAccepted(false)
    document.body.style.overflow = 'auto';
  };

  if (!showPopup) {
    return null;
  }

  return (
    <>
      <div className="cookie-overlay"></div>
      <div className="cookie-popup">
        <div style={{ fontSize: "16px", fontWeight: 600 }}>How we use cookies</div>
        <p style={{ margin: "10px 0px 24px 0px", fontSize: "16px", color: "#2d3748" }}>We use cookies to give you the best possible experience with CleanSkyEnergy.com. Some area essential for this site to function, others help us understand how you use the site, so we can improve it. We may also use cookies for targeting purposes. Click “Accept all cookies” to proceed as specified, or click “Reject all cookies”.</p>
        <div className="cookie-buttons">
          <Button style={{ padding: "8px 16px", borderRadius: "6px" }} onClick={handleAccept}>ACCEPT ALL COOKIES</Button>
          <Button onClick={handleReject} style={{ marginLeft: '10px', padding: "8px 16px", background: "#eff7fa", color: "#253f66", border: "1px solid #253f66", borderRadius: "6px" }}>REJECT ALL COOKIES</Button>
        </div>
      </div>
      <style>{`
        .cookie-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
          z-index: 999; /* Ensure it's on top of other content */
        }

        .cookie-popup {
          position: fixed;
          bottom: 36px;
          left: 5%;
          width: 90%;
          background-color: #eff7fa;
          padding: 20px 28px;
          border-radius: 8px;
          box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
          z-index: 1000; /* Ensure it's on top of the overlay */
        }

        .cookie-buttons {
          display: flex;
          justify-content: flex-start;
        }
      `}</style>

    </>
  );
};

export default CookiePopup;

function getCookie(cookieName: string) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

function setCookie(cookieName: string, cookieValue: string, expirationDays: number) {
  const date = new Date();
  date.setTime(date.getTime() + (Number(expirationDays) * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}
