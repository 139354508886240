import { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { EnrollmentContext } from "../../../enrollment";
import { dealStages, iCheckConfig } from "../../../config";
import { Button } from "../../styles/Button.styled";
import { CheckboxDesc, EnrollmentFormCol, EnrollmentResultContent, PlanSummaryRow } from "../../styles/Enrollment.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { FormLoading } from "../../form-loading";
import { getEnrollmentRecord, saveEnrollmentPaymentInfo } from "../../../api/enrollment";
import { signEncoder } from "../../../utils/signEncoder";
import moment from 'moment';
import { fetchIsWhitelisted, paidCustomer, uiLogging, updateDealHubspot, updateStatus } from "../../../api/api";
import hubspotApiCalls from "../../../utils/hubspotApiCalls";
import ShareAsale from "../../../utils/ShareAsale";
import { shareAsaleNew } from "../../../utils/shareAsaleNew";
import selectedPlanMagicLink from "../../../utils/selectedPlanMagicLink";
import urlHasParam from "../../../utils/urlHasParam";
import { useNavigate } from "react-router-dom";
import { CheckboxItem, CheckboxItem2, CheckboxItemNew } from "../../checkbox-item";

export const PendingDepositTx = ({ additional, name, enrollmentTokens, selectedPlans, showFinalPage, depositConfig, split,autopayRequired }: {
    additional: any;
    name: string;
    enrollmentTokens: any;
    selectedPlans: any;
    showFinalPage?: any;
    depositConfig: any;
    split: string;
    autopayRequired:boolean;
}) => {
    const { t, i18n } = useTranslation('enrollment')
    const [ paymentMethod, setPaymentMethod ] = useState('1');
    const [ showSuccessfulPage, setShowSuccessfulPage ] = useState(showFinalPage ? showFinalPage : false);
    const [ agentHoldPayment, setAgentHoldPayment ] = useState(false);
    const [ paymentResponse, setPaymentResponse ] = useState();
    const [ saving, setSaving ] = useState(false);
    const [ iframeData, setIframeData ] = useState({
        ldc: "",
        email: ""
    });
    const [ showDepositWaiverPopup, setShowDepositWaiverPopup ] = useState(false);
    const { handleSetErrorModalContent } = useContext<any>(EnrollmentContext);
    const [ newNameData, setNewNameData ] = useState({
        firstName: "",
        lastName: ""
    })
    const [ selectedPlansML, setSelectedPlansML ] = useState<any>()
    const navigate = useNavigate();
    const depositAmount = split == "false" ? (additional && additional[0] && additional[0].value ? additional[0].value : 0) :depositConfig?.deposit_suggested_amount;
    const url = new URL(window.location.href);
    const idName = url.searchParams.get('name');
    const agentParam = url.searchParams.get('isAgent');
    const [onlyDeposit,setOnlyDeposit] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('message', handleMessage);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('message', handleMessage);
            }
        }
    }, [])

    function removeParamsFromUrl(url: string) {
        const urlObj = new URL(url);
        const paramsToRemove = ["name", "status", "isTx"];
    
        for (const paramName of paramsToRemove) {
            urlObj.searchParams.delete(paramName);
        }
    
        return urlObj.toString();
    }

    useEffect(()=>{
        (async()=>{
            const urlSearchParams = new URLSearchParams(window.location.search)
            const is_magic_link = urlSearchParams.has("sign")
            
            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const signParam = url.searchParams.get('sign');

            let res = await getEnrollmentRecord(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam)
            let customerData = res?.response;
            setNewNameData({
                firstName: customerData?.first_name,
                lastName: customerData?.last_name
            })
            setIframeData({ldc: customerData?.ldc_account, email: customerData?.email})

            if(idParam){
                let dataFetched = await selectedPlanMagicLink(idParam, signParam)
                setSelectedPlansML(dataFetched)
            }

            if(!showFinalPage){
                let _resp = await fetchIsWhitelisted(enrollmentTokens?.enrollment_id || idParam)
                if(is_magic_link && _resp?.whitelist_flag == "1"){
                    await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "fraud_whitelisted_deposit_visited", removeParamsFromUrl(window.location.href), "Deposit Payment Screen")
                    await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `Hubspot API call - Fraud whitelisted deposit visited` )
                }else{
                    await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "deposit_visited", `${window.location.href}${urlHasParam(window.location.href)?"&":"?"}id=${enrollmentTokens?.enrollment_id}&sign=${enrollmentTokens?.sign}`, "Deposit Payment Screen")
                    await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `Hubspot API call - Deposit visited`)
                }
                //UPDATE DEAL STAGE HERE
                updateDealHubspot(enrollmentTokens?.enrollment_id || idParam, dealStages.ABANDON_DEPOSIT)
            }
        })()
    },[enrollmentTokens])

    const loopRetrieveStatus = async () => {
        const fetchData = async () => {
        try {
            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const signParam = url.searchParams.get('sign');
            let res = await getEnrollmentRecord(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam);
            console.log('Data in res ==>', res.response.status);
            if (res.response.status === "40.E04") {
                setAgentHoldPayment(false);
                setShowSuccessfulPage(true);
                return true; 
            } else {
                return false; 
            }
        } catch (error) {
            return false; 
        }
        };
    
        const continueLoop = async () => {
        let shouldContinue = await fetchData();
        if (!shouldContinue) {
            const intervalId = setInterval(async () => {
                shouldContinue = await fetchData();
                if(shouldContinue) {
                    clearInterval(intervalId);
                }
            }, 5000);
        }
        };
    
        await continueLoop();
    };
       
    const saveRecords: any = async (enrollmentId: string, jsonData: string, sign: string, confirmationNumber: string, paidAmount: string, attempt = 1, maxAttempts = 3) => {
        const url1 = new URL(window.location.href);
        const setup_autopay = url1.searchParams.get('setup_autopay');
        const res = await saveEnrollmentPaymentInfo(enrollmentId, jsonData, sign, confirmationNumber, paidAmount, (autopayRequired ? true : (setup_autopay?setup_autopay:false)));
            if (res && parseInt(res.status) === 1) {
            await uiLogging(enrollmentId, sign, `Transaction details successfully entered on our database`)
            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search);
            params.delete('setup_autopay');
            window.history.replaceState({}, '', `${url.pathname}?${params}`);
            //status Enrollment-Submitted Deposit-Paid
            if(agentParam !== "true"){
                // await updateStatus(enrollmentId, sign, "40.E04", "Deposit-Paid", null)
            }else{
                // await updateStatus(enrollmentId, sign, null, "Deposit-Paid", null)
            }
            setSaving(false);
            return res;
        }
        else if (res && parseInt(res.status) === 2) {
            await uiLogging(enrollmentId, sign, `Transaction details entry FAILED on our database - status 2 from response`)
            //status Deposit Deposit-Failed
            // await updateStatus(enrollmentId, sign, "20.E01", "Deposit-Failed", null)
            handleSetErrorModalContent({
                title: t('Apologize text payment neter')
            });
            setSaving(false);
            return res;
        }
        else if (attempt < maxAttempts) {
            await uiLogging(enrollmentId, sign, `Transaction details entry on our database - Attempt ${attempt}`)
            setSaving(false);
            return saveRecords(enrollmentId, jsonData, sign, confirmationNumber, paidAmount, attempt + 1, maxAttempts);
        }
        else {
            await uiLogging(enrollmentId, sign, `Transaction details entry FAILED on our database - Network Error`)
            //status Deposit Deposit-Failed
            await updateStatus(enrollmentId, sign, "20.E01", "Deposit-Failed", null)
            handleSetErrorModalContent({
                title: t('Apologize text payment invsg')
            });
            setSaving(false);
            throw new Error((res && res.message) ? t(res.message) : t('Network Error'));
        }
    }

    function updateQueryParam(key:any, value:any) {
        let url = new URL(window.location.href);

        url.searchParams.set(key, value);

        window.history.pushState({}, '', url);
    }

    const handleMessage = async(e: any) => {
        e.preventDefault();
        const jsonData = e.data;
        const url = new URL(window.location.href);
        const idParam = url.searchParams.get('id');
        const signParam = url.searchParams.get('sign');
        jsonData?.custId && await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `Payment log from ICG - ${JSON.stringify(jsonData)}`)
        if (typeof jsonData !== 'undefined') {
            if (typeof jsonData === 'object' && jsonData.constructor.name === 'Object') {
                const { source } = jsonData;
                if (typeof source !== 'undefined' && source === 'iCheck') {
                    if (typeof jsonData.error !== 'undefined' && jsonData.error !== null) {
                        // toast.error(jsonData.error);
                        const url = new URL(window.location.href);
                        const idParam = url.searchParams.get('id');
                        const signParam = url.searchParams.get('sign');
                        await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `ICG response has error, modal shown to user for ${jsonData?.error}`)
                        handleSetErrorModalContent({
                            title: t(jsonData.error)
                        });
                    }
                    else {
                        setPaymentResponse(jsonData);
                        setSaving(true);
                        const { transactionResponse } = jsonData;
                        const confirmationNumber = transactionResponse.split('|')[7];
                        if (transactionResponse.indexOf('APPROVED') >= 0) {
                            
                            const url = new URL(window.location.href);
                            const idParam = url.searchParams.get('id');
                            const signParam = url.searchParams.get('sign');
                            
                            await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `credit card transaction APPROVED from ICG, starting process to enter data in our database`)
                            saveRecords(
                                enrollmentTokens?.enrollment_id || idParam,
                                JSON.stringify(jsonData),
                                idParam ? signParam : enrollmentTokens?.sign,
                                confirmationNumber,
                                depositAmount
                            ).then(async () => {
                                // console.log(res)
                                setSaving(false);
                                const urlSearchParams = new URLSearchParams(window.location.search)
                                const is_magic_link = urlSearchParams.has("sign")
                                let _resp = await fetchIsWhitelisted(enrollmentTokens?.enrollment_id || idParam)
                                if(is_magic_link && _resp?.whitelist_flag == "1"){
                                    await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "fraud_whitelisted_deposit_complete", removeParamsFromUrl(window.location.href), "Thank you Page")
                                    await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "Hubspot API call - fraud whitelisted deposit complete")
                                }else{
                                    await hubspotApiCalls(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "deposit_complete", "", "Thank you Page")
                                    await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, "Hubspot API call - deposit complete")
                                }
                                //UPDATE DEAL STAGE HERE
                                updateDealHubspot(enrollmentTokens?.enrollment_id || idParam, dealStages.COMPLETE_DEPOSIT)
                                if(agentParam == "true"){
                                    setAgentHoldPayment(true)
                                    await loopRetrieveStatus()
                                }else{
                                    setShowSuccessfulPage(true);
                                }
                                setShowSuccessfulPage(true);
                                if(idParam){
                                    // await paidCustomer(idParam, "deposit")
                                    await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `user status changed from FRAUD WARNING`)
                                }
                            })
                        }
                        else {
                            const url = new URL(window.location.href);
                            const idParam = url.searchParams.get('id');
                            const signParam = url.searchParams.get('sign');
                            await uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || signParam, `Error in processing credit card, modal shown to user for ${transactionResponse?.split('|')[0]}`)
                            setSaving(false);
                            handleSetErrorModalContent({
                                title: t(transactionResponse.split('|')[0])
                            });
                        }
                    }
                }
            }
        }
    }

    const getAdditionalContent = () => {
        const {
            ccUrl, baUrl, ccSpUrl, baSpUrl,
            appId, appSecret, styles
        } = iCheckConfig;
        const url = paymentMethod === '1' ?
                    (i18n.language === 'es' ? ccSpUrl : ccUrl) :
                    (i18n.language === 'es' ? baSpUrl : baUrl);

        const firstName = newNameData.firstName; 
        const lastName = newNameData.lastName;

        const currentUrl = new URL(window.location.href);
        const idParam = currentUrl.searchParams.get('id');

        return (
            <div style={{ marginTop: 12 }}>
                <div className="mb-3" style={{display:'flex', justifyContent:'center', alignItems:'center', gap: '20px'}}>
                    <p style={{ fontSize: 18, marginBottom: 0 }}><b>{t('Deposit Amount')}: <span style={{ fontSize: 20 }}>${depositAmount}</span></b></p>
                    <a href="javascript:;" style={{color:'#26B7EA',fontSize:17}} onClick={() => setShowDepositWaiverPopup(true)}>{t('Need a deposit alternative?')}</a>
                </div>
                {/* <div style={{textAlign:'center',marginBottom:24}}>
                    <div className="d-flex justify-content-center">
                        <CheckboxItem label="Credit Card" value="1" checked={paymentMethod === '1'} isRadio disabled={false} handleClick={() => setPaymentMethod('1')} />
                        <CheckboxItem label="eCheck (ACH)" value="2" checked={paymentMethod === '2'} isRadio disabled={false} handleClick={() => setPaymentMethod('2')} />
                    </div>
                </div> */}
                {!autopayRequired &&   
                    <CheckboxItem2
                        label={t("Set up Autopay : Autopay set up saves you time and effort for your bills to paid on their due dates to help avoid late payments.")}
                        value={String(onlyDeposit)}
                        checked={onlyDeposit}
                        handleClick={(e:any) => {
                            setOnlyDeposit(!onlyDeposit)
                            updateQueryParam('setup_autopay', !onlyDeposit);
                        }}
                        onlyread={false}
                        disabled={false}
                    />
                }
                <div className="d-flex justify-content-center">
                    <div className="position-relative">
                        <iframe
                            id="iFrameICG"
                            name="iFrameICG"
                            title="iFrameICG"
                            src={`${url}?appId=${appId}&appSecret=${appSecret}&custId=${`${encodeURIComponent(firstName)}${encodeURIComponent(lastName)}_${moment().format('YYYYMMDDHHmmss')}`}&amount=${depositAmount}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&email=${iframeData.email}&custom1=${iframeData.ldc}&custom2=${enrollmentTokens?.enrollment_id || idParam}&css=${styles}`}
                            frameBorder="0"
                            scrolling="no"
                            width="360"
                            height="560"
                        />
                    </div>
                </div>
            </div>
        )
    }

    if (agentHoldPayment) {
        return(
            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <div style={{display:"flex",justifyContent:"center", fontSize:"24px",width:"70%",textAlign:"center"}}>Thank you for submitting your Payment Information.</div>
                <br/>
                <div style={{display:"flex",justifyContent:"center", fontSize:"20px",width:"70%",textAlign:"center"}}>Please let your CleanSky Energy CARE agent know that you have submitted your payment information.</div>
                <br/>
                <div style={{display:"flex",justifyContent:"center", fontSize:"20px",fontWeight:"bold"}}>Do Not Close this Webpage</div>
                <br/>
                <div style={{display:"flex",justifyContent:"center", fontSize:"20px"}}>Please wait for instructions from your CARE agent</div>
            </div>
        )
    }
    else if (showSuccessfulPage) {
        const url = new URL(window.location.href);
        const idParam = url.searchParams.get('id');
        const idSign = url.searchParams.get('sign');
        uiLogging(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign, "Reached Thank you page")
        
        // shareAsaleNew(enrollmentTokens?.enrollment_id || idParam, enrollmentTokens?.sign || idSign)
        
        let allDocs: any = {};
        if(selectedPlans?.length){
            selectedPlans?.forEach((plan: any) => {
                const { commodity_name, docs } = plan;
                if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
                const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
                allDocs[commodity_name] = availableDocs;
            })
        }else if(selectedPlansML?.length){
            selectedPlansML?.forEach((plan: any) => {
                const { commodity_name, docs } = plan;
                if (!allDocs[commodity_name]) allDocs[commodity_name] = [];
                const availableDocs = docs.filter((val: any) => val.display_in_thank_you_page === true);
                allDocs[commodity_name] = availableDocs;
            })
        }

        navigate(`/depositsuccess${window.location.search}`, {state: {
            pagename: "DepositSuccess",
            selectedPlans: selectedPlans,
            selectedPlansML: selectedPlansML,
            enrollmentTokens: enrollmentTokens,
            depositConfig:depositConfig,
            split: split
        }})

        return (
            <div>
                <div className="text-center">
                    <h1 className="mb-3">{t('Thank you for choosing CleanSky Energy')}</h1>
                    <p>
                        {idName? 
                        <Trans i18nKey="enrollment:successfulPageDescDepositML1" components={{ 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                            </Trans>:
                        <Trans i18nKey="enrollment:successfulPageDesc" components={{7: <a href="mailto:verify@CleanSkyEnergy.com" />, 8: <a href="tel:(888) 733-5557" />, 9: <a href="mailto:customercare@cleanskyenergy.com" />}}>
                        </Trans>}
                    </p>
                    <p style={{fontSize:12}}>
                        {!idName && <Trans i18nKey="enrollment:successfulPageDesc2" components={{10: <a href="mailto:verify@CleanSkyEnergy.com" />}}>
                        </Trans>}
                    </p>
                    <div className="mt-5">
                        <Button
                            withArrow
                            onClick={() => {
                                window.location.href = i18n.language=="en"?`https://cleanskyenergy.com/`:`https://cleanskyenergy.com/es`
                            }}>
                            {t('Back to Homepage')}
                        </Button>
                    </div>
                    <div style={{border:'1px solid #ddd',borderRadius:12,padding:'36px 32px 12px',backgroundColor:'#fafafa'}} className="mt-5 mb-5">
                        <p style={{fontSize:13}}>{t('Refer below to view, print, and/or download your contract and other important documents. Adobe Acrobat Reader must be installed on your computer. You may download Adobe Acrobat Reader free of charge from the Adobe web site')}: <a href="http://get.adobe.com/reader/" target="_blank" rel="noreferrer">{t('Download')} Adobe Reader</a></p>
                        {
                            Object.keys(allDocs).map((type: string, typeIdx: number) => {
                                return (
                                    <div key={typeIdx} className="mb-4 mt-4 d-flex flex-column align-items-center">
                                        <h6>{t(`Documents for ${type} Plan`)}</h6>
                                        <div className="plan-links mt-3">
                                            {
                                                allDocs[type].map((doc: any, idx: number) => (
                                                    <PlanSummaryRow narrow key={idx} className="text-start">
                                                        <a href={doc.url} target="_blank" key={idx} rel="noreferrer">
                                                            <FontAwesomeIcon icon="file-pdf" />
                                                            <span className="ms-2">{doc.display_name}</span>
                                                        </a>
                                                    </PlanSummaryRow>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <p style={{fontSize:12}}>{t("Benefits Beyond Energy Want to see about potential additional savings on recurring bills like cable, phone, internet, satellite (television and radio), pest control and home/alarm security? CleanSky Savings Assistant works to get you a better price for the exact same service and sometimes we are even able to get you an upgrade or add-on for the same or lower price! Here's the best part, it costs you nothing for the negotiations. We split the savings 50/50. Find out more at")} <a href="https://CleanSkyEnergy.com/savings-assistant/" target="_blank" rel="noreferrer">https://CleanSkyEnergy.com/savings-assistant/</a></p>
                </div>
            </div>
        )
    }
    return (
        <div>
            <EnrollmentResultContent>
                <div className="text-center">
                    <h1 className="mb-3">{t("We're Almost There!")}</h1>
                    {autopayRequired ?<p>{t('DepositPaymentScreen1806')}</p>:
                    <p>{t('Thank you. To complete your enrollment, please pay your deposit by providing your Credit Card information and selecting Continue below.')}</p>}
                    {/* <p>{t('mainHeadingFullDepositSplit', {amount: Number(Number(depositAmount)-Number(depositConfig?.deposit_installment_amount[0])) })}</p> */}
                    <div className="mt-5">{getAdditionalContent()}</div>
                </div>
                {agentParam == "true"&&<p>Please let the CleanSky Energy CARE agent know when you have entered your Credit Card information and selected Continue.</p>}
                <div className="mt-5">
                    <h5 className="mb-2">{t('Why Should I Pay The Deposit?')}</h5>
                    <p>{t('After review of your credit history, a refundable deposit is required to initiate service. Your payment information will only be used to process the deposit and will not be used for any other purposes.')}</p>
                </div>
            </EnrollmentResultContent>
            <Modal id="deposit-waiver-modal" size="lg" centered show={showDepositWaiverPopup} onHide={() => setShowDepositWaiverPopup(false)}>
                <Modal.Header closeButton>
                    <h4 className="pt-3">{t('Alternatives to Paying a Deposit')}</h4>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine1">
                            We may be able to waive your deposit if you qualify under any of the following, please call us at <a href="tel:8887335557">(888) 733-5557</a> to learn more about these options.
                        </Trans>
                    </p>
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine2" components={{2: <b />}}>
                            <b>Letter of credit from a previous electric provider</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant:
                        </Trans>
                    </p>
                    <ul>
                        <li>{t('Has been a customer of any retail electric provider or an electric utility within the two years prior to the request for electric service;')}</li>
                        <li>{t('Is not delinquent in payment of any such electric service account; and')}</li>
                        <li>{t('During the last 12 consecutive months of service was not late in paying a bill more than once.')}</li>
                    </ul>
                    <br />
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine3" components={{3: <b />}}>
                            <b>65 or Older</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant is 65 years of age or older and the customer is not currently delinquent in payment of any electric service account.
                        </Trans>
                    </p>
                    <p>
                        <Trans i18nKey="enrollment:depositWaiverLine4" components={{4: <b />, 6: <a href="tel:1-800-681-1950" />}}>
                            <b>Victim of Family Violence</b> – A residential customer or applicant may be deemed as having established satisfactory credit if the customer or applicant has been determined to be a victim of family violence as defined in the Texas Family Code §71.004, by a family violence center as defined in Texas Human Resources Code §51.002, by treating medical personnel, by law enforcement personnel, by the Office of a Texas District Attorney or County Attorney, by the Office of the Attorney General, or by a grantee of the Texas Equal Access to Justice Foundation. This determination shall be evidenced by submission of a certification letter from the Texas Council of Family Violence. The certification letter may be submitted directly to CleanSky Energy by faxing a copy to <a href="tel:1-800-681-1950">1-800-681-1950</a> toll-free.
                        </Trans>
                    </p>
                    {/* <p>
                        <Trans i18nKey="enrollment:depositWaiverLine5" components={{5: <b />}}>
                            <b>Letter of Guarantee</b> – The guarantor must be a current CleanSky Energy customer and remain a customer for the same term as the applicant, have no past due balance, and have no more than one late payment in a 12-month period during the term of the guarantee agreement.
                        </Trans>
                    </p> */}
                </Modal.Body>
            </Modal>
            <FormLoading show={saving} />
        </div>
    )
}
