import jsPDF from "jspdf";
import { b64Satisfy } from "./utils/b64Satisfy";
import { consent_mi } from "./common/data";
import footer from "./common/mi-consent-footer.jpg";

export const generateMiConsentPdf = ({
  userId,
  firstName,
  lastName,
  ldcAccount,
  utility,
  signature,
  ip,
}: {
  userId: string;
  firstName: string;
  lastName: string;
  ldcAccount: string;
  utility: string;
  signature: string;
  ip: string;
}) => {
  const doc = new jsPDF();
  const date = new Date().toISOString().split("T")[0];
  const headerLeftPadding = 20;
  const bodyLeftPadding = 15;

  doc.addFileToVFS("Satisfy-Regular.ttf", b64Satisfy);
  doc.addFont("Satisfy-Regular.ttf", "Satisfy", "normal");

  doc.setFont("helvetica", "normal");
  doc.setFontSize(10); // font 10 for header

  doc.text(`CleanSky Energy`, headerLeftPadding, 10);
  doc.text(`3355 West Alabama,`, headerLeftPadding, 15);
  doc.text(`Suite 500`, headerLeftPadding, 20);
  doc.text(`Houston, TX 77098`, headerLeftPadding, 25);

  doc.setFontSize(12); // font 12 for body

  doc.text(`Name: ${firstName} ${lastName}`, bodyLeftPadding, 60);
  doc.text(`Account Number: ${ldcAccount}`, bodyLeftPadding, 70);
  doc.text(`Contract ID: ${userId}`, bodyLeftPadding, 80);

  const consentText = consent_mi(utility);

  const textLines = doc.splitTextToSize(consentText.trim(), 170);
  doc.setFont("helvetica", "bold"); // reset font
  doc.text(textLines, bodyLeftPadding, 100);

  doc.setFont("helvetica", "normal"); // reset font
  doc.text(`Today's Date: ${date}`, bodyLeftPadding, 150);
  doc.setFont("helvetica", "normal"); // reset font
  doc.text(`Signature: `, bodyLeftPadding, 160);
  doc.setFont("'Satisfy', cursive", "bold");
  doc.setFont("Satisfy", "normal");
  doc.text(`${signature} `, bodyLeftPadding + 20, 160);
  doc.setFont("helvetica", "normal"); // reset font
  doc.text(`IP Address: ${ip}`, bodyLeftPadding, 170);

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const imgWidth = pageWidth;
  const imgHeight = 50;

  const img = new Image();
  img.src = footer;
  doc.addImage(img, "jpg", 0, pageHeight - imgHeight, imgWidth, imgHeight);

  return doc;
};
