import { useEffect, useState } from "react";
import {
    StyledHeader, Logo, StyledHeaderInner,
    HeaderMenu, HeaderMenuItem, MenuToggle
} from "./styles/Header.styled";
import { useTranslation } from "react-i18next";
import { Button } from "./styles/Button.styled";
import { IS_DEV, IS_UAT } from "../config";
import { getEnrollmentRecord } from "../api/enrollment";
import ScriptModal from '../components/ScriptModal'


const Header = ({ enableSwitchLang,scriptData }: {
    enableSwitchLang: boolean,
    scriptData:any
}) => {
    const { t, i18n } = useTranslation();
    const [ showMenu, setShowMenu ] = useState(false);
    const [ lang, setLang ] = useState({ label: 'English', value: 'en' });
    const [openScript,setOpenScript] = useState(false)

    useEffect(() => {

        (async()=>{
            const url = new URL(window.location.href);
            const idParam = url.searchParams.get('id');
            const custSign = url.searchParams.get('sign');
            const agentId =  url.searchParams.get('agent_id');
            const language =  url.searchParams.get('language');
            
            if(custSign){
                let res = await getEnrollmentRecord(idParam, custSign)
                let customerData = res?.response;
                let language = customerData?.language;
                if (language?.toLowerCase()  === 'spanish') {
                    i18n.changeLanguage('es');
                    setLang({ label: 'English', value: 'en' });
                }
                else {
                    i18n.changeLanguage('en');
                    setLang({ label: 'Español', value: 'es' });
                }
            }else if(agentId && language){
                console.log('Data in language ==>', language)
                if (language?.toLowerCase()  === 'english') {
                    i18n.changeLanguage('en');
                    setLang({ label: 'Español', value: 'es' });
                }
                else {
                    i18n.changeLanguage('es');
                    setLang({ label: 'English', value: 'en' });
                }
            }
            else{
                if (i18n.language === 'es') {
                    setLang({ label: 'English', value: 'en' });
                }
                else {
                    setLang({ label: 'Español', value: 'es' });
                }
            }
            
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n, enableSwitchLang])

    let isEs = false;
    if (window.location.pathname) {
        const { pathname } = window.location;
        isEs = pathname.toLocaleLowerCase().indexOf('/es') === 0 ? true : false;
    }

    const changeLang = () => {
        const currentLang = i18n.language;
        if (currentLang === 'en') {
            i18n.changeLanguage('es');
            setLang({ label: 'English', value: 'en' });
        }
        else {
            i18n.changeLanguage('en');
            setLang({ label: 'Español', value: 'es' });
        }
    }

    return (
        <StyledHeader>
            <StyledHeaderInner>
                <Logo href="https://cleanskyenergy.com/">
                    <img src="/images/logo.svg" width="200" alt="" style={{marginRight:20}} />
                    {
                        IS_DEV && <span style={{backgroundColor:'#DE3A28',color:'white',padding:'2px 8px',borderRadius:4,fontWeight:700,fontSize:18}}>DEV</span>
                    }
                    {
                        IS_UAT && <span style={{backgroundColor:'#DE3A28',color:'white',padding:'2px 8px',borderRadius:4,fontWeight:700,fontSize:18}}>UAT</span>
                    }
                </Logo>
                <MenuToggle
                    active={`${showMenu}`}
                    onClick={() => {
                        if (showMenu) {
                            document.body.style.overflow = 'unset'
                        }
                        else {
                            document.body.style.overflow = 'hidden'
                        }
                        setShowMenu(!showMenu)
                    }}
                />
                <HeaderMenu active={`${showMenu}`}>
                    <HeaderMenuItem href={isEs ? "https://cleanskyenergy.com/es/contactenos/" : "https://cleanskyenergy.com/contact-us/"} target="_blank">{t('Contact Us')}</HeaderMenuItem>
                    {/* {!openScript && <Button sm secondary onClick={()=>setOpenScript(true)}>Show Script</Button>} */}
                    {
                        enableSwitchLang &&
                        <Button sm secondary onClick={changeLang}>{lang.label}</Button>
                    }
                </HeaderMenu>
            </StyledHeaderInner>
            <ScriptModal openScripts={openScript} setOpenScript={setOpenScript} scriptData={scriptData}/>
        </StyledHeader>
    )
}

export default Header